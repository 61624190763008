import { CognitoUser } from "amazon-cognito-identity-js";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { useDialog } from "../base/Dialog";
import QRCode from "qrcode";
import { ErrorBox } from "../../components/Errors";
import { Button } from "../base/Button";
import { PlayStoreBadge } from "../base/PlayStoreBadge";
import { AppStoreBadge } from "../base/AppStoreBadge";
import "./SetupMfa.scss";
import { Box } from "../layout/Box";
import { CognitoContext } from "../../contexts/CognitoContext";
import { toast } from "react-toastify";
import { DEFAULT_TOAST_SETTINGS } from "../../utils";

export type Props = {
    user: CognitoUser | undefined
    closeDialog: () => void;
}

enum MfaSetupState {
    apps,
    qrcode,
    success
}

export function SetupMfa({ user, closeDialog }: Props) {

    const cognito = useContext(CognitoContext);
    const [setupState, setSetupState] = useState<MfaSetupState>(MfaSetupState.apps);
    const [email, setEmail] = useState<string>();
    const [error, setError] = useState<Error | null | undefined | any>();
    const [verification, setVerification] = useState<string>()
    const [deviceName, setDeviceName] = useState('My Phone');
    const [totpCode, setTotpCode] = useState('');
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {

        if (user && !verification) {
            user.getUserData((err, userData) => {
                const maybeEmail = userData?.UserAttributes.find(v => v.Name === "email")?.Value

                if (err || !userData || !maybeEmail) {
                    setError(err || "Unable to fetch user data")
                } else {
                    setEmail(maybeEmail);
                    user.associateSoftwareToken({
                        associateSecretCode: (secretCode) => {
                            setVerification(secretCode);
                        },
                        onFailure: (err) => {
                            setError(err);
                        }
                    })
                }
            })

        }

    }, [user])

    useEffect(() => {

        if (verification && canvasRef.current) {
            const totpQrcode = `otpauth://totp/Sherpah:${email}?secret=${verification}&issuer=Sherpah`
            QRCode.toCanvas(canvasRef.current, totpQrcode, (err) => {
                setError(err);
            });
        }

    }, [verification, canvasRef, setupState])

    function onVerifyTotpCode() {

        setError('');

        if (!totpCode.match(/[0-9]{6}/)) {
            setError('MFA code must be 6 digits')
            return 
        }

        setLoading(true);

        user?.verifySoftwareToken(totpCode, deviceName, {
            onSuccess: (session) => {

                user.setUserMfaPreference(null, {
                    Enabled: true,
                    PreferredMfa: true
                }, (err, result) => {

                    console.log({ err, result });
                    if (err) {
                        setError(err.message);
                    } else {
                        toast('Successfully setup MFA', DEFAULT_TOAST_SETTINGS);
                        cognito.setMfaDetails!({ totpEnabled: true })
                        closeDialog();
                    }
                })

            },
            onFailure: (err) => {
                setError(err.message);
                setLoading(false);
            }
        })
    }

    let inner: React.ReactElement;

    switch (setupState) {
        case MfaSetupState.apps:
            inner = <>
                <p>Download an authenticator app, we recommend Google Authenticator, linked below:</p>
                <Box>
                    <PlayStoreBadge alt="Google authenticator app for Android" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share" />
                    <AppStoreBadge alt="Google authenticator app for iOS" href="https://apps.apple.com/au/app/google-authenticator/id388497605" />
                </Box>
                <Box>
                    <Button text="Continue" onClick={() => setSetupState(MfaSetupState.qrcode)} />
                </Box>
            </>
            break;

        case MfaSetupState.qrcode:
            inner = <>
                <p>Scan the QR code in your authenticator app, and enter in a code below</p>
                <Box flow="column">
                    <canvas ref={canvasRef} />
                    <p>Alteratively, enter this secret key into your authenticator app: <code>{verification}</code></p>
                    <label htmlFor="totp">MFA code</label>
                    <input id="totp" inputMode="numeric" value={totpCode} onChange={(e) => setTotpCode(e.target.value)} placeholder="Code"></input>

                    <label htmlFor="nickname">Device nickname</label>
                    <input id="nickname" type="text" value={deviceName} onChange={(e) => setDeviceName(e.target.value)} placeholder="Device name"></input>
                    <Button disabled={loading || !verification} onClick={onVerifyTotpCode} text="Submit" />
                </Box>
            </>
            break;

        case MfaSetupState.success:
            inner = <Box flow="column">
                <p>Success!</p>
                <Button text="Done" onClick={closeDialog} />
            </Box>
            break;
    }

    return <div className="setup-mfa">
        {error && <ErrorBox>{error.toString()}</ErrorBox>}
        {inner}
    </div>
}

export function useMfaDialog(user: CognitoUser | undefined) {

    const MfaDialog = (closeDialog: () => void) => {
        return <SetupMfa user={user} closeDialog={closeDialog} />
    }

    const dialog = useDialog({
        title: "MFA Setup",
        child: MfaDialog
    });

    function onSetupMfa() {
        dialog.setOpen(true);
    }

    return {
        onSetupMfa,
        SetupMfa: dialog.component
    }
}
