import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function Redirect() {
    const [searchParams, setSearchParams] = useSearchParams();
    const mobile = searchParams.get("mobile");
    const web = searchParams.get("web");
    const clinic = searchParams.get("clinic");

    let scheme = "mysherpah";
    if (clinic == "Heartschool") {
        scheme = "heartschool";
    }

    useEffect(() => {
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        var Android = /Android/.test(navigator.userAgent);
        var newLocation = "";
        if (iOS) {
            newLocation = scheme + `:${mobile}`;
        } else if (Android) {
            newLocation = scheme + `://${mobile}`;
        } else {
            newLocation = web || "/";
        }
        window.location.replace(newLocation)
    }, []);

    return <main>
        <p>Redirecting...</p>
    </main>;
}
