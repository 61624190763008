import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';


export type Paginated<T> = {
    current_page: number,
    total_pages: number,
    results: T[]
};

type PaginationControlsProps = {
    page: number,
    setPage: Dispatch<SetStateAction<number>>,
    totalPages: number
};

export function PaginationControls(props: PaginationControlsProps){
    const {page, setPage, totalPages} = props;

    if (totalPages <= 1) return;

    let buttons: JSX.Element[];
    if (totalPages <= 5) {
        // 1 2 [3] 4 5
        buttons = Array(totalPages).fill(undefined).map((v, i) => 
            <button className={`pagination ${page == (i+1) ? "active" : ""}`} onClick={() => setPage(i+1)} key={i+1}>{i+1}</button>
        );
    } else {
        if (page <= 3) {
            // 1 [2] 3 ... 9
            buttons = Array(3).fill(undefined).map((v, i) => 
                <button className={`pagination ${page == (i+1) ? "active" : ""}`} onClick={() => setPage(i+1)} key={i+1}>{i+1}</button>
            );
            buttons.push(<div>...</div>);
            buttons.push(
                <button className="pagination" onClick={() => setPage(totalPages)}>{totalPages}</button>
            );
        } else if (page >= totalPages - 2) {
            // 1 ... 7 [8] 9
            const offset = totalPages - 2;
            buttons = [
                <button className="pagination" onClick={() => setPage(1)}>{1}</button>
            ]
            buttons.push(<div>...</div>);
            buttons.push(
                ...Array(3).fill(undefined).map((v, i) => 
                    <button className={`pagination ${page == (i+offset) ? "active" : ""}`} onClick={() => setPage(i+offset)} key={i+offset}>{i+offset}</button>
                )
            );
        } else {
            // 1 ... [5] ... 9
            buttons = [
                <button className="pagination" onClick={() => setPage(1)}>{1}</button>,
                <div>...</div>,
                // <button className="pagination" onClick={() => setPage(page-1)}>{page-1}</button>,
                <button className="active" onClick={() => setPage(page)}>{page}</button>,
                // <button className="pagination" onClick={() => setPage(page+1)}>{page+1}</button>,
                <div>...</div>,
                <button className="pagination" onClick={() => setPage(totalPages)}>{totalPages}</button>
            ];
        }
    }

    return <div className="heading-wrapper-actions">
        <button className="pagination" onClick={() => setPage(p => Math.max(p-1, 1))}>
            <FontAwesomeIcon icon={faCaretLeft} /> Prev
        </button>
        { buttons }
        <button className="pagination" onClick={() => setPage(p => Math.min(p+1, totalPages))}>
            Next <FontAwesomeIcon icon={faCaretRight} />
        </button>
    </div>;
}
