import { faCircleQuestion, faTable, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { PropsWithChildren, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';
import { Trends, Report } from '../patients/report/reportTypes';
import ReportSection from '../patients/report/ReportSection';
import Loading from '../../components/Loading';
import { ErrorText } from '../../components/Errors';
import ReportGraph from '../patients/report/ReportGraph';
import ReportStats from '../patients/report/ReportStats';

type RandomReport = Report & {
    patientId: string,
    date: string,
    smartwatch: string
};

type DatasetFormValues = {
    sleep_onset: string,
    sleep_finish: string,
    depression: number | null,
    anxiety: number | null,
    confidence: number | null,
    comment: string
};

type Dataset = DatasetFormValues & {
    date: string,
    patientId: string,
    smartwatch: string,
};

const RATINGS = ['Normal','Sub-clinical','Mild','Moderate','Severe'];
const CONFIDENCE = ['Not confident', 'Somewhat confident', 'Fairly confident', 'Very confident'];


type ValidatedInputProps = PropsWithChildren & {
    touched: boolean | undefined,
    value: any | null,
    error: string | undefined,
    onClick: () => void,
    style?: any
};

function ValidatedInput(props: ValidatedInputProps) {
    let style: any = {
        borderRadius: 8,
        padding: 4,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer"
    };

    // let colour, message;
    // if (props.touched) {
    //     if (props.value === null || props.value === "") {
    //         colour = "#4c673d";
    //         message = "Field intentionally left blank.";
    //     } else {
    //         colour = "#7ace4c";
    //         message = "Field has input.";
    //     }
    // } else {
    //     colour = "red";
    //     message = "Please input a value or click.";
    // } 

    let colour = "#00000000";
    if (props.error || props.value === null || props.value === "") {
        colour = "red";
    }
    style["border"] = "3px solid " + colour;


    return <div style={style}>
        <div style={props.style}>
            {props.children}
        </div>
        <div style={{ color: colour }}>{props.error}</div>
    </div>
}

type AnnotateReportFormProps = {
    onSkip: () => void,
    date: string,
    patientId: string
};

export function AnnotateReportForm(props: AnnotateReportFormProps) {
    const { sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const [showUntouchedWarning, setShowUntouchedWarning] = useState<boolean>(false);

    const onSubmit = async (values: DatasetFormValues, { setSubmitting, setErrors, resetForm }: FormikHelpers<DatasetFormValues>) => {
        setError(null);

        // if there are fields with null value, show errors
        const nullKeys = Object.keys(values).filter(key =>
            key !== "comment" && (
                values[key as keyof DatasetFormValues] === null ||
                values[key as keyof DatasetFormValues] === ""
            )
        );
        if (nullKeys.length > 0 && !showUntouchedWarning) {
            let errors: FormikErrors<DatasetFormValues> = {};
            nullKeys.forEach(key => errors[key as keyof DatasetFormValues] = "This field is blank.");
            setErrors(errors);
            setShowUntouchedWarning(true);
            setSubmitting(false);
            return;
        }
        
        try {
            const data = {
                ...values,
                date: props.date,
                patient_id: props.patientId
            };
            const response = await sherpahPost(`/patients/annotated-reports`, data);
            setShowUntouchedWarning(false);
            resetForm();
            props.onSkip();
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    };

    const initialValues: DatasetFormValues = {
        sleep_onset: "",
        sleep_finish: "",
        depression: null,
        anxiety: null,
        confidence: null,
        comment: ""
    };

    return <Formik 
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({isSubmitting, values, errors, touched, setFieldTouched, resetForm}) => (
        <Form>
            {/* <p>To prevent accidentally skipping an input, for each field below please either input a value or click to acknowledge being left as blank.</p> */}
            <b>Algorithm dataset training</b>
            <div style={{ display: "grid", gap: 8, gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", alignItems: "center" }}>
                <ValidatedInput error={errors.sleep_onset} touched={touched.sleep_onset} value={values.sleep_onset} onClick={() => setFieldTouched("sleep_onset", true)} style={{display: "flex", flexDirection: "row"}}>
                    <label style={{ alignSelf: "center", marginRight: 8 }}>Sleep onset:</label>
                    <Field style={{ flex: 1 }} name="sleep_onset" type="text" placeholder="eg. 21:00" />
                    {/* <ErrorMessage name="sleep_onset" component={ErrorText} /> */}
                </ValidatedInput>
                <ValidatedInput error={errors.sleep_finish} touched={touched.sleep_finish} value={values.sleep_finish} onClick={() => setFieldTouched("sleep_finish", true)} style={{display: "flex", flexDirection: "row"}}>
                    <label style={{ alignSelf: "center", marginRight: 8 }}>Sleep finish:</label>
                    <Field style={{ flex: 1 }} name="sleep_finish" type="text" placeholder="eg. 07:00" />
                    {/* <ErrorMessage name="sleep_finish" component={ErrorText} /> */}
                </ValidatedInput>
                <ValidatedInput error={errors.depression} touched={touched.depression} value={values.depression} onClick={() => setFieldTouched("depression", true)}>
                    <label>Depression score:</label> <span>{values.depression !== null ? RATINGS[values.depression] : ""}</span><br/>
                    <Field name="depression" type="range" min="0" max="4" />
                    {/* <ErrorMessage name="depression" component={ErrorText} /> */}
                </ValidatedInput>
                <ValidatedInput error={errors.anxiety} touched={touched.anxiety} value={values.anxiety} onClick={() => setFieldTouched("anxiety", true)}>
                    <label>Anxiety score:</label> <span>{values.anxiety !== null ? RATINGS[values.anxiety] : ""}</span><br/>
                    <Field name="anxiety" type="range" min="0" max="4" />
                    {/* <ErrorMessage name="anxiety" component={ErrorText} /> */}
                </ValidatedInput>
                <ValidatedInput error={errors.confidence} touched={touched.confidence} value={values.confidence} onClick={() => setFieldTouched("confidence", true)}>
                    <label>Confidence:</label> <span>{values.confidence !== null ? CONFIDENCE[values.confidence] : ""}</span>
                    <span
                        data-tooltip-id="confidence-tooltip"
                        data-tooltip-content={`How certain you are of your assessment.`}>
                            <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                            <Tooltip id="confidence-tooltip" className="tooltip-content" />
                    </span>
                    <br/>
                    <Field name="confidence" type="range" min="0" max="3" />
                    {/* <ErrorMessage name="confidence" component={ErrorText} /> */}
                </ValidatedInput>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ alignSelf: "center", marginRight: 8 }}>Other comments (optional):</label>
                    <Field style={{ flex: 1 }} name="comment" as="textarea" />
                    {/* <ErrorMessage name="comment" component={ErrorText} /> */}
                </div>
            </div>
            
            {error ? <ErrorText>{error}</ErrorText> : null}

            { showUntouchedWarning ? <ErrorText>Warning: Some fields haven't been filled in. You may still submit this form without filling these fields in.</ErrorText> : null }
            
            <div className="actions">
            { isSubmitting ? 
                <button type="button" className="w-button" disabled>Submitting...</button>
                : <>
                    <button type="submit" className="w-button" style={{ gridColumnStart: 1, gridColumnEnd: 4 }}>{ showUntouchedWarning ? "Submit despite some fields possibly being blank" : "Submit" }</button>
                    <button type="button" className="w-button secondary-button" style={{ gridColumnStart: 4, gridColumnEnd: 7 }} onClick={() => {setShowUntouchedWarning(false); resetForm(); props.onSkip();}}>Skip</button>
                </>
            }
            </div>
        </Form>
        )}
    </Formik>;
}


export default function AlgorithmData() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const [date, setDate] = useState<string>("");
    const [patientId, setPatientId] = useState<string>("");
    const [report, setReport] = useState<Report | null>(null);

    const showRandomReport = async () => {
        setReport(null);
        setError(null);
        try {
            const response = await sherpahGet<RandomReport>("patients/random");
            if (response.data.scores_error) {
                // if there's a scores error, fetch another report
                await showRandomReport();
                return;
            } else {
                setReport(response.data);
                setDate(response.data.date);
                setPatientId(response.data.patientId);
            }
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    useEffect(() => {
        showRandomReport();
    }, [])

    return <main>
        <div className="heading-wrapper">
            <h1>Algorithm Data</h1>
        </div>

        <div className="rounded-container">
            <div>
                This page is for obtaining datasets to train the depression & anxiety score algorithm.
                Random 24-hour deidentified patient records are graphed below.
                Fill in the fields below the graph for each patient and press Submit.
                You also have the option to skip on any patient.
            </div>
            <div className="rounded-container" style={{ margin: "16px 0"}}>
            <AnnotateReportForm date={date} patientId={patientId} onSkip={showRandomReport} />
            </div>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { report && !error ? <>
            
                <section id="reportContainer">
                    <ReportGraph report={report!} />
                    <div className="graph-legend">
                        <div>
                            <div className="graph-legend-square" style={{ backgroundColor: "#7ACE4C" }}></div>
                            <div>
                                {/* <input type="checkbox" /> */}
                                <label>Heart Rate</label>
                            </div>
                        </div>
                        <div>
                            <div className="graph-legend-square" style={{ backgroundColor: "#2A3238" }}></div>
                            <div>
                                {/* <input type="checkbox" /> */}
                                <label>Steps</label>
                            </div>
                        </div>
                        <div>
                            <div className="graph-legend-square" style={{ backgroundColor: "#E4E4E4" }}></div>
                            <div>
                                {/* <input type="checkbox" /> */}
                                <label>Sleep</label>
                            </div>
                        </div>
                    </div>
                </section>

                { report.stats ? <ReportStats report={report} /> : null }
            </> : <Loading /> }

            </div>
    </main>

}