import { CognitoUserPool } from "amazon-cognito-identity-js"
import { createContext, useContext, useEffect, useState } from "react";
import { AxiosContext } from "./AxiosContext";
import { AuthContext } from "./AuthContext";
import axios from "axios";
import { DOMAIN } from "../constants";
import { logError } from "../utils";

type AppConfigResponse = {
    cognito_user_pool_id: string;
    cognito_user_pool_client_id: string;
    app_redirect_uri: string;
    auth_config: {
        serviceConfiguration: {
            authorizationEndpoint: string,
            tokenEndpoint: string,
            revocationEndpoint: string,
            endSessionEndpoint: string
        },
        scopes: string[],
        clientId: string,
        redirectUrl: string
    }
}

export enum AppState {
    none,
    fetching,
    initialised
}

export enum CognitoSessionState {
    none,
    fetching,
    found,
    error
}

export type AppContextType = {
    initState: AppState;
    error?: any;
    appConfig?: AppConfigResponse;
    userPool?: CognitoUserPool;
    fromApp: boolean;
}

export const AppContext = createContext<AppContextType>({
    initState: AppState.none,
    fromApp: true
});

const fromApp = window.location.search.includes("fromApp");

export const AppProvider = ({ children }: React.PropsWithChildren) => {

    const [appConfig, setAppConfig] = useState<AppConfigResponse>();
    const [userPool, setUserPool] = useState<CognitoUserPool>();
    const [initState, setInitState] = useState(AppState.none);
    const [error, setError] = useState();

    function initialise() {

        const options = {
            method: "GET",
            url: DOMAIN + "app/config",
        };

        if (!userPool && initState == AppState.none) {
            setInitState(AppState.fetching);
            axios<AppConfigResponse>(options)
                .then(res => {
                    setAppConfig(res.data);
                    setUserPool(new CognitoUserPool({
                        UserPoolId: res.data.cognito_user_pool_id,
                        ClientId: fromApp ? res.data.auth_config.clientId : res.data.cognito_user_pool_client_id
                    }))
                })
                .catch(err => {
                    logError(err);
                    setError(err);
                })
                .finally(() => {
                    setInitState(AppState.initialised);
                })
        }
    }

    useEffect(() => {
        initialise();
    }, [])

    return <AppContext.Provider
        value={{
            userPool,
            appConfig,
            initState,
            error,
            fromApp
        }}
    >
        {children}
    </AppContext.Provider>
}
