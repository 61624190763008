import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';
import { User } from '../../types';
import "./doctor.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ErrorText } from '../../components/Errors';
import Loading from '../../components/Loading';
import { formatDatetime } from '../../utils';
import { DateTime } from 'luxon';


type DeletedItem = {
    id: string,
    model: "DASS21" | "MedicalReview" | "MedicationCourse",
    patient_user: User,
    deleted_at: string,
    description: string,
    endpoint: string
};


export default function DeletedItems() {
    const [items, setItems] = useState<DeletedItem[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { sherpahGet, sherpahPut, catchAxios } = useContext(AxiosContext)!;

    const getItems = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await sherpahGet<{deleted_items: DeletedItem[]}>(`/doctors/%USERID%/deleted-items`);
            setItems(response.data.deleted_items);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    const restore = async (item: DeletedItem) => {
        setLoading(true);
        setError(null);
        try {
            await sherpahPut(`/patients/${item.patient_user.id}/${item.endpoint}`, {deleted_at: null});
            setItems(items => items.filter(i => i.model != item.model && i.id != item.id))
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    let content;
    if (error != null) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <>
            <div className="heading-wrapper">
                <h1>Deleted items</h1>
            </div>
            <div className="rounded-container">
                { items.length == 0 ? 
                    <i className="empty-message">No deleted items.</i>
                    :
                    <div className="flex-col-gap-16">
                        <div className="list-container tall-list">
                            <div className="list-columns deleted-list-grid">
                                <div className="list-column">ITEM TYPE</div>
                                <div className="list-column">DESCRIPTION</div>
                                <div className="list-column">PATIENT</div>
                                <div className="list-column">DELETED AT</div>
                                <div className="list-column">PERMANENT DELETION</div>
                                <div className="list-column">ACTIONS</div>
                            </div>
                            { items.map(item => 
                                <div className="list-item deleted-list-grid" key={item.id}>
                                    <div>{item.model}</div>
                                    <div>{item.description}</div>
                                    <div>{item.patient_user.full_name}</div>
                                    <div>{formatDatetime(item.deleted_at)}</div>
                                    <div style={{ color: "red" }}>{DateTime.fromISO( item.deleted_at, {zone: 'utc'}).toLocal().plus({days: 30}).toRelative({unit: "days"})}</div>
                                    <div className="list-actions">
                                        <div onClick={() => restore(item)} className="list-action" data-tooltip-id="restore" data-tooltip-content="Restore">
                                            <FontAwesomeIcon icon={faTrashArrowUp} className="list-action-icon" />
                                            <Tooltip id="restore" style={{zIndex: 1000}}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </>;
    }

    return <main>
        {content}
    </main>;
}
