import { CognitoUser, UserData } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";

export enum ApiFetchState {
    none,
    fetching,
    complete
}

export function useCognitoUserData(user: CognitoUser | undefined) {

    const [apiState, setApiState] = useState<ApiFetchState>(ApiFetchState.none)
    const [userData, setUserData] = useState<UserData | undefined>();
    const [error, setError] = useState<Error>();

    function fetchUserData(user: CognitoUser): Promise<UserData | undefined> {
        return new Promise((resolve, reject) => {
            user.getUserData((err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            })
        }
        )
    }

    useEffect(() => {
        if (user) {
            setApiState(ApiFetchState.fetching);
            fetchUserData(user)
                .then(setUserData)
                .catch(setError)
                .finally(() => setApiState(ApiFetchState.complete))
        }
    }, [user])

    return { userData, apiState, error, fetchUserData };
}
