import React, { useContext, useEffect, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { Doctor } from '../../types';
import "./doctor.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ErrorText } from '../../components/Errors';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { CognitoContext } from "../../contexts/CognitoContext";
import { ApiFetchState, useCognitoUserData } from "../../api/Cognito";
import { Button } from "../../ui/base/Button";
import { useMfaDialog } from "../../ui/dialogs/SetupMfa";
import { toast } from "react-toastify";
import { DEFAULT_TOAST_SETTINGS } from '../../utils';


export default function Settings() {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [doctor, setDoctor] = useState<Doctor | null>(null);
    const { sherpahGet, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { user } = useContext(AuthContext)!.authState;

    const cognito = useContext(CognitoContext);
    const details = cognito && cognito.getUserDetails && cognito.getUserDetails();
    const data = useCognitoUserData(cognito.user);
    const { SetupMfa, onSetupMfa } = useMfaDialog(cognito.user);
    const mfaDetails = cognito.getMfaDetails && cognito.getMfaDetails(data.userData);

    const getDoctor = async () => {
        try {
            let response;
            if (user?.is_operator) {
                response = await sherpahGet<{doctor: Doctor}>(`/operators/${user.id}/patients`);
            } else {
                response = await sherpahGet<{doctor: Doctor}>(`/doctors/${user!.id}/patients`);
            }
            setDoctor(response.data.doctor);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    const setDeidentifyPatients = async (isOn: boolean) => {
        // setLoading(true);
        try {
            const response = await sherpahPut(`/doctors/${user!.id}`, {deidentify_patients: isOn});
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    function onDisableMfa() {
        cognito.user?.setUserMfaPreference(null, {
            Enabled: false,
            PreferredMfa: false
        }, (err, success) => {
            console.log({ err, success });

            if (err) {
                toast('Unable to disable MFA: ' + err.message, DEFAULT_TOAST_SETTINGS);
            } else {
                toast('Successfully disabled MFA', DEFAULT_TOAST_SETTINGS);
                cognito.setMfaDetails!({ totpEnabled: false })
            }
        })
    }

    useEffect(() => {
        getDoctor();
    }, []);

    let content;
    if (error != null) {
        content = <main><ErrorText>{error}</ErrorText></main>;
    } else if (loading || !details || !cognito.user || data.apiState !== ApiFetchState.complete) {
        content = <main><Loading /></main>;
    } else {
        content = <main>
            { mfaDetails?.totpRequiresLogin ? undefined : SetupMfa }
            <h1>Settings</h1>
            <div className="rounded-container">
                <h3 style={{ fontWeight: "normal" }}>
                    Your linking code is: <code style={{ fontWeight: 600 }}>{doctor!.linking_code}</code>
                </h3>
                <p>Give this code to any new patients so they can share their profile with you.</p>
                <hr style={{ margin: "16px 0" }}/>

                <div style={{ marginBottom: "8px" }}>
                    <b>Multi-factor authentication:</b> { mfaDetails ? mfaDetails.totpEnabled ? "Setup" : "Not setup" : "Unknown" }
                </div>
                {
                    (mfaDetails && mfaDetails.totpRequiresLogin) ? 
                        <p>MFA changes require you to sign out and sign in again.</p>
                        : mfaDetails && mfaDetails.totpEnabled ?
                    <Button onClick={onDisableMfa} text="Disable MFA" />
                    : <Button onClick={onSetupMfa} text="Setup MFA" />
                }

                <hr style={{ margin: "16px 0" }}/>

                <div>
                    <input id="deidentify" type="checkbox" onChange={e => setDeidentifyPatients(e.target.checked)} defaultChecked={doctor!.deidentify_patients} />
                    <label htmlFor="deidentify">Deidentify patients</label>
                    <p>
                        Turn this setting on to hide all the names of your patients on SherpahConnect.
                        This setting is useful for showing other clinicians a patient list without revealing identities.
                    </p>
                </div>
                <hr style={{ margin: "16px 0" }}/>
                <Link to="/deleted-items" className="w-button secondary-button fa-icon-button">
                    <FontAwesomeIcon icon={faTrash} />
                    View deleted items
                </Link>
            </div>
        </main>;
    }
    
    return content;
}
