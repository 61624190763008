export default function TCsHeartschool() {
    return (
        <main style={{ maxWidth: 800, margin: "0 auto" }}>
            <h1>Terms and Conditions for Heartschool</h1>
            <p>Welcome to Heartschool, a mobile application designed to support your mental wellness journey. By using Heartschool, you agree to the following terms and conditions:</p>
            <ol>
                <li>
                    <b>Acceptance of Terms</b>: By downloading, installing, or using Heartschool, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use the application.
                </li>
                <li>
                    <b>Subscription and Payment</b>: Heartschool operates on a subscription basis. Users are required to pay a subscription fee once every three months to access the full features of the application. Payment is charged automatically to the payment method provided by the user upon subscription. By providing payment information, you authorize Heartschool to charge the subscription fee.
                </li>
                <li>
                    <b>Cancellation</b>: Subscribers can cancel their subscription at any time by emailing drsimon@heartschool.com.au and requesting cancellation. Cancellation requests must be made at least 24 hours before the next billing cycle to avoid being charged for the subsequent period.
                </li>
                <li>
                    <b>Data Collection</b>: Heartschool collects various types of data, including but not limited to, responses to mental wellness questionnaires and health data such as heart rate and steps from compatible smartwatches. By using the application, you consent to the collection of this data.
                </li>
                <li>
                    <b>Use of Data</b>: Heartschool may use the collected data for research and development activities aimed at improving the application's features and effectiveness in supporting mental wellness. This may include analyzing aggregated data and publishing research articles based on the findings. All data used for research purposes is deidentified to protect the privacy of users.
                </li>
                <li>
                    <b>Compliance with Laws</b>: Heartschool operates in accordance with the laws and regulations of Western Australia, Australia. Users are responsible for complying with all applicable laws when using the application.
                </li>
                <li>
                    <b>Disclaimer</b>: Heartschool is not a substitute for professional medical advice, diagnosis, or treatment. The information provided by the application is for informational purposes only and should not be considered medical advice. Users should consult with a qualified healthcare professional for personalized advice and treatment.
                </li>
                <li>
                    <b>Limitation of Liability</b>: Heartschool and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the application, including but not limited to, any loss of data, loss of revenue or profit, or any other commercial or economic loss.
                </li>
                <li>
                    <b>Changes to Terms</b>: Heartschool reserves the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on the application. Continued use of Heartschool after such changes constitutes acceptance of the modified terms.
                </li>
                <li>
                    <b> Contact Us</b>: If you have any questions or concerns about these terms and conditions, please contact us at drsimon@heartschool.com.au.
                </li>
            </ol>

            <p>By using Heartschool, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. Thank you for choosing Heartschool to support your mental wellness journey.</p>
        </main>
    );
}