import React, {createContext, useContext, useEffect, useState} from "react";
import { Diagnosis, Doctor, MedicareItem, Medication, Patient } from "../types";
import { AuthContext } from "./AuthContext";
import { AxiosContext } from "./AxiosContext";


type SharedResponse = {
	medications: Medication[],
	diagnoses: Diagnosis[],
	medicare_items: MedicareItem[]
}

type SharedContextType = {
	medications: Medication[],
	diagnoses: Diagnosis[],
	medicareItems: MedicareItem[]
};

const SharedContext = createContext<SharedContextType | null>(null);

const SharedProvider = ({children}: React.PropsWithChildren) => {
	const { sherpahGet } = useContext(AxiosContext)!;
	const authContext = useContext(AuthContext)!;

	const [medications, setMedications] = useState<Medication[]>([]);
	const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([]);
	const [medicareItems, setMedicareItems] = useState<MedicareItem[]>([]);

	async function init() {
		if (authContext.authState.authenticated) {
			try {
				const response = await sherpahGet<SharedResponse>("shared");
				setMedications(response.data.medications);
				setDiagnoses(response.data.diagnoses);
				setMedicareItems(response.data.medicare_items);
			} catch (e: any) {
				console.error("Shared context HTTP error", e);
			}
		}
	}

	useEffect(() => {
		init();
	}, [authContext.authState.authenticated]);

	return (
		<SharedContext.Provider
			value={{
				medications,
				diagnoses,
				medicareItems
			}}>
			{children}
		</SharedContext.Provider>
	);
};

export { SharedContext, SharedProvider };