import { createContext, useContext, useEffect, useState } from "react"
import { CognitoContext } from "./CognitoContext";

export type FeatureContextType = {
    enrolled: boolean;
}

export const FeatureContext = createContext<FeatureContextType>({
    enrolled: false
});

export const StaffEmails = [
    "bpkneale@gmail.com"
]

export const StaffDomains = [
    "@sherpah.com.au"
]

export const FeatureProvider = ({ children }: React.PropsWithChildren) => {

    const cognitoContext = useContext(CognitoContext);
    const [enrolled, setEnrolled] = useState(false);

    useEffect(() => {
        let newEnrolled = false;
        if (cognitoContext && cognitoContext.user && cognitoContext.getUserDetails) {
            const email = cognitoContext.getUserDetails()?.email;
            newEnrolled = Boolean(email && (StaffDomains.some(domain => email.endsWith(domain)) || StaffEmails.some(staffEmail => staffEmail === email)));
        }
        setEnrolled(newEnrolled);
    }, [cognitoContext])


    return <FeatureContext.Provider
        value={{
            enrolled
        }}
    >
        {children}
    </FeatureContext.Provider>
}

