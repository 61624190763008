import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUser } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { Trends, Report, PopulatedDates } from './reportTypes';
import TrendsSection from './TrendsSection';
import ReportSection from './ReportSection';
import { ErrorText } from '../../../components/Errors';
import "./report.scss";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { DatePickerField } from '../../../components/DatePickerField';
import { downloadRequest } from '../../../utils';


export default function PatientReport() {
    const [mode, setMode] = useState<"report" | "trends">("report");
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [populatedDates, setPopulatedDates] = useState<PopulatedDates | null>(null);
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;


    useEffect(() => {
        sherpahGet<PopulatedDates>(`patients/${patient?.user_id}/populated-dates`)
            .then(resp => setPopulatedDates(resp.data));
    }, []);

    return <main>
        <div className="heading-wrapper">
			<h1>{ patient?.user.full_name }</h1>
			<div className="heading-wrapper-actions">
				<Link to={`/patients/${patient?.user_id}`} className="secondary-button w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faUser} /></span>
					Patient profile
				</Link>
				<button className="secondary-button w-button fa-icon-button" onClick={() => setShowDownloadModal(true)}>
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faDownload} /></span>
					Download CSV
				</button>
			</div>
		</div>

        <div className="rounded-container">
            <div className="tabs">
                <span className={`tab ${mode == "report" ? " active-tab" : ""}`} onClick={() => setMode("report")}>Report</span>
                <span className={`tab ${mode == "trends" ? " active-tab" : ""}`} onClick={() => setMode("trends")}>Trends</span>
            </div>
            { mode == "report" ? <ReportSection populatedDates={populatedDates} /> : null }
            { mode == "trends" ? <TrendsSection populatedDates={populatedDates} /> : null }
        </div>

        { showDownloadModal ? <DownloadCSVModal onClose={() => setShowDownloadModal(false)} /> : null }
    </main>;
}


type DownloadCSVModalProps = {
    onClose: () => void,
    // onDownload: () => void
};

type DownloadCSVFormValues = {
    start: string,
    end: string
};

export function DownloadCSVModal(props: DownloadCSVModalProps) {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [error, setError] = useState<string | null>(null);

    const onSubmit = async (values: DownloadCSVFormValues, { setErrors }: FormikHelpers<DownloadCSVFormValues>) => {
        try {
            const url = `/patients/${patient!.user_id}/csv?start=${values.start}&end=${values.end}`;
            const request = sherpahGet<string>(url);
            await downloadRequest(request, `patient-${patient!.user_id}.csv`, "text/csv");
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    const initialValues: DownloadCSVFormValues = {
        start: DateTime.now().plus({days: -1}).toFormat("yyyy-MM-dd"),
        end: DateTime.now().toFormat("yyyy-MM-dd"),
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Download patient data</div>
            <p>Select a date range to download patient's heart rate and steps data.<br/>
            Data is collected from 12pm of the start date to 12pm of the end date.</p>
            <Formik 
                initialValues={initialValues}
                validationSchema={Yup.object<DownloadCSVFormValues>({
                    start: Yup.date().label("Start date").required(),
                    end: Yup.date().label("End date")
                            .min(Yup.ref("start"), "End date must be after start date."),
                })}
                onSubmit={onSubmit}>
                {({isSubmitting}) => (
                    <Form>
                        <div className="form-fields">
                            <div className="form-field-half">
                                <label>Start</label>
                                <DatePickerField name="start" />
                                <ErrorMessage name="start" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label>End</label>
                                <DatePickerField name="end" />
                                <ErrorMessage name="end" component={ErrorText} />
                            </div>
                        </div>
                        { error ? <ErrorText>{error}</ErrorText> : null }
                        { isSubmitting ? 
                            <div className="modal-actions">
                                <button type="button" className="w-button" disabled>Downloading...</button>
                            </div>
                            :
                            <div className="modal-actions">
                                <button type="submit" className="w-button">Download</button>
                                <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    </div>;
}
