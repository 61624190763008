
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ErrorText } from '../../components/Errors';


export default function Payment() {
    const [searchParams, setSearchParams] = useSearchParams();
    const sessionId = searchParams.get("session_id");
    const clinicName = searchParams.get("clinic");
    const params = useParams<{patientId?: string}>();

	const { publicAxios, catchAxios } = useContext(AxiosContext)!;
	const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const verifyPayment = async () => {
        try {
            const response = await publicAxios.get(`/signup/verify-payment/${params.patientId || ""}?session_id=${sessionId}`);
            navigate(`/redirect?mobile=payment?status=successful&clinic=${clinicName}`);
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    useEffect(() => {
        if (sessionId) {
            verifyPayment();
        } else {
            navigate(`/redirect?mobile=payment?status=cancelled&clinic=${clinicName}`);
        }
    }, []);

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else {
        content = <Loading text="Verifying..." />;
    }

    return <main>
        {content}
    </main>;
}
