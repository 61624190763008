import { useContext, useState } from 'react';
import { Formik, Form, Field, FormikHelpers, ErrorMessage  } from 'formik';
import {
    MedicationCourse,
    TMSCourse
} from '../../../types';
import { DatePickerField } from '../../../components/DatePickerField';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { ErrorText } from '../../../components/Errors';
import * as Yup from 'yup';


type TMSCourseModalProps = {
    tmsCourse: TMSCourse,
    onClose: () => void,
    // onAdd: (course: TMSCourse) => void,
    onEdit: (course: TMSCourse) => void
};

type TreatmentFormValues = {
    start?: string,
    end?: string
};

export function TMSCourseModal(props: TMSCourseModalProps) {
    const { sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (values: TreatmentFormValues, { setErrors }: FormikHelpers<TreatmentFormValues>) => {
        setError(null);
        try {
            // editing tms course
            const response = await sherpahPut<TMSCourse>(`/patients/${patient!.user_id}/tms-courses/${props.tmsCourse.id}`, values);
            props.onEdit(response.data);
            props.onClose();
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    };

    const initialValues: TreatmentFormValues = {
        start: props.tmsCourse?.start || undefined,
        end: props.tmsCourse?.end || undefined
    };


    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Set TMS course dates</div>
            <Formik 
                initialValues={initialValues}
                validationSchema={Yup.object<TreatmentFormValues>({
                    start: Yup.date().label("Course start").notRequired(),
                    end: Yup.date().label("Course end").notRequired()
                            // .min(Yup.ref("start"), "Course end must be after course start.")
                })}
                onSubmit={onSubmit}>
                    {({isSubmitting}) => (
                    <Form>
                        <div className="form-fields">
                            <div className="form-field-half">
                                <label htmlFor="start">Course Start</label>
                                <DatePickerField name="start" />
                                <ErrorMessage component={ErrorText} name="start" />
                            </div>
                            <div className="form-field-half">
                                <label htmlFor="end">Course End</label>
                                <DatePickerField name="end" />
                                <ErrorMessage component={ErrorText} name="end" />
                            </div>
                        </div>
                        {error ? <ErrorText>{error}</ErrorText> : null}
                        {isSubmitting ? 
                            <div className="modal-actions">
                                <button type="button" className="w-button" disabled>Saving...</button>
                            </div>
                            :
                            <div className="modal-actions">
                                <button type="submit" className="w-button">Save</button>
                                <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    </div>
}



type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: () => void,
    course: TMSCourse
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        const courseResource = `tms-courses/${props.course.id}`;
        try {
            await sherpahDelete(`/patients/${patient!.user_id}/${courseResource}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this TMS course?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                "Deleting..."
            :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}
