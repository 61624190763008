import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "../../contexts/AxiosContext";
import { DEFAULT_TOAST_SETTINGS } from "../../utils";

import './login.scss';
import { ErrorText } from "../../components/Errors";
import Loading from "../../components/Loading";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UnauthContainer } from "./UnauthContainer";
import { Routes } from "../Routes";


type ResetPasswordFormValues = {
    password: string,
    confirm_password: string
};


export default function ResetPasswordForm() {
    const params = useParams<{token: string}>();
    
	const axiosContext = useContext(AxiosContext)!;
	const [isValidToken, setIsValidToken] = useState<boolean | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const validateToken = async () => {
        try {
            const response = await axiosContext.publicAxios.post("accounts/password_reset/validate_token/", {
                token: params.token
            });
            setIsValidToken(true);
        } catch (e: any) {
            if (e.response && e.response.status == 404) {
                setIsValidToken(false);
            } else {
                axiosContext.catchAxios(e, setError);
            }
        }
    };

    useEffect(() => {
        validateToken();
    }, []);


    const onSubmit = async (values: ResetPasswordFormValues, { setSubmitting }: FormikHelpers<ResetPasswordFormValues>) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosContext.publicAxios.post("accounts/password_reset/confirm/", {
                token: params.token,
                password: values.password
            });
            navigate(Routes.anon.accounts.login2);
            toast("Password updated.", DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            axiosContext.catchAxios(e, setError);
        }
    };

    const initialValues: ResetPasswordFormValues = {
        password: "",
        confirm_password: ""
    };

    let content;
    if (isValidToken === null) {
        content = <Loading text="Checking reset link..." />;
    } else if (isValidToken === false) {
        content = <p>This password reset link is invalid, possibly because it has already been used. Please request a new password reset.</p>
    } else if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (isValidToken === true) {
        content = <Formik 
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
                password: Yup.string().required("Password is required"),
                confirm_password: Yup.string()
                   .oneOf([Yup.ref("password")], "Passwords must match")
            })}>
                {({isSubmitting}) => (
                <Form className="login-form form">
                    <div className="form-question">
                        <label>New password</label>
                        <ErrorMessage component={ErrorText} name="password" />
                        <Field type="password" name="password" />
                    </div>
                    <div className="form-question">
                        <label>Confirm password</label>
                        <ErrorMessage component={ErrorText} name="confirm_password" />
                        <Field type="password" name="confirm_password" />
                    </div>
                    { isSubmitting ?
                    <button type="submit" disabled>Updating...</button> :
                    <button type="submit">Update password</button> }
                </Form>
                )}
        </Formik>
    }

    return <UnauthContainer>
        {content}
    </UnauthContainer>;
}