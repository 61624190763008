import React from "react";


export function UnauthContainer(props: React.PropsWithChildren) {
    return <div className="login-main">
        <div className="rounded-container login-container">
            <div className="logo-block">
                <img src="/sherpah-logo.png" className="logo" />
                <span className="logo-title">Sherpah</span>
            </div>
            {props.children}
        </div>
    </div>;
}