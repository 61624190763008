import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip as TooltipChartJS,
    ChartData,
    BarElement,
    LineController,
    BarController,
    TimeScale,
    ChartOptions,
    ChartDataset
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Report } from './reportTypes';
import { DateTime, Duration } from 'luxon';


ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Legend,
    BarElement,
    LineController,
    BarController,
    TooltipChartJS,
    zoomPlugin
);

export default function ReportGraph(props: {report: Report, testingNewScores?: boolean, showHrv?: boolean}) {
    const date = DateTime.fromISO(props.report.date);
    const timestamps = Array.from({length: 1440}, (_, i) => date.plus({minutes: i+720}));

    const options: ChartOptions = {
        responsive: true,
        animation: false as const,
        plugins: {
            legend: false as const,
            tooltip: {
                enabled: true,
                intersect: false
            },
            datalabels: {
                display: false
            }
        },
        scales: {
            x: {
                type: "time" as const,
                time: {
                    displayFormats: {
                        hour: "ha"
                    }
                }
            },
            y: {
                min: 40,
                max: 180
            }
        }
    };

    let datasets: ChartDataset<"line" | "bar", number[] | {x: DateTime, y: number}[]>[] = [
        {
            type: "line" as const,
            label: "Heart rate",
            data: props.report.metrics.heart_rate,
            pointRadius: 0,
            pointBackgroundColor: "#7ace4c",
            fill: "#7ace4c",
            borderColor: "#7ace4c",
            borderJoinStyle: "round",
            borderWidth: 2.5
        }, {
            type: "bar" as const,
            label: "Steps",
            data: props.report.metrics.steps.map(v => 40+v/5),
            backgroundColor: "#2A3238",
            barPercentage: 1.5
        }
    ];

    if (props.report.metrics.hrv && props.showHrv) {
        datasets.push({
            type: "line" as const,
            label: "HRV HF",
            data: props.report.metrics.hrv.map(v => 40+v/40),
            pointRadius: 0,
            pointBackgroundColor: "#ed9631",
            fill: "#ed9631",
            borderColor: "#ed9631",
            borderJoinStyle: "round",
            borderWidth: 2.5
        });
    }

    if (props.report.stats) {
        const bucketAwake = props.report.stats.avg_awake_heart_rate;
        const bucketSleep = 0.7 * bucketAwake;
        const sleepOnset = DateTime.fromISO(props.report.stats.sleep_onset);
        const sleepFinish = DateTime.fromISO(props.report.stats.sleep_finish);
        datasets.push({
            type: "line" as const,
            data: [
                {x: timestamps[0], y: bucketAwake},
                {x: sleepOnset, y: bucketAwake},
                {x: sleepOnset, y: bucketSleep},
                {x: sleepFinish, y: bucketSleep},
                {x: sleepFinish, y: bucketAwake},
                {x: timestamps[timestamps.length-1], y: bucketAwake},
            ],
            pointRadius: 0,
            borderColor: "rgba(120, 120, 120, 0.2)",
            borderWidth: 30
        });

        if (props.testingNewScores) {
            props.report.stats.interruption_detail.forEach(interruption => {
                const height = 40;
                const start = DateTime.fromISO(interruption.start);
                const end = DateTime.fromISO(interruption.end);
                datasets.push({
                    type: "line" as const,
                    data: [
                        {x: start, y: height},
                        {x: end, y: height},
                    ],
                    pointRadius: 0,
                    borderColor: "rgba(220, 0, 0, 0.4)",
                    borderWidth: 30
                });
            });
        }
    }


    const data: ChartData<"line" | "bar", number[] | {x: DateTime, y: number}[], DateTime> = {
        labels: timestamps,
        datasets: datasets,
    };

    return <Chart type="bar" data={data} options={options} />;
}
