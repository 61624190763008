import React from "react";
import { pie, arc, hsl, PieArcDatum } from "d3";
import { scaleSequential } from "d3-scale";

const innerRadius = 35;
const outerRadius = 49;

const trafficLights = scaleSequential((t: number) => {
	return hsl((1-t) * 84, 1, 0.5) + "";
});

type GaugeProps = {
	scale?: number,
	precision?: number,
	value: number,
	maxValue: number,
	valueDesc: string
};

const Gauge = (props: GaugeProps) => {
	// relative size of gauge
	const scale = props.scale || 1;
	// float precision of value
	const precision = props.precision == undefined ? 1 : props.precision;

	const noArcs = Math.round(props.value*60/props.maxValue);
	function arcColour(d: PieArcDatum<number>) {
		if(d.index < noArcs) return(trafficLights(d.startAngle/Math.PI + 1/2));
		else return "white";
	}

	const data = Array(60).fill(1);
	const rainbowPie = pie<number>()
		.startAngle(-Math.PI/2)
		.endAngle(Math.PI/2);
	const rainbowArcs = rainbowPie(data);
	const rainbowPaths = rainbowArcs.map(a => {
		return {
			"arc": arc<PieArcDatum<number>>()
				.innerRadius(innerRadius)
				.outerRadius(outerRadius)(a),
			"fill": arcColour(a)
		};
	});

	const backgroundPie = pie<number>()
		.sortValues(null)
		.startAngle(-Math.PI/2)
		.endAngle(Math.PI/2);
	const backgroundArcs = backgroundPie([1]);
	const backgroundPaths = backgroundArcs.map(a => {
		return {
			"arc": arc<PieArcDatum<number>>()
				.innerRadius(innerRadius)
				.outerRadius(outerRadius)(a),
			"fill": arcColour(a)
		};
	});

	return (
		<svg
			viewBox="0 0 100 70"
			height={70 * scale}
			width={100 * scale} >
			<g transform="translate(50, 50)">
				{rainbowPaths.map((p,i) => <path d={p.arc!} 
					key={i}
					fill={p.fill}
					stroke={p.fill} />
				)}
				{backgroundPaths.map((p,i) => <path d={p.arc!} 
					key={i}
					fill="rgba(0,0,0,0)"
					stroke="black"
					strokeWidth={2} />
				)}
				<text y={-6} textAnchor="middle" alignmentBaseline="baseline" fontSize={20} fill="black">{Number(props.value).toFixed(precision)}</text>
				<text y={5} textAnchor="middle" alignmentBaseline="hanging" fontSize={12} fill="black">{props.valueDesc}</text>
			</g>
		</svg>
	);
};


export default Gauge;
