import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';

import { Operator } from '../../types';

import "./doctor.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faUserNurse } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ErrorText } from '../../components/Errors';
import Loading from '../../components/Loading';


type OperatorsResponse = {
    operators: Operator[],
};


export default function Operators() {
    const [operators, setOperators] = useState<Operator[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [deletingOperator, setDeletingOperator] = useState<Operator | null>(null);
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;

    const getOperators = async () => {
        setError(null);
        try {
            const response = await sherpahGet<OperatorsResponse>(`/doctors/%USERID%/operators`);
            setOperators(response.data.operators);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOperators();
    }, []);

    let content;
    if (error != null) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <>
            <div className="heading-wrapper">
                <h1>Operators</h1>
                <div className="heading-wrapper-actions">
                    <Link to={`/operators/add`} className="w-button secondary-button fa-icon-button">
                        <FontAwesomeIcon icon={faUserNurse} />
                        Add operator
                    </Link>
                </div>
            </div>
            <div className="rounded-container">
                { operators.length == 0 ? 
                    <i className="empty-message">You currently have no operators.</i>
                    :
                    <div className="flex-col-gap-16">
                        <div className="list-container tall-list">
                            <div className="list-columns operators-list-grid">
                                <div className="list-column">NAME</div>
                                <div className="list-column">ACTIONS</div>
                            </div>
                            { operators.map(operator => 
                                <div className="list-item operators-list-grid" key={operator.user.id}>
                                    <Link to={`/operators/${operator.user.id}/edit`}>
                                        {operator.user.last_name}, {operator.user.first_name}
                                    </Link>
                                    <div className="list-actions">
                                        <Link to={`/operators/${operator.user.id}/edit`} className="list-action" data-tooltip-id="edit" data-tooltip-content="Edit">
                                            <FontAwesomeIcon icon={faEdit} className="list-action-icon" />
                                            <Tooltip id="edit" style={{zIndex: 1000}}/>
                                        </Link>
                                        <div onClick={() => setDeletingOperator(operator)} className="list-action" data-tooltip-id="delete" data-tooltip-content="Delete">
                                            <FontAwesomeIcon icon={faTrashAlt} className="list-action-icon" />
                                            <Tooltip id="delete" style={{zIndex: 1000}}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </>;
    }

    return <main>
        {content}
        { deletingOperator ? <ConfirmDeleteModal
            operator={deletingOperator}
            onClose={() => setDeletingOperator(null)}
            onDelete={() => {
                setOperators(operators => operators.filter(o => o.user.id != deletingOperator.user.id));
                setDeletingOperator(null);
            }} /> : null }
    </main>;
}


type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: () => void,
    operator: Operator
};

function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        try {
            const response = await sherpahDelete<Operator>(`/operators/${props.operator.user.id}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this operator?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                "Deleting..." :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}