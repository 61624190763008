import React, {createContext, useContext, useRef, useState} from "react";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import {AuthContext, AuthSource} from "./AuthContext";
import createAuthRefreshInterceptor from "axios-auth-refresh";
// import { decodeJwt } from "../utils";
import { DOMAIN } from "../constants";
import { FormikErrors } from "formik";
import { useNavigate } from "react-router-dom";


export type ErrorResponse = {
	error?: string,
	serializer_errors?: { [field: string]: string }
};

type CatchAxiosFunctionType = (
    error: AxiosError<ErrorResponse>,
    setErrorState: (value: React.SetStateAction<string | null>) => void,
    setSerializerErrors?: <T>(errors: FormikErrors<T>) => void
) => void;

type AxiosContextType = {
	authAxios: AxiosInstance,
	publicAxios: AxiosInstance,
	sherpahGet: <T>(url: string) => Promise<AxiosResponse<T, any>>,
	sherpahPost: <T>(url: string, data: any) => Promise<AxiosResponse<T, any>>,
	sherpahPut: <T>(url: string, data: any) => Promise<AxiosResponse<T, any>>,
	sherpahDelete: <T>(url: string) => Promise<AxiosResponse<T, any>>,
	catchAxios: CatchAxiosFunctionType
};

const AxiosContext = createContext<AxiosContextType | null>(null);

const AxiosProvider = ({children}: React.PropsWithChildren) => {
    const navigate = useNavigate();
	const authContext = useContext(AuthContext);

	const authAxios = axios.create({
		baseURL: DOMAIN,
		headers: {
			"Accept": "application/json"
		}
	});

	const publicAxios = axios.create({
		baseURL: DOMAIN,
		headers: {
			"Accept": "application/json"
		}
	});

	authAxios.interceptors.request.use(
		config => {
			if (config && config.headers) { // } && !config.headers.Authorization) {
				const bearer = authContext?.accessToken.current ?? authContext?.authState.cognitoSession?.getAccessToken().getJwtToken();
				config.headers.Authorization = `Bearer ${bearer}`;
			}
			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	createAuthRefreshInterceptor(authAxios, authContext?.doAuthRefresh!, {});

	const sherpahGet: <T>(url: string) => Promise<AxiosResponse<T, any>> = async (url) => {
		const userId = authContext!.getUserId();
		if (userId == null) {
			throw Error("No user id found.");
		}
		const interpolatedUrl = url.replace("%USERID%", userId!);
		const response = await authAxios.get(interpolatedUrl);
		return response;
	};

	const sherpahPost: <T>(url: string, data: any) => Promise<AxiosResponse<T, any>> = async (url, data) => {
		const userId = authContext!.getUserId();
		if (userId == null) {
			throw Error("No user id found.");
		}
		const interpolatedUrl = url.replace("%USERID%", userId!);
		const response = await authAxios.post(interpolatedUrl, data);
		return response;
	};

	const sherpahPut: <T>(url: string, data: any) => Promise<AxiosResponse<T, any>> = async (url, data) => {
		const userId = authContext!.getUserId();
		if (userId == null) {
			throw Error("No user id found.");
		}
		const interpolatedUrl = url.replace("%USERID%", userId!);
		const response = await authAxios.put(interpolatedUrl, data);
		return response;
	};

	const sherpahDelete: <T>(url: string) => Promise<AxiosResponse<T, any>> = async (url) => {
		const userId = authContext!.getUserId();
		if (userId == null) {
			throw Error("No user id found.");
		}
		const interpolatedUrl = url.replace("%USERID%", userId!);
		const response = await authAxios.delete(interpolatedUrl);
		return response;
	};

    const catchAxios = (
        error: AxiosError<ErrorResponse>,
        setErrorState: (value: React.SetStateAction<string | null>) => void,
        setSerializerErrors?: <T>(errors: FormikErrors<T>) => void
    ) => {
		// if accessing forbidden resource, navigate home
        if (error.response && error.response.status == 403) {
			setErrorState("Action not permitted.");
        	// navigate("/");
        }
        if (error.response) {
            if (error.response.data) {
				// handle error message returned from server
                if (error.response.data.error) {
                    setErrorState(error.response.data.error);
				// handle error messages returned from serializers, mostly resulting from invalid form submissions
                } else if (error.response.data.serializer_errors) {
                    if (setSerializerErrors) {
                        setSerializerErrors(error.response.data.serializer_errors);
                    } else {
                        setErrorState("Serializer error.");
                    }
                } else {
                    setErrorState("Server returned an error.");
                }
            } else if (error.response.status == 0) {
                setErrorState("Couldn't connect to server.");
            } else if (error.response.status == 404) {
                setErrorState("Server resource not found.");
            } else if (error.response.status == 500) {
                setErrorState("Server error.");
            } else {
                setErrorState("Server response error.");
            }
        } else if (error.request) {
            setErrorState("Couldn't reach server.");
        } else {
            setErrorState("Error.");
        }
    }

	return (
		<AxiosContext.Provider
			value={{
				authAxios,
				publicAxios,
				sherpahGet,
				sherpahPost,
				sherpahPut,
				sherpahDelete,
				catchAxios
			}}>
			{children}
		</AxiosContext.Provider>
	);
};

export {AxiosContext, AxiosProvider};