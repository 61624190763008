import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ErrorText } from '../../components/Errors';


export default function SignupVerification() {
    const params = useParams<{code: string}>();
	const { publicAxios, catchAxios } = useContext(AxiosContext)!;
	const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const validateToken = async () => {
        try {
            const response = await publicAxios.get(`/signup/verification/${params.code}`);
            navigate("/redirect?mobile=verification?status=verified");
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    useEffect(() => {
        validateToken();
    }, []);

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else {
        content = <Loading text="Verifying..." />;
    }

    return <main>
        {content}
    </main>;
}
