import { faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";

export function ErrorText(props: PropsWithChildren & {style?: any}) {
	return (
        <div className="danger-text" style={props.style}>
			<FontAwesomeIcon
                icon={faExclamationTriangle}
				style={{width: 14, height: 14, marginRight: 6}} />
			{props.children}
		</div>
	);
}

export function ErrorBox(props: PropsWithChildren & {style?: any}) {
	return (
        <div className="danger-box" style={props.style}>
			<FontAwesomeIcon
                icon={faExclamationTriangle}
				style={{width: 16, height: 16, marginRight: 12}} />
			{props.children}
		</div>
	);
}

export function WarningBox(props: PropsWithChildren & {style?: any}) {
	return (
        <div className="warning-box" style={props.style}>
			<FontAwesomeIcon
                icon={faExclamationCircle}
				style={{width: 16, height: 16, marginRight: 12}} />
			{props.children}
		</div>
	);
}
