import DatePicker from 'react-datepicker';
import { useContext, useEffect, useState } from 'react';
import { PopulatedDates, Report } from './reportTypes';
import { DateTime, Duration } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { AnnotateReportForm } from '../../admin/AlgorithmData';
import { PatientContext } from '../../../contexts/PatientContext';
import ReportStats from './ReportStats';
import Loading from '../../../components/Loading';
import { ErrorBox, WarningBox } from '../../../components/Errors';
import ReportGraph from './ReportGraph';


export default function ReportSection(props: {report?: Report, testingNewScores?: boolean, populatedDates?: PopulatedDates | null}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramDate = searchParams.get("date");
    const initialDate = paramDate ? DateTime.fromISO(paramDate).toJSDate() : DateTime.now().startOf("day").toJSDate();
    const [date, setDate] = useState<Date>(initialDate);
    const [report, setReport] = useState<Report | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [scoresError, setScoresError] = useState<string | null>(null);
    const [warnings, setWarnings] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showTrainingBlock, setShowTrainingBlock] = useState<boolean>(false);
    const [showHrv, setShowHrv] = useState<boolean>(true);
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const patient = useContext(PatientContext)!.patient!;

    const populatedDayClassName = (date: Date) => {
        const dateString = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
        if (!props.populatedDates) return "";
        if (props.populatedDates.full.includes(dateString)) {
            return "datepicker-populated-full";
        } else if (props.populatedDates.partial.includes(dateString)) {
            return "datepicker-populated-partial";
        } else {
            return "";
        }
    }

    const getReport = async () => {
        setError(null);
        setScoresError(null);
        setWarnings([]);
        setLoading(true);
        setReport(null);
        const dateString = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
        try {
            const response = await sherpahGet<Report>(`patients/${patient.user_id}/metrics?date=${dateString}`);
            if ("scores_error" in response.data) setScoresError(response.data.scores_error!);
            setWarnings(response.data.warnings || []);
            setReport(response.data);
            setShowTrainingBlock(response.data.needs_annotating || false);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getReport();
    }, [date]);

    let content;
    if (report) {
        content = <>
            { report.stats && showTrainingBlock ? 
                <section className="rounded-container">
                    <AnnotateReportForm date={DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')} patientId={patient.user.id.toString()} onSkip={() => setShowTrainingBlock(false)} />
                </section> : null }

            <section id="reportContainer">
                {/* <h2>Heart Rate and Steps</h2> */}
                <ReportGraph report={report!} testingNewScores={props.testingNewScores} showHrv={showHrv} />
                <div className="graph-legend">
                    <div>
                        <div className="graph-legend-square" style={{ backgroundColor: "#7ACE4C" }}></div>
                        <div>
                            {/* <input type="checkbox" /> */}
                            <label>Heart Rate</label>
                        </div>
                    </div>
                    <div>
                        <div className="graph-legend-square" style={{ backgroundColor: "#2A3238" }}></div>
                        <div>
                            {/* <input type="checkbox" /> */}
                            <label>Steps</label>
                        </div>
                    </div>
                    <div>
                        <div className="graph-legend-square" style={{ backgroundColor: "#E4E4E4" }}></div>
                        <div>
                            {/* <input type="checkbox" /> */}
                            <label>Sleep</label>
                        </div>
                    </div>
                    { report.metrics.hrv ? 
                        <div>
                            <div className="graph-legend-square" style={{ backgroundColor: "#ed9631" }}></div>
                            <div>
                                <input type="checkbox" defaultChecked={showHrv} onChange={e => setShowHrv(e.target.checked)} />
                                <label>HRV HF</label>
                            </div>
                        </div>
                    : null }
                </div>
            </section>

            { report.stats ? <ReportStats report={report} /> : null }
        </>;
    } else {
        // content = <div>no report</div>;
    }


    return <div className="flex-col-gap-16">
        <div className="graph-controls-left">
            <b>Select date: </b>
            <button className="date-mover" onClick={() => setDate(d => new Date(d.getTime() - 86400000))}><FontAwesomeIcon icon={faCaretLeft} /></button>
            <DatePicker
                className="report-date-picker"
                selected={date}
                onChange={(d: Date) => {
                    setDate(d);
                }}
                dateFormat="d MMM yyyy"
                dayClassName={populatedDayClassName} />
            <button className="date-mover" onClick={() => setDate(d => new Date(d.getTime() + 86400000))}><FontAwesomeIcon icon={faCaretRight} /></button>
            <div style={{flex: 1}}></div>
        {/* </div>
        <div className="graph-controls-left"> */}
            <b>Go to: </b>
            <button className="w-button pagination" onClick={() => setDate(DateTime.now().toJSDate())}>Today</button>
            <button className="w-button pagination" onClick={() => setDate(DateTime.now().minus({days: 1}).toJSDate())}>Yesterday</button>
            { patient.latest_report ? <button className="w-button pagination" onClick={() => setDate(DateTime.fromISO(patient.latest_report!).toJSDate())}>Last full report</button> : null }
        </div>
        
        { (error || scoresError || warnings.length > 0) ? <div style={{display: "flex", gap: "8px", flexDirection: "column"}}>
                { scoresError ? <ErrorBox>{scoresError}</ErrorBox> : null }
                { error ? <ErrorBox>{error}</ErrorBox> : null }
                { warnings.map((warning, i) => <WarningBox key={i}>{warning}</WarningBox>) }
            </div> : null }

        { loading ? <Loading /> : null }

        { content }

    </div>;
}
