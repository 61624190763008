import { useContext, useEffect, useReducer, useState } from 'react';
import { TMSCourse as TMSCourseType } from '../../../types';
import { PatientContext } from '../../../contexts/PatientContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { TMSCourseModal, ConfirmDeleteModal } from './TMSModals';
import TMSCourse from './TMSCourse';
import Loading from '../../../components/Loading';
import { ErrorText } from '../../../components/Errors';


type TMSCourseAction = 
    { type: "initialise", courses: TMSCourseType[] } |
    { type: "add", course: TMSCourseType } | 
    { type: "edit", course: TMSCourseType } |
    { type: "delete", courseId: string };

function TMSCoursesReducer(courses: TMSCourseType[], action: TMSCourseAction) {
    switch (action.type) {
        case "initialise": {
            return action.courses;
        }
        case "add": {
            return [...courses, action.course];
        }
        case "edit": {
            return courses.map(c => c.id === action.course!.id ? action.course : c);
        }
        case "delete": {
            return courses.filter(c => c.id !== action.courseId);
        }
    }
}


type TMSCoursesResponse = {
    tms_courses: TMSCourseType[]
};

export default function TMSTab() {
    const [editingTMSCourse, setEditingTMSCourse] = useState<TMSCourseType | null>(null);
    const [deletingCourse, setDeletingCourse] = useState<TMSCourseType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { sherpahGet, sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!;
    const [tmsCourses, tmsDispatch] = useReducer(TMSCoursesReducer, []);

    const getTmsCourses = async () => {
        try {
            const response = await sherpahGet<TMSCoursesResponse>(`/patients/${patient!.user_id}/tms-courses`);
            tmsDispatch({type: "initialise", courses: response.data.tms_courses});
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    const addTmsCourse = async () => {
        setLoading(true);
        setError(null);
        try {
            // posting new tms course
            const response = await sherpahPost<TMSCourseType>(`/patients/${patient!.user_id}/tms-courses`, {});
            tmsDispatch({type: "add", course: response.data});
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    const setIsCompleted = async (course: TMSCourseType, isCompleted: boolean) => {
        setLoading(true);
        setError(null);
        try {
            const response = await sherpahPut<TMSCourseType>(`/patients/${patient!.user_id}/tms-courses/${course.id}`, {
                is_completed: isCompleted
            });
            tmsDispatch({type: "edit", course: response.data});
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTmsCourses();
    }, []);

    if (error) {
        return <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        return <Loading text="Loading TMS courses..." />;
    }

    return <div className="flex-col-gap-16">
        {tmsCourses.length > 0 ? 
            tmsCourses.map((course, index) => (
                <TMSCourse
                    tmsCourse={course}
                    index={index+1}
                    key={index}
                    onSetIsCompleted={(isCompleted: boolean) => setIsCompleted(course, isCompleted)}
                    onDelete={() => setDeletingCourse(course)}
                    onUpdate={(tmsCourse: TMSCourseType) => tmsDispatch({type: "edit", course: tmsCourse})}
                    onEdit={() => setEditingTMSCourse(course)} />
            )) :
            <i className="empty-message">No TMS courses</i>
        }

        { !isShared ?
            <div>
                <button className="w-button fa-icon-button secondary-button" onClick={addTmsCourse}>
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faFolderPlus} /></span>
                    Add TMS course
                </button>
            </div> : null }

        { editingTMSCourse ? <TMSCourseModal
            tmsCourse={editingTMSCourse}
            onClose={() => {
                setEditingTMSCourse(null);
            }}
            onEdit={(course: TMSCourseType) => {
                tmsDispatch({type: "edit", course});
                setEditingTMSCourse(null);
            }} />
        : null }

        { deletingCourse ? <ConfirmDeleteModal
            course={deletingCourse}
            onClose={() => setDeletingCourse(null)}
            onDelete={() => {
                tmsDispatch({type: "delete", courseId: deletingCourse.id});
                setDeletingCourse(null);
            }} /> : null }
    </div>;
}
