import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';

import { Doctor, Patient, User } from '../../types';
import { ErrorMessage, Field, Form, Formik, FormikHandlers, FormikHelpers } from 'formik';
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { toast } from 'react-toastify';
import { ErrorText } from '../../components/Errors';


type AddDoctorFormValues = {
    user__first_name: string,
    user__last_name: string,
    user__email: string,
    mobile_number: string | null,
    fax_number: string | null,
    clinic_name: string | null,
    clinic_address: string | null,
    clinic_phone: string | null,
    clinic_fax: string | null,
};

export default function AddDoctor() {
    const [error, setError] = useState<string | null>(null);
    const { sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const navigate = useNavigate();

    const onSubmit = async (values: AddDoctorFormValues, { setSubmitting, setErrors }: FormikHelpers<AddDoctorFormValues>) => {
        try {
            const response = await sherpahPost<Doctor>(`/doctors/`, values);
            navigate(-1);
            toast('Doctor has been added.', DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    }

    const initialValues: AddDoctorFormValues = {
        user__first_name: "",
        user__last_name: "",
        user__email: "",
        mobile_number: "",
        fax_number: "",
        clinic_name: "",
        clinic_address: "",
        clinic_phone: "",
        clinic_fax: "",
    };

    return <main className="narrow">
        <div className="heading-wrapper">
            <h1>Add doctor</h1>
        </div>
        <div className="rounded-container">
            <Formik 
                initialValues={initialValues}
                onSubmit={onSubmit}>
                    {({isSubmitting}) => (
                    <Form>
                        <h3>Doctor details</h3>
                        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                            <div>
                                <label>First name</label>
                                <Field type="text" name="user__first_name" />
                                <ErrorMessage name="user__first_name" component={ErrorText} />
                            </div>
                            <div>
                                <label>Last name</label>
                                <Field type="text" name="user__last_name" />
                                <ErrorMessage name="user__last_name" component={ErrorText} />
                            </div>
                            <div>
                                <label>Email</label>
                                <Field type="text" name="user__email" />
                                <ErrorMessage name="user__email" component={ErrorText} />
                            </div>

                            <div>
                                <label>Mobile number</label>
                                <Field type="text" name="mobile_number" />
                                <ErrorMessage name="mobile_number" component={ErrorText} />
                            </div>
                            <div>
                                <label>Fax number</label>
                                <Field type="text" name="fax_number" />
                                <ErrorMessage name="fax_number" component={ErrorText} />
                            </div>
                        </div>

                        <br/>

                        <h3>Clinic details</h3>
                        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                            <div>
                                <label>Clinic name</label>
                                <Field type="text" name="clinic_name" />
                                <ErrorMessage name="clinic_name" component={ErrorText} />
                            </div>
                            <div>
                                <label>Clinic address</label>
                                <Field type="text" name="clinic_address" />
                                <ErrorMessage name="clinic_address" component={ErrorText} />
                            </div>
                            <div>
                                <label>Clinic phone</label>
                                <Field type="text" name="clinic_phone" />
                                <ErrorMessage name="clinic_phone" component={ErrorText} />
                            </div>
                            <div>
                                <label>Clinic fax</label>
                                <Field type="text" name="clinic_fax" />
                                <ErrorMessage name="clinic_fax" component={ErrorText} />
                            </div>
                        </div>
                        
                        {error ? <ErrorText>{error}</ErrorText> : null}

                        {isSubmitting ? 
                            <div>Adding...</div>
                            :
                            <div style={{display: "flex", gap: "8px"}}>
                                <button type="submit" className="w-button">Add</button>
                                <button type="button" className="secondary-button w-button" onClick={() => navigate(-1)}>Cancel</button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    </main>

}