export default function TCsSherpah() {
    return (
        <main style={{ maxWidth: 800, margin: "0 auto" }}>
            <h1>Terms and Conditions for MySherpah</h1>
            <p>Welcome to MySherpah, a mobile application designed to support your mental health journey. By using MySherpah, you agree to the following terms and conditions:</p>
            <ol>
                <li>
                    <b>Acceptance of Terms</b>: By downloading, installing, or using MySherpah, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use the application.
                </li>
                <li>
                    <b>Subscription and Payment</b>: MySherpah operates on a subscription basis. Users are required to pay a subscription fee once every three months to access the full features of the application. Payment is charged automatically to the payment method provided by the user upon subscription. By providing payment information, you authorize MySherpah to charge the subscription fee.
                </li>
                <li>
                    <b>Cancellation</b>: Subscribers can cancel their subscription at any time by emailing contact@sherpah.com.au and requesting cancellation. Cancellation requests must be made at least 24 hours before the next billing cycle to avoid being charged for the subsequent period.
                </li>
                <li>
                    <b>Data Collection</b>: MySherpah collects various types of data, including but not limited to, responses to mental health questionnaires, personal information provided by the user, and health data such as heart rate and steps from compatible smartwatches. By using the application, you consent to the collection of this data.
                </li>
                <li>
                    <b>Use of Data</b>: MySherpah may use the collected data for research and development activities aimed at improving the application's features and effectiveness in supporting mental health. This may include analyzing aggregated data and publishing research articles based on the findings. Any data used for research purposes will be deidentified to protect the privacy of users.
                </li>
                <li>
                    <b>Compliance with Laws</b>: MySherpah operates in accordance with the laws and regulations of Western Australia, Australia. Users are responsible for complying with all applicable laws when using the application.
                </li>
                <li>
                    <b>Disclaimer</b>: MySherpah is not a substitute for professional medical advice, diagnosis, or treatment. The information provided by the application is for informational purposes only and should not be considered medical advice. Users should consult with a qualified healthcare professional for personalized advice and treatment.
                </li>
                <li>
                    <b>Limitation of Liability</b>: MySherpah and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the application, including but not limited to, any loss of data, loss of revenue or profit, or any other commercial or economic loss.
                </li>
                <li>
                    <b>Changes to Terms</b>: MySherpah reserves the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on the application. Continued use of MySherpah after such changes constitutes acceptance of the modified terms.
                </li>
                <li>
                    <b> Contact Us</b>: If you have any questions or concerns about these terms and conditions, please contact us at contact@sherpah.com.au.
                </li>
            </ol>

            <p>By using MySherpah, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. Thank you for choosing MySherpah to support your mental health journey.</p>
        </main>
    );
}