import { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';

import "./dass.scss";

import { User, DASS21 as DASS21Type } from '../../../types';
import { PatientContext } from '../../../contexts/PatientContext';
import { DEFAULT_TOAST_SETTINGS, formatDate, formatDatetime } from '../../../utils';
import { ErrorText } from '../../../components/Errors';
import { toast } from 'react-toastify';
import { DASS_QUESTIONS } from './questions';
import { DateTime } from 'luxon';
import Loading from '../../../components/Loading';
import DatePicker from 'react-datepicker';


type DASS21Answers = {
	[key in `q${1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21}`]: number | null
};

type QuestionProps = {
    question: string,
    index: number,
    answer?: number,
    onAnswer?: (questionIndex: keyof DASS21Answers, value: number) => void
};

function Question(props: QuestionProps) {
    const { question, index } = props;
    const [answer, setAnswer] = useState<number | null>(props.answer === undefined ? null : props.answer);

    const updateAnswer = (value: number) => {
        if (props.onAnswer) {
            setAnswer(value);
            props.onAnswer(`q${index}` as keyof DASS21Answers, value);
        }
    };

    return <>
        <div className="dass-question">
            <h4>{index}. {question}</h4>
            <div className="dass-options">
                <button onClick={() => updateAnswer(0)} className={answer == 0 ? "selected" : ""} disabled={props.answer !== undefined}>Never</button>
                <button onClick={() => updateAnswer(1)} className={answer == 1 ? "selected" : ""} disabled={props.answer !== undefined}>Sometimes</button>
                <button onClick={() => updateAnswer(2)} className={answer == 2 ? "selected" : ""} disabled={props.answer !== undefined}>Often</button>
                <button onClick={() => updateAnswer(3)} className={answer == 3 ? "selected" : ""} disabled={props.answer !== undefined}>Always</button>
            </div>
        </div>
        <hr/>
    </>;
}

type DASS21Results = DASS21Answers & {
    completed_at: string,
    backdated_to: string | null
};

export default function DASS21() {
    const [error, setError] = useState<string | null>(null);
    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [backdate, setBackdate] = useState<Date | null>(null);
    const { sherpahGet, sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const navigate = useNavigate();

    const params = useParams<{id: string}>();
    const showingResults = params.id !== undefined;

    const initialAnswers: DASS21Answers = DASS_QUESTIONS.reduce((obj: any, q, index) => {obj[`q${index+1}`] = null; return obj;}, {});
	const [answers, setAnswers] = useState<DASS21Answers>(initialAnswers);
    const [completedAt, setCompletedAt] = useState<string | null>(null);

    const getResults = async () => {
        try {
            const response = await sherpahGet<{"dass21": DASS21Results}>(`patients/${patient!.user_id}/dass21/${params.id}`);
            setAnswers(response.data.dass21);
            if (response.data.dass21.backdated_to) {
                setCompletedAt(formatDate(response.data.dass21.backdated_to) + " (backdated)");
            } else {
                setCompletedAt(formatDatetime(response.data.dass21.completed_at));
            }
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showingResults) {
            setLoading(true);
            getResults();
        }
    }, []);

	const postResults = async () => {
        setSubmitting(true);
        setSubmissionError(null);

        // ensure entire questionnaire was filled out
        let isCompleted = true;
        for (const answer in answers) {
            if (answers[answer as keyof DASS21Answers] === null) {
                isCompleted = false;
                break;
            }
        }
        if (!isCompleted) {
            setSubmissionError("Please answer all questions.");
            setSubmitting(false);
            return;
        }

        let data: any = {...answers};
        if (backdate) {
            data["backdated_to"] = DateTime.fromJSDate(backdate).toISODate();
        }

		try {
			await sherpahPost<DASS21Type>(
				`patients/${patient!.user_id}/dass21`,
				data
			);
            toast("DASS-21 completed!", DEFAULT_TOAST_SETTINGS);
            navigate(-1);
		} catch (e: any) {
			catchAxios(e, setSubmissionError);
		} finally {
			setSubmitting(false);
		}
	};

	const updateAnswers = (questionIndex: keyof DASS21Answers, value: number) => {
        setAnswers(a => {
            a[questionIndex] = value;
            return a;
        });
	};

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <div>
            { showingResults ? 
                <p><b>{patient?.user.full_name}</b> completed this questionnaire on <b>{ completedAt }</b></p>:
                <>
                    <p>The Depression, Anxiety and Stress Scale - 21 Items (DASS-21) is a set of three self-report scales designed to measure the emotional states of depression, anxiety and stress.</p>
                    <p>Please read each statement and choose a frequency that indicates how much the statement applied to you <b>over the past week</b>.</p>
                    <b>If this questionnaire was completed at a past date you may enter that date here:</b> <DatePicker
                        selected={backdate}
                        onChange={(d: Date) => setBackdate(d)}
                        dateFormat="d MMM yyyy" />
                </>
            }
            { submissionError ? <ErrorText>{submissionError}</ErrorText> : null }
            { showingResults ? 
                DASS_QUESTIONS.map((question, i) => <Question question={question} index={i+1} key={i} answer={answers[`q${i+1}` as keyof DASS21Answers]!} />) :
                DASS_QUESTIONS.map((question, i) => <Question question={question} index={i+1} key={i} onAnswer={updateAnswers} />)
            }
            { submissionError ? <ErrorText>{submissionError}</ErrorText> : null }
            { !showingResults ? (
                submitting ? 
                    <button disabled>Submitting...</button> : 
                    <button onClick={postResults}>Submit</button>
            ) : null }
        </div>;
    }
        
    return <main>
        <div className="heading-wrapper">
            <h1>DASS-21</h1>
        </div>
        <div className="rounded-container">
            { content }
        </div>
    </main>;
}