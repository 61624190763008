import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';

import { ErrorMessage, Field, Form, Formik, FormikHandlers, FormikHelpers } from 'formik';
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorText } from '../../components/Errors';
import { Operator } from '../../types';


type OperatorFormValues = {
    user__email: string,
    user__first_name: string,
    user__last_name: string,
};

type OperatorFormProps = {
    operator?: Operator
};

export default function OperatorForm(props: OperatorFormProps) {
    const { sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const onSubmit = async (values: OperatorFormValues, { setErrors }: FormikHelpers<OperatorFormValues>) => {
        setError(null);
        try {
            if (props.operator) {
                const { user__email, ...data} = values;
                const response = await sherpahPut(`/operators/${props.operator.user.id}`, data);
            } else {
                const response = await sherpahPost(`/doctors/%USERID%/operators`, values);
            }
            navigate(-1);
            toast(props.operator ? "Operator updated." : "Operator added.", DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    }

    const initialValues: OperatorFormValues = {
        user__email: props.operator?.user.email || "",
        user__first_name: props.operator?.user.first_name || "",
        user__last_name: props.operator?.user.last_name || "",
    };

    return <main className="narrow">
        <div className="heading-wrapper">
            <h1>{props.operator ? "Edit" : "Add"} operator</h1>
        </div>
        <div className="rounded-container">
            <Formik 
                validationSchema={Yup.object<OperatorFormValues>({
                    user__email: Yup.string().label("Email").required(),
                    user__first_name: Yup.string().label("First name").required(),
                    user__last_name: Yup.string().label("Last name").required(),
                })}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                    {({isSubmitting}) => (
                    <Form>
                        <div className="form-fields">
                            <div>
                                <label>Email</label>
                                <Field type="text" name="user__email" disabled={props.operator} />
                                <ErrorMessage name="user__email" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label>First name</label>
                                <Field type="text" name="user__first_name" />
                                <ErrorMessage name="user__first_name" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label>Last name</label>
                                <Field type="text" name="user__last_name" />
                                <ErrorMessage name="user__last_name" component={ErrorText} />
                            </div>
                        </div>

                        {error ? <ErrorText>{error}</ErrorText> : null}

                        <div className="actions">
                        { isSubmitting ? 
                            <button type="button" className="w-button" disabled>{ props.operator ? "Saving..." : "Adding..." }</button>
                            : <>
                                <button type="submit" className="w-button">{ props.operator ? "Save" : "Add" }</button>
                                <button type="button" className="secondary-button w-button" onClick={() => navigate(-1)}>Cancel</button>
                            </>
                        }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </main>;
}