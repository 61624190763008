import { FormEvent, SyntheticEvent } from "react";
import './Button.scss';

export type ButtonProps = {
    variant?: 'primary' | 'secondary' | 'tertiary'
    text?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: (e: SyntheticEvent) => void;
    onSubmit?: (e: FormEvent) => void;
}

export function Button({ variant = 'primary', disabled, text, onClick, onSubmit }: ButtonProps) {
    return <button disabled={disabled} className={`button ${variant}`} onSubmit={onSubmit} onClick={onClick}>{text}</button>
}
