import { useContext, useEffect, useReducer, useState } from 'react';
import {
    TMSCourse as TMSCourseType,
    TMSSession,
    TMSTreatmentSession
} from '../../../types';
import { PatientContext } from '../../../contexts/PatientContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileLines, faTrashAlt, faPrescriptionBottleMedical, faFolderPlus, faCirclePlus, faCheck, faXmark, faTrash, faCaretDown, faArrowRight, faRightLong, faAnglesRight, faCircleCheck, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { DateTime } from 'luxon';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { DatePickerField } from '../../../components/DatePickerField';
import { ErrorText } from '../../../components/Errors';
import * as Yup from 'yup';
import { downloadRequest, formatDate } from '../../../utils';
import { Paginated, PaginationControls } from '../../../components/Pagination';
import Loading from '../../../components/Loading';


type TMSSessionFormValues = {
    type: "Treatment" | "Mapping",
    start: string,
    side_of_head: "Left" | "Right",
    treatment_site: "DLPFC" | "SMA" | "Other",
    motor_threshold: number,
    treatment_intensity: number,
    pps: number,
    train_pulse_frequency: number,
	pulses_in_train: number,
	number_of_trains: number,
	intertrain_interval: number,
    side_effects: string,
    comment: string,
};

const SESSION_DEFAULTS = {
    type: "Treatment",
    start: DateTime.now().toFormat("yyyy-MM-dd"),
    side_of_head: "Left" as const,
    treatment_site: "DLPFC" as const,
    motor_threshold: 40,
    treatment_intensity: 100,
    pps: 1,
    side_effects: "",
    comment: "",
    train_pulse_frequency: 3,
    pulses_in_train: 16,
    number_of_trains: 20,
    intertrain_interval: 8,
    treatment_number: 0
};


type AddTMSSessionResponse = {tms_course: TMSCourseType} & Paginated<TMSSession>;
type EditTMSSessionResponse = {tms_course: TMSCourseType} & TMSSession;

type TMSSessionFormProps = {
    onAdd?: (paginatedSessions: AddTMSSessionResponse) => void,
    onEdit?: (session: EditTMSSessionResponse) => void,
    onCancel: () => void,
    tmsCourse: TMSCourseType,
    tmsSession?: TMSSession,
    prevSession?: TMSTreatmentSession | null,
};

function TMSSessionForm(props: TMSSessionFormProps) {
    const { sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [error, setError] = useState<string | null>(null);

    const onSubmit = async (values: TMSSessionFormValues, { setErrors }: FormikHelpers<TMSSessionFormValues>) => {
        try {
            if (props.tmsSession) { // editing tms session
                const resource = props.tmsSession.type == "Treatment" ? "treatments" : "mappings";
                const response = await sherpahPut<EditTMSSessionResponse>(`/patients/${patient!.user_id}/tms-courses/${props.tmsCourse.id}/${resource}/${props.tmsSession.id}`, values);
                props.onEdit!(response.data);
            } else { // posting new tms session
                // TODO: clean up this messy code
                const response = await sherpahPost<AddTMSSessionResponse>(`/patients/${patient!.user_id}/tms-courses/${props.tmsCourse.id}/sessions?return_page=true`, values);
                props.onAdd!(response.data);
            }
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    };

    let initialValues: TMSSessionFormValues;
    if (props.tmsSession) {
        initialValues = {
            type: props.tmsSession.type,
            start: props.tmsSession.start,
            side_of_head: props.tmsSession.side_of_head,
            treatment_site: props.tmsSession.treatment_site,
            motor_threshold: props.tmsSession.motor_threshold,
            // initialise default values that aren't used for tms mappings
            treatment_intensity: SESSION_DEFAULTS.treatment_intensity,
            pps: SESSION_DEFAULTS.pps,
            side_effects: SESSION_DEFAULTS.side_effects,
            comment: SESSION_DEFAULTS.comment,
            train_pulse_frequency: SESSION_DEFAULTS.train_pulse_frequency,
            pulses_in_train: SESSION_DEFAULTS.pulses_in_train,
            number_of_trains: SESSION_DEFAULTS.number_of_trains,
            intertrain_interval: SESSION_DEFAULTS.intertrain_interval,
        };
        if (props.tmsSession.type == "Treatment") {
            initialValues = {
                ...initialValues,
                treatment_intensity: props.tmsSession.treatment_intensity,
                pps: props.tmsSession.pps,
                side_effects: props.tmsSession.side_effects,
                comment: props.tmsSession.comment,
                train_pulse_frequency: props.tmsSession.train_pulse_frequency,
                pulses_in_train: props.tmsSession.pulses_in_train,
                number_of_trains: props.tmsSession.number_of_trains,
                intertrain_interval: props.tmsSession.intertrain_interval
            };
        }
    } else {
        initialValues = {
            type: "Treatment",
            start: DateTime.now().toFormat("yyyy-MM-dd"),
            side_of_head: props.prevSession?.side_of_head || SESSION_DEFAULTS.side_of_head,
            treatment_site: props.prevSession?.treatment_site || SESSION_DEFAULTS.treatment_site,
            motor_threshold: props.prevSession?.motor_threshold || SESSION_DEFAULTS.motor_threshold,
            treatment_intensity: props.prevSession?.treatment_intensity || SESSION_DEFAULTS.treatment_intensity,
            pps: props.prevSession?.pps || SESSION_DEFAULTS.pps,
            side_effects: props.prevSession?.side_effects || SESSION_DEFAULTS.side_effects,
            comment: "",
            train_pulse_frequency: props.prevSession?.train_pulse_frequency || SESSION_DEFAULTS.train_pulse_frequency,
            pulses_in_train: props.prevSession?.pulses_in_train || SESSION_DEFAULTS.pulses_in_train,
            number_of_trains: props.prevSession?.number_of_trains || SESSION_DEFAULTS.number_of_trains,
            intertrain_interval: props.prevSession?.intertrain_interval || SESSION_DEFAULTS.intertrain_interval,
        };
    }

    return <Formik 
        initialValues={initialValues}
        validationSchema={Yup.object<TMSSessionFormValues>({
            start: Yup.date().label("Session date").required(),
            motor_threshold: Yup.number().label("Motor threshold").required().min(0),
            treatment_intensity: Yup.number().label("Treatment intensity").required().min(0),
            pps: Yup.number().label("Pulse per second").required().min(0),
            train_pulse_frequency: Yup.number().label("Train pulse frequency").required().min(0),
            pulses_in_train: Yup.number().label("Pulses in train").required().min(0),
            number_of_trains: Yup.number().label("Number of trains").required().min(0),
            intertrain_interval: Yup.number().label("Intertrain interval").required().min(0)
        })}
        onSubmit={onSubmit}>
            {({isSubmitting, values}) => (
            <Form style={{ gap: 0 }}>
                <div className="list-item tms-full-list-grid">
                    <div>
                        <Field as="select" name="type">
                            <option value="Treatment">Treatment</option>
                            <option value="Mapping">Mapping</option>
                        </Field>
                    </div>
                    <div>
                        <DatePickerField name="start" />
                        <ErrorMessage component={ErrorText} name="start" />
                    </div>
                    <div>
                        <Field as="select" name="side_of_head">
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                        </Field>
                    </div>
                    <div>
                        <Field as="select" name="treatment_site">
                            <option value="DLPFC">DLPFC</option>
                            <option value="SMA">SMA</option>
                            <option value="Other">Other</option>
                        </Field>
                    </div>
                    <div>
                        <Field type="text" name="motor_threshold" className="number" min="0" />%
                        <ErrorMessage component={ErrorText} name="motor_threshold" />
                    </div>
                    { values.type == "Treatment" ? <>
                            <div>
                                <Field type="text" name="treatment_intensity" className="number" min="0" />%
                                <ErrorMessage component={ErrorText} name="treatment_intensity" />
                            </div>
                            <div>
                                <Field type="text" name="pps" className="number" min="0" />
                                <ErrorMessage component={ErrorText} name="pps" />
                            </div>
                            <div>
                                <Field type="text" name="train_pulse_frequency" className="number" min="0" />
                                <ErrorMessage component={ErrorText} name="train_pulse_frequency" />
                            </div>
                            <div>
                                <Field type="text" name="pulses_in_train" className="number" min="0" />
                                <ErrorMessage component={ErrorText} name="pulses_in_train" />
                            </div>
                            <div>
                                <Field type="text" name="number_of_trains" className="number" min="0" />
                                <ErrorMessage component={ErrorText} name="number_of_trains" />
                            </div>
                            <div>
                                <Field type="text" name="intertrain_interval" className="number" min="0" /> seconds
                                <ErrorMessage component={ErrorText} name="intertrain_interval" />
                            </div>
                            <div>
                                <Field type="text" name="side_effects" />
                                <ErrorMessage component={ErrorText} name="side_effects" />
                            </div>
                            <div>
                                <Field type="text" name="comment" />
                                <ErrorMessage component={ErrorText} name="comment" />
                            </div>
                        </> : 
                        <>{Array(8).fill(undefined).map((_,i) => <div className="empty-message" key={i} style={{ margin: 0 }}>--</div>)}</>
                    }
                    { isSubmitting ? 
                        <div className="course-actions">
                            <div>Saving...</div>
                        </div>
                        :
                        <div className="course-actions">
                            <button type="submit" className="clear-button" data-tooltip-id="save" data-tooltip-content="Save">
                                <Tooltip id="save" className="tooltip-content" />
                                <FontAwesomeIcon icon={faCheck} className="fa-button" />
                            </button>
                            <button className="clear-button" onClick={props.onCancel} data-tooltip-id="cancel" data-tooltip-content="Cancel">
                                <Tooltip id="cancel" className="tooltip-content" />
                                <FontAwesomeIcon icon={faXmark} className="fa-button" />
                            </button>
                        </div>
                    }
                </div>
                {error ? <ErrorText>{error}</ErrorText> : null}
            </Form> )}
    </Formik>;
}



type TMSCourseProps = {
    tmsCourse: TMSCourseType,
    index: number,
    onSetIsCompleted: (isComplete: boolean) => void,
    onDelete: () => void,
    onUpdate: (tmsCourse: TMSCourseType) => void
    onEdit: () => void,
};

type TMSSessionAction = 
    { type: "set", sessions: TMSSession[] } |
    { type: "add", session: TMSSession } | 
    { type: "edit", session: TMSSession } |
    { type: "delete", sessionId: string };

function TMSSessionReducer(sessions: TMSSession[], action: TMSSessionAction) {
    switch (action.type) {
        case "set": {
            return action.sessions;
        }
        case "add": {
            return [action.session, ...sessions];
        }
        case "edit": {
            return sessions.map(c => c.id === action.session!.id ? action.session : c);
        }
        case "delete": {
            return sessions.filter(c => c.id !== action.sessionId);
        }
    }
}

export default function TMSCourse(props: TMSCourseProps) {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!;
    const [addingSession, setAddingSession] = useState<boolean>(false);
    const [deletingSession, setDeletingSession] = useState<TMSSession | null>(null);
    const [editingSession, setEditingSession] = useState<TMSSession | null>(null);
    const [prevSession, setPrevSession] = useState<TMSTreatmentSession | null>(null);
    const [moreActionsVisible, setMoreActionsVisible] = useState<boolean>(false);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [pageFromPagination, setPageFromPagination] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [tmsSessions, sessionsDispatch] = useReducer(TMSSessionReducer, []);

    const updatePaginatedSessions = (paginatedSessions: Paginated<TMSSession>) => {
        const sessions = paginatedSessions.results;
        sessionsDispatch({
            type: "set",
            sessions: sessions
        });
        if (sessions.length > 0) {
            // if the previous session was a mapping, set the carry-over values as a union of the previous mapping and the previous treatment / default treatment values
            if (sessions[0].type == "Mapping") {
                let prevTreatment = sessions.find(s => s.type == "Treatment") as TMSTreatmentSession;
                // if there are no prior treatments, use session defaults
                if (!prevTreatment) {
                    setPrevSession({
                        ...SESSION_DEFAULTS,
                        ...sessions[0],
                        type: "Treatment"
                    });
                }
                // set previous session as union of previous mapping and previous treatment
                else {
                    setPrevSession({
                        ...prevTreatment,
                        ...sessions[0],
                        type: "Treatment"
                    });
                }
            } else {
                setPrevSession(sessions[0]);
            }
        }
        setTotalPages(paginatedSessions.total_pages);
        setPage(paginatedSessions.current_page);
    };

    const getPaginatedSessions = async () => {
        setLoading(true);
        try {
            const response = await sherpahGet<Paginated<TMSSession>>(`/patients/${patient!.user_id}/tms-courses/${props.tmsCourse.id}/sessions?page=${pageFromPagination}`);
            updatePaginatedSessions(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPaginatedSessions();
        setPage(pageFromPagination);
    }, [pageFromPagination]);

    const showSessionActions = !isShared && !props.tmsCourse.is_completed
    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else if (tmsSessions.length == 0 && !addingSession) {
        content = <i className="empty-message">No TMS sessions recorded</i>;
    } else {
        content = <>
        <div className="tms-course-list-container">
            <div className="list-container" style={{ marginTop: 16, paddingBottom: 8 }}>
                <div className="list-columns tms-full-list-grid">
                    <div className="list-column">SESSION TYPE</div>
                    <div className="list-column">DATE</div>
                    <div className="list-column">SIDE OF HEAD</div>
                    <div className="list-column">TREATMENT SITE</div>
                    <div className="list-column">MOTOR THRESHOLD</div>
                    <div className="list-column">TREATMENT INTENSITY</div>
                    <div className="list-column">PULSES PER SECOND</div>
                    <div className="list-column">TRAIN PULSE FREQUENCY</div>
                    <div className="list-column">PULSES IN TRAIN</div>
                    <div className="list-column">NUMBER OF TRAINS</div>
                    <div className="list-column">INTERTRAIN INTERVAL</div>
                    <div className="list-column">SIDE EFFECTS</div>
                    <div className="list-column">COMMENT</div>
                    { showSessionActions ? <div className="list-column course-actions">ACTIONS</div> : null }
                </div>

                { addingSession ? 
                    <TMSSessionForm
                        onAdd={(addSessionResponse: AddTMSSessionResponse) => {
                            updatePaginatedSessions(addSessionResponse);
                            props.onUpdate(addSessionResponse.tms_course);
                            setAddingSession(false);
                        }}
                        onCancel={() => setAddingSession(false)}
                        tmsCourse={props.tmsCourse}
                        prevSession={prevSession} /> 
                    : null }

                { tmsSessions.map(session =>
                    session.id == editingSession?.id ?
                    <TMSSessionForm
                        onEdit={(editSessionResponse: EditTMSSessionResponse) => {
                            sessionsDispatch({type: "edit", session: editSessionResponse});
                            props.onUpdate(editSessionResponse.tms_course);
                            setEditingSession(null);
                        }}
                        onCancel={() => setEditingSession(null)}
                        tmsCourse={props.tmsCourse}
                        tmsSession={session}
                        key={session.id} />
                    :
                    <div className={`list-item tms-full-list-grid ${session.newly_updated ? "newly-updated" : ""}`} key={session.id}>
                        <div>
                            {session.type}
                            {session.type == "Mapping" ? " #" + session.mapping_number : " #" + session.treatment_number}
                        </div>
                        <div>{formatDate(session.start)}</div>
                        <div>{session.side_of_head}</div>
                        <div>{session.treatment_site}</div>
                        <div>{session.motor_threshold}%</div>
                        { session.type == "Treatment" ? <>
                                <div>{session.treatment_intensity}%</div>
                                <div>{session.pps}</div>
                                <div>{session.train_pulse_frequency}</div>
                                <div>{session.pulses_in_train}</div>
                                <div>{session.number_of_trains}</div>
                                <div>{session.intertrain_interval} seconds</div>
                                <div>{session.side_effects || <i className="empty-message">None</i>}</div>
                                <div>{session.comment || <i className="empty-message">None</i>}</div>
                            </>
                            : <>{Array(8).fill(undefined).map((_,i) => <div className="empty-message" key={i} style={{ margin: 0 }}>--</div>)}</>
                        }
                        { showSessionActions ? <div className="course-actions">
                            <div onClick={() => setEditingSession(session)} data-tooltip-id="edit" data-tooltip-content="Edit">
                                <Tooltip id="edit" className="tooltip-content" />
                                <FontAwesomeIcon icon={faEdit} className="fa-button" />
                            </div>
                            <div onClick={() => setDeletingSession(session)} data-tooltip-id="delete" data-tooltip-content="Delete">
                                <Tooltip id="delete" className="tooltip-content" />
                                <FontAwesomeIcon icon={faTrashAlt} className="fa-button" />
                            </div>
                        </div> : null }
                    </div>
                )}
            </div>
            { showSessionActions ? <div className="tms-actions-background"></div> : null }
        </div>
            <PaginationControls page={page} setPage={setPageFromPagination} totalPages={totalPages}/>
        </>;
    }

    const tmsStart = props.tmsCourse.start || props.tmsCourse.began;
    const tmsEnd = props.tmsCourse.end || props.tmsCourse.finished;

    let title;
    if (props.tmsCourse.is_completed) {
        title = <div>
                <h3 style={{ marginBottom: "8px" }}>
                    <FontAwesomeIcon icon={faCircleCheck} style={{ marginRight: "8px" }} className="success-text" />
                    Completed TMS Course #{props.index}
                </h3>
                <span className="empty-message">
                    {formatDate(tmsStart!)} - {formatDate(tmsEnd!)} | {props.tmsCourse.sessions_count} treatment sessions
                </span>
        </div>;
    } else {
        title = <div>
            <h3 style={{ marginBottom: "8px" }}>
                TMS Course #{props.index}
            </h3>
            <span className="empty-message">
                { tmsStart ? 
                    `${formatDate(tmsStart)} - Ongoing | ${props.tmsCourse.sessions_count} treatment sessions` 
                    : null
                }
            </span>
        </div>;
    }

    return <div className="flex-col-gap-8">
        <div className="heading-wrapper">
            {title}
            { !isShared ?
                <div className="heading-wrapper-actions">
                    {!props.tmsCourse.is_completed ? 
                        <button className="w-button fa-icon-button" onClick={() => setAddingSession(true)}>
                            <FontAwesomeIcon icon={faCirclePlus} />
                            Add TMS session
                        </button> : null }
                    <div className="dropdown" onClick={e => e.stopPropagation()}>
                        <button className="w-button secondary-button fa-icon-button" onClick={() => setMoreActionsVisible(val => !val)}>
                            <FontAwesomeIcon icon={faCaretDown} />
                            More
                        </button>
                        { moreActionsVisible ? 
                            <div className="dropdown-content">
                                <div className="dropdown-item fa-icon-button" onClick={() => props.onSetIsCompleted(!props.tmsCourse.is_completed)}>
                                    { props.tmsCourse.is_completed ? <>
                                        <FontAwesomeIcon icon={faAnglesRight} />
                                        Mark as ongoing
                                    </> : <>
                                        <FontAwesomeIcon icon={faCheck} />
                                        Mark as completed
                                    </> }
                                </div>
                                <div className="dropdown-item fa-icon-button" onClick={() => setShowReportModal(true)}>
                                    <FontAwesomeIcon icon={faFileLines} />
                                    Generate report
                                </div>
                                <div className="dropdown-item fa-icon-button" onClick={props.onEdit}>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                    Set course dates
                                </div>
                                <div className="dropdown-item fa-icon-button danger-button" onClick={props.onDelete}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                    Delete
                                </div>
                            </div> : null }
                    </div>
                </div> : null }
        </div>
        { content }
        <hr/>

        { deletingSession ? <ConfirmDeleteModal
            session={deletingSession}
            onClose={() => setDeletingSession(null)}
            onDelete={(deleteSessionResponse: TMSCourseType) => {
                sessionsDispatch({type: "delete", sessionId: deletingSession.id});
                props.onUpdate(deleteSessionResponse);
                setDeletingSession(null);
            }} /> : null }

        { showReportModal ? <ReportModal
            course={props.tmsCourse}
            onClose={() => setShowReportModal(false)} /> : null }
    </div>;
}


type ReportModalProps = {
    onClose: () => void,
    course: TMSCourseType
}

export function ReportModal(props: ReportModalProps) {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [loading, setLoading] = useState<boolean>(true);
    const [successful, setSuccessful] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getReport = async () => {
        try {
            const request = sherpahGet<string>(`/patients/${patient!.user_id}/tms-courses/${props.course.id}/report`);
            await downloadRequest(request, `tms-report.pdf`, "application/pdf");
            setSuccessful(true);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getReport();
    });

    let content;
    if (loading) {
        content = <Loading text="Generating report..." />;
    } else if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (successful) {
        content = <div>Report has been downloaded.</div>;
    } else {
        content = <ErrorText>Error generating report.</ErrorText>;
    }

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">TMS report</div>
            { content }
            <div className="modal-actions">
                <button type="button" className="secondary-button w-button" onClick={props.onClose}>Close</button>
            </div>
        </div>
    </div>;
}


type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: (deleteSessionResponse: TMSCourseType) => void,
    session: TMSSession
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        try {
            const resource = props.session.type == "Treatment" ? "treatments" : "mappings";
            const response = await sherpahDelete<TMSCourseType>(`/patients/${patient!.user_id}/tms-courses/${props.session.tms_course_id}/${resource}/${props.session.id}`);
            props.onDelete(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this TMS {props.session.type} for <b>{ formatDate(props.session.start) }</b>?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                <div className="modal-actions">
                    <button type="button" className="w-button" disabled>Deleting...</button>
                </div>
                :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}