import { PropsWithChildren, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';
import { Trends, Report } from '../patients/report/reportTypes';
import ReportSection from '../patients/report/ReportSection';
import Loading from '../../components/Loading';
import { ErrorBox, ErrorText } from '../../components/Errors';



const SAMPLES = [
    [102, '2023-12-04'],
    [58, '2022-10-27'],
    [117, '2022-11-10'],
    [65, '2022-12-29'],
    [211, '2023-11-20'],
    [55, '2022-08-27'],
    [134, '2023-01-21'],
    [145, '2023-03-17'],
    [115, '2023-04-03'],
    [109, '2023-01-15'],
    [151, '2024-02-05'],
    [102, '2023-05-12'],
    [44, '2022-12-13'],
    [39, '2023-12-18'],
    [144, '2023-08-01'],
    [259, '2023-12-22'],
    [102, '2023-10-21'],
    [122, '2023-01-02'],
    [65, '2022-11-13'],
    [127, '2022-12-06'],
    [103, '2022-11-06'],
    [38, '2023-07-11'],
    [44, '2023-05-09'],
    [187, '2023-10-01'],
    [249, '2024-01-14'],
    [55, '2022-12-21'],
    [38, '2023-09-21'],
    [179, '2023-08-22'],
    [93, '2022-07-19'],
    [187, '2023-08-08'],
    [178, '2024-01-28'],
    [151, '2023-05-05'],
    [56, '2023-03-17'],
    [126, '2023-02-13'],
    [220, '2024-01-31'],
    [144, '2023-02-17'],
    [178, '2024-01-06'],
    [44, '2023-10-24'],
    [144, '2023-04-20'],
    [67, '2023-05-19'],
    [44, '2022-11-22'],
    [122, '2022-11-15'],
    [159, '2023-07-20'],
    [126, '2023-08-11'],
    [115, '2023-03-07'],
    [117, '2023-06-20'],
    [249, '2024-01-25'],
    [39, '2023-02-27'],
    [65, '2022-12-19'],
    [102, '2023-07-21'],
    [126, '2023-06-09'],
    [149, '2023-05-13'],
    [151, '2023-12-16'],
    [213, '2023-09-02'],
    [38, '2022-09-11'],
    [111, '2022-11-16'],
    [54, '2022-08-17'],
    [221, '2023-09-28'],
    [38, '2023-09-08'],
    [106, '2022-08-27'],
    [67, '2023-06-07'],
    [151, '2023-04-23'],
    [102, '2023-06-01'],
    [44, '2023-06-10'],
    [220, '2023-12-24'],
    [54, '2023-07-14'],
    [131, '2023-02-16'],
    [178, '2023-08-08'],
    [134, '2023-05-13'],
    [115, '2023-05-16'],
    [148, '2023-07-29'],
    [115, '2023-03-06'],
    [149, '2023-08-17'],
    [220, '2023-12-26'],
    [124, '2022-12-20'],
    [44, '2022-08-26'],
    [151, '2024-02-01'],
    [220, '2023-12-22'],
    [55, '2023-05-28'],
    [149, '2023-09-28'],
    [144, '2023-05-29'],
    [148, '2023-06-11'],
    [58, '2022-10-03'],
    [134, '2023-02-23'],
    [187, '2023-12-10'],
    [126, '2023-01-01'],
    [178, '2023-09-28'],
    [102, '2023-05-04'],
    [186, '2023-09-21'],
    [44, '2023-12-07'],
    [93, '2022-07-18'],
    [38, '2022-11-18'],
    [54, '2023-03-29'],
    [56, '2022-08-17'],
    [149, '2024-01-14'],
    [91, '2022-11-03'],
    [117, '2022-12-18'],
    [111, '2024-01-29'],
    [111, '2024-01-30'],
    [111, '2024-02-01']
];


// dev
// const SAMPLES = [[58, '2022-06-23'], [44, '2023-07-11'], [58, '2022-06-23'], [44, '2023-07-24'], [92, '2022-07-01'], [92, '2022-07-13'], [65, '2023-12-05'], [92, '2022-07-09'], [98, '2022-07-08'], [81, '2022-06-30'], [92, '2022-06-23'], [91, '2022-08-11'], [91, '2023-02-13'], [98, '2022-06-20'], [98, '2022-07-07'], [98, '2022-07-06'], [98, '2022-06-22'], [56, '2022-07-02'], [98, '2022-07-01'], [39, '2022-07-08'], [92, '2022-07-03'], [91, '2022-07-22'], [38, '2022-07-03'], [98, '2022-07-11'], [58, '2022-06-30'], [39, '2022-06-29'], [65, '2023-11-28'], [56, '2022-06-30'], [91, '2022-08-15']];


export default function NewMetrics() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const [sampleNo, setSampleNo] = useState<number>(0);
    const [report, setReport] = useState<Report | null>(null);

    const getReport = async () => {
        setReport(null);
        setError(null);
        const sample = SAMPLES[sampleNo];
        try {
            const response = await sherpahGet<Report>(`patients/${sample[0]}/metrics?date=${sample[1]}`);
            setReport(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    useEffect(() => {
        getReport();
    }, [sampleNo])

    return <main>
        <div className="heading-wrapper">
            <h1>New metrics assessment page</h1>
        </div>
        <section className="rounded-container">
            <p>
                This page is for assessing and validating the new Sherpah sleep metrics.
            </p>
            <p>
                <b>There are 100 random samples with their respective sleep metric analysis.</b>
            </p>
            <span style={{marginRight: 8}}>Sample number:</span>
            <button onClick={() => setSampleNo(s => Math.max(s-1, 0))}>Prev</button>
            <select style={{width: 100, margin: "0 16px"}} value={sampleNo} onChange={e => setSampleNo(parseInt(e.target.value))}>
                {Array(100).fill(undefined).map((v, i) => <option key={i} value={i}>{i+1}</option>)}
            </select>
            <button onClick={() => setSampleNo(s => Math.min(s+1, 99))}>Next</button>
        </section>
        { error ? <ErrorBox>{error}</ErrorBox> : null }
        { report ? <ReportSection report={report} testingNewScores={true} /> : <Loading /> }
    </main>

}