import { MedicalReview, Comment } from '../../../types';
import "./comments.scss";
import { formatDate, formatDatetime } from '../../../utils';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { ErrorText } from '../../../components/Errors';
import Loading from '../../../components/Loading';
import { AuthContext } from '../../../contexts/AuthContext';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTrashAlt, faXmark } from '@fortawesome/free-solid-svg-icons';


type CommentProps = {
    comment: Comment,
    onEdit: (newComment: Comment) => void,
    onDelete: () => void
};

function CommentComponent(props: CommentProps) {
    const { comment } = props;
    const [editing, setEditing] = useState<boolean>(false);
    const [editedText, setEditedText] = useState<string>(comment.text);
    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const user = useContext(AuthContext)!.authState.user!;

    const updateComment = async () => {
        setSaving(true);
        setError(null)
        try {
            const response = await sherpahPut<Comment>(`/patients/${patient!.user_id}/comments/${comment.id}`, {text: editedText});
            setEditing(false);
            props.onEdit(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setSaving(false);
        }
    }

    let actions = null;
    if (comment.sender.id == user.id) {
        if (saving) {
            actions = <span className="comment-actions">Saving...</span>
        } else if (editing) {
            actions = <span className="comment-actions">
                <span onClick={updateComment} data-tooltip-id="save" data-tooltip-content="Save">
                    <Tooltip id="save" className="tooltip-content" />
                    <FontAwesomeIcon icon={faCheck} className="fa-button" />
                </span>
                <span onClick={() => setEditing(false)} data-tooltip-id="cancel" data-tooltip-content="Cancel">
                    <Tooltip id="cancel" className="tooltip-content" />
                    <FontAwesomeIcon icon={faXmark} className="fa-button" />
                </span>
            </span>;
        } else {
            actions = <span className="comment-actions hidden">
                <span onClick={() => setEditing(true)} data-tooltip-id="edit" data-tooltip-content="Edit">
                    <Tooltip id="edit" className="tooltip-content" />
                    <FontAwesomeIcon icon={faEdit} className="fa-button" />
                </span>
                <span onClick={props.onDelete} data-tooltip-id="delete" data-tooltip-content="Delete">
                    <Tooltip id="delete" className="tooltip-content" />
                    <FontAwesomeIcon icon={faTrashAlt} className="fa-button" />
                </span>
            </span>;
        }
    }

    return <div
            className={`comment ${comment.sender.id == user.id ? "own-comment" : ""}`}
            style={{ width: editing ? "100%" : "initial" }}
            key={comment.id}>
        { comment.deleted_at !== null ?
            <>
                <div className="comment-heading">
                    <span className="comment-sender">{comment.sender.full_name}</span>
                </div>
                <i className="empty-message" style={{ margin: 0 }}>Comment deleted {formatDatetime(comment.deleted_at)}.</i>
            </> :
            <>
                <div className="comment-heading">
                    <span className="comment-sender">{comment.sender.full_name}</span>
                    <span className="comment-timestamp">
                        { formatDatetime(comment.timestamp) }
                        { comment.edited_at ? ` (Edited ${formatDatetime(comment.edited_at)})` : null}
                    </span>
                    { !comment.is_acknowledged ? <span className="danger-text"> • Unacknowledged</span> : null }
                    { error ? <ErrorText>{error}</ErrorText> : null }
                    { actions }
                </div>
                { editing ? 
                    <textarea onChange={e => setEditedText(e.target.value)} defaultValue={editedText}></textarea> :
                    <div>{comment.text}</div>
                }
            </>
        }
    </div>;
}


type PaginatedComments = {
    last_id: number | null,
    more: boolean,
    comments: Comment[]
};

type CommentAction = 
    { type: "set", comments: Comment[] } |
    { type: "prepend", comments: Comment[] } |
    { type: "add", comment: Comment } | 
    { type: "edit", comment: Comment } |
    { type: "delete", commentId: string } |
    { type: "acknowledgeAll" };

function CommentsReducer(comments: Comment[], action: CommentAction) {
    switch (action.type) {
        case "set": {
            return action.comments;
        }
        case "prepend": {
            return [...action.comments, ...comments];
        }
        case "add": {
            return [...comments, action.comment];
        }
        case "edit": {
            return comments.map(c => c.id === action.comment!.id ? action.comment : c);
        }
        case "delete": {
            return comments.filter(c => c.id !== action.commentId);
        }
        case "acknowledgeAll": {
            return comments.map(c => ({...c, is_acknowledged: true}));
        }
    }
}


export default function CommentsTab(props: {onAcknowledge: () => void}) {
    const { sherpahGet, sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [lastId, setLastId] = useState<number | null>(null);
    const [text, setText] = useState<string>("");
    const [sending, setSending] = useState<boolean>(false);
    const [acknowledging, setAcknowledging] = useState<boolean>(false);
    const [deletingComment, setDeletingComment] = useState<Comment | null>(null);
    const [commentsInitialised, setCommentsInitialised] = useState<boolean>(false);
    const user = useContext(AuthContext)!.authState.user!;

    const commentsRef = useRef<HTMLDivElement | null>(null);

    const [comments, commentsDispatch] = useReducer(
        CommentsReducer,
        []
    );

    const getComments = async () => {
        setLoading(true);
        try {
            const lastIdQuery = lastId ? `?lastId=${lastId}` : "";
            const response = await sherpahGet<PaginatedComments>(`/patients/${patient!.user_id}/comments${lastIdQuery}`);
            commentsDispatch({
				type: "prepend",
				comments: response.data.comments
			});
            setLastId(response.data.last_id);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    const sendMessage = async () => {
        setSending(true);
        try {
            const response = await sherpahPost<Comment>(`/patients/${patient!.user_id}/comments`, {text});
            commentsDispatch({type: "add", comment: response.data});
            // if main doctor, acknowledge all messages on comment
            if (user.is_doctor && !isShared) {
                commentsDispatch({type: "acknowledgeAll"});
                props.onAcknowledge();
            }
            setText("");
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setSending(false);
        }
    };

    const acknowledgeMessages = async () => {
        setAcknowledging(true);
        try {
            const response = await sherpahPost(`/patients/${patient!.user_id}/comments/acknowledge-all`, {});
            commentsDispatch({type: "acknowledgeAll"});
            props.onAcknowledge();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setAcknowledging(false);
        }
    }

    // scroll to bottom when comments are initialised
    useEffect(() => {
        if (commentsRef.current && !commentsInitialised) commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
        if (comments.length > 0) setCommentsInitialised(true);
    }, [comments])

    useEffect(() => {
        commentsDispatch({
            type: "set",
            comments: []
        });
        getComments();
        setLoading(false);
    }, []);

    let content;
    if (loading) {
        content = <Loading />;
    } else {
        content = <>
            <div className="flex-col-gap-16" style={{ overflow: "scroll", maxHeight: 500 }} ref={commentsRef}>
                { comments.length > 0 ? <>
                    { lastId !== null ? 
                        <button className="secondary-button" onClick={getComments}>Load more</button>
                        : null }
                    { comments.map(comment => 
                        <CommentComponent
                            key={comment.id}
                            comment={comment}
                            onEdit={(newComment: Comment) => commentsDispatch({type: "edit", comment: newComment})}
                            onDelete={() => setDeletingComment(comment)} />
                    ) }
                    </> :
                    <i className="empty-message">No comments yet.</i>
                }
                { user.is_doctor && !isShared && comments.some(c => !c.is_acknowledged) && !acknowledging ? 
                    <button style={{ alignSelf: "flex-start" }} onClick={acknowledgeMessages}>Acknowledge all messages</button>
                    : null }
                { acknowledging ?
                    <button style={{ alignSelf: "flex-start" }} disabled>Acknowledging...</button>
                    : null }
            </div>
            <hr/>
            { error ? <ErrorText>{error}</ErrorText> : null }
            <div className="flex-row-gap-8">
                <input type="text" onChange={e => setText(e.target.value)} value={text} placeholder="Type your message here..." />
                { sending ? 
                    <button type="button" style={{ padding: "9px 32px" }} disabled>Sending...</button> :
                    <button type="button" style={{ padding: "9px 32px" }} onClick={sendMessage}>Send</button>
                }
            </div>

            { deletingComment ? <ConfirmDeleteModal
                comment={deletingComment}
                onClose={() => setDeletingComment(null)}
                onDelete={(deletedComment: Comment) => {
                    commentsDispatch({type: "edit", comment: deletedComment});
                    setDeletingComment(null);
                }} /> : null }
        </>;
    }
    
    return <div className="flex-col-gap-8">
		{content}
	</div>;
}



type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: (deleteComment: Comment) => void,
    comment: Comment
};

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        try {
            const response = await sherpahDelete<Comment>(`/patients/${patient!.user_id}/comments/${props.comment.id}`);
            props.onDelete(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this comment?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                "Deleting..."
            :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}
