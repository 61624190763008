import { CSSProperties } from "react";
import "./Box.scss";

const Spacing = {
    "0": "0rem",
    "1u": "1rem",
    "2u": "2rem",
    "4u": "4rem"
}

export type BoxProps = {
    flow?: CSSProperties['flexFlow']
    spacing?: "1u" | "2u" | "4u";
} & React.PropsWithChildren

export function Box(props: BoxProps) {

    const style: CSSProperties = {
        flexFlow: props.flow,
        gap: Spacing[props.spacing ?? "1u"]
    }

    return <div style={style} className="box">
        {props.children}
    </div>
}
