

export default function DeletingData() {
    return (
        <main style={{ maxWidth: 800, margin: "0 auto" }}>
            <img src="/logo-rounded.png" width="60" />
            <h3>Sherpah</h3>
            <h1>Deleting your data</h1>
            <p>This short guide shows you how to delete your account and all associated data (heart rate metrics, check-in results, personal info etc.) on any Sherpah-affiliated app.</p>

            <h2>If you are logged into the app:</h2>
            <div>
                <b>Step 1.</b> From the home page, press the settings button on the top right.
            </div>
            <img src="/deleting-data/Home.png" style={{ height: "500px", alignSelf: "flex-start", border: "1px solid black" }} />

            <div>
                <b>Step 2.</b> In the settings page, press <b>Account</b>.
            </div>
            <img src="/deleting-data/Settings.png" style={{ height: "500px", alignSelf: "flex-start", border: "1px solid black" }} />

            <div>
                <b>Step 3.</b> Next, press <b>Delete account</b>.
            </div>
            <img src="/deleting-data/Account.png" style={{ height: "500px", alignSelf: "flex-start", border: "1px solid black" }} />

            <div>
                <b>Step 4.</b> Be aware that deleting your account and associated data is permanent and cannot be undone. If you're happy to proceed, press <b>Yes, delete everything.</b>
            </div>
            <img src="/deleting-data/Delete account.png" style={{ height: "500px", alignSelf: "flex-start", border: "1px solid black" }} />

            <h2>If you can't login to the app:</h2>
            <div>Send an email to <a href="mailto:support@sherpah.com.au">support@sherpah.com.au</a> with your account details and we will assist you in deleting your Sherpah data.</div>
        </main>
    );
}