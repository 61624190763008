import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';
import { DEFAULT_TOAST_SETTINGS, downloadRequest } from '../../utils';
import { ErrorText } from '../../components/Errors';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Doctor, Patient } from '../../types';


type AnnotatedReport = {
    patient: Patient,
    date: string,
    annotated_by: Doctor,
    created_at: string,
    sleep_onset: string,
    sleep_finish: string,
    depression: number | null,
    anxiety: number | null,
    confidence: number | null,
    comment: string,
};

type AnnotatedReportsResponse = {
    count: number,
    // annotated_reports: AnnotatedReport[]
}

export default function Algorithm() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    // const [annotatedReports, setAnnotatedReports] = useState<AnnotatedReport[]>([]);
    const [annotatedReportCount, setAnnotatedReportCount] = useState<number | null>(null);

    const getAnnotatedReports = async () => {
        setError(null);
        try{
            const response = await sherpahGet<AnnotatedReportsResponse>("/patients/annotated-reports");
            // setAnnotatedReports(response.data.annotated_reports);
            setAnnotatedReportCount(response.data.count);
        } catch (e: any) {
            catchAxios(e, setError);
        }
    }

    const getCsv = async () => {
        setError(null);
        try{
            const request = sherpahGet<string>("/patients/annotated-reports/csv");
            await downloadRequest(request, `annotated-reports.csv`, "text/csv");
        } catch (e: any) {
            catchAxios(e, setError);
        }
    }

    useEffect(() => {
        getAnnotatedReports();
    }, []);

    return <main>
        <div className="heading-wrapper">
            <h1>Algorithm</h1>
			<div className="heading-wrapper-actions">
                <button type="submit" className="w-button fa-icon-button" onClick={getCsv}>
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faDownload} /></span>
                    Download annotated reports
                </button>
            </div>
        </div>
        <div className="rounded-container">
            {/* <p>Input patient's email address to allow them free access.</p> */}

            { error ? <ErrorText>{error}</ErrorText> : null }

            <b>Number of annotated reports:</b> { annotatedReportCount || "Loading..." }

            {/* <div>
            { (annotatedReports && annotatedReports.length > 0) ? 
                annotatedReports.map(r => <div>{r.patient.user.id} - ({r.date})</div>)
                : <i className="empty-message">No annotated reports.</i>
            }
            </div> */}


        </div>
    </main>

}