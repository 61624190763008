import { Link } from 'react-router-dom';
import { MedicalReview } from '../../../types';
import "./activity.scss";
import { formatDate, formatDatetime } from '../../../utils';
import { useContext, useEffect, useReducer, useState } from 'react';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { ErrorText } from '../../../components/Errors';
import Loading from '../../../components/Loading';
import { Paginated, PaginationControls } from '../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faFileCirclePlus, faTrashAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { DatePickerField } from '../../../components/DatePickerField';
import { Tooltip } from 'react-tooltip';
import { SharedContext } from '../../../contexts/SharedContext';


type ReviewAction = 
    { type: "set", reviews: MedicalReview[] } |
    { type: "add", review: MedicalReview } | 
    { type: "edit", review: MedicalReview } |
    { type: "delete", reviewId: string };

function MedicalReviewsReducer(reviews: MedicalReview[], action: ReviewAction) {
    switch (action.type) {
        case "set": {
            return action.reviews;
        }
        case "add": {
            return [action.review, ...reviews];
        }
        case "edit": {
            return reviews.map(c => c.id === action.review!.id ? action.review : c);
        }
        case "delete": {
            return reviews.filter(c => c.id !== action.reviewId);
        }
    }
}


export default function ReviewsTab() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
    const [editingReview, setEditingReview] = useState<MedicalReview | null>(null);
    const [deletingReview, setDeletingReview] = useState<MedicalReview | null>(null);

    const [reviews, reviewsDispatch] = useReducer(
        MedicalReviewsReducer,
        []
    );

    const getReviews = async () => {
        setLoading(true);
        try {
            const response = await sherpahGet<Paginated<MedicalReview>>(`/patients/${patient!.user_id}/medical-reviews?page=${page}`);
            reviewsDispatch({
				type: "set",
				reviews: response.data.results
			});
            setTotalPages(response.data.total_pages);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getReviews();
    }, [page]);

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <>
            { reviews.length > 0 ? <>
                <div className="list-container">
                    <div className="list-columns reviews-list-grid">
                        <div className="list-column">DATE</div>
                        <div className="list-column">MEDICARE ITEM</div>
                        <div className="list-column">DESCRIPTION</div>
                        { !isShared ? <div className="list-column">ACTIONS</div> : null }
                    </div>
                    { reviews.map(r => 
                        <div className="list-item reviews-list-grid" key={r.id}>
                            <div>{ formatDate(r.date) }</div>
                            <div>{ r.medicare_item.code } - { r.medicare_item.description }</div>
                            <div>{ r.description }</div>
                            { !isShared ?
                                <div className="course-actions">
                                    <div onClick={() => setEditingReview(r)} data-tooltip-id="edit" data-tooltip-content="Edit">
                                        <Tooltip id="edit" className="tooltip-content" />
                                        <FontAwesomeIcon icon={faEdit} className="fa-button" />
                                    </div>
                                    <div onClick={() => setDeletingReview(r)} data-tooltip-id="delete" data-tooltip-content="Delete">
                                        <Tooltip id="delete" className="tooltip-content" />
                                        <FontAwesomeIcon icon={faTrashAlt} className="fa-button" />
                                    </div>
                                </div> : null }
                        </div>
                    )}
                </div>
                <PaginationControls page={page} setPage={setPage} totalPages={totalPages}/>
            </> : <i className="empty-message">None</i> }

            { !isShared ?
                <div>
                    <button className="w-button secondary-button fa-icon-button" onClick={() => setShowReviewModal(true)}>
                        <span className="fa-wrapper"><FontAwesomeIcon icon={faFileCirclePlus} /></span>
                        Add medical review
                    </button>
                </div> : null }

            { (showReviewModal || editingReview) ? <MedicalReviewModal
                medicalReview={editingReview}
                onClose={() => {
                    setShowReviewModal(false);
                    setEditingReview(null);
                }}
                onAdd={(review: MedicalReview) => reviewsDispatch({type: "add", review})}
                onEdit={(review: MedicalReview) => reviewsDispatch({type: "edit", review})} />
            : null }

            { deletingReview ? <ConfirmDeleteModal
                review={deletingReview}
                onClose={() => setDeletingReview(null)}
                onDelete={() => {
                    reviewsDispatch({type: "delete", reviewId: deletingReview.id});
                    setDeletingReview(null);
                }} /> : null }

        </>;
    }
    
    return <div className="flex-col-gap-8">
		{content}
	</div>;
}



type MedicalReviewFormValues = {
    date: string,
    medicare_item_id: string,
    description: string
};

type MedicalReviewModalProps = {
    onAdd?: (medicalReview: MedicalReview) => void,
    onEdit?: (medicalReview: MedicalReview) => void,
    onClose: () => void,
    medicalReview: MedicalReview | null,
};

function MedicalReviewModal(props: MedicalReviewModalProps) {
    const { sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const { medicareItems } = useContext(SharedContext)!;
    const [error, setError] = useState<string | null>(null);

    const onSubmit = async (values: MedicalReviewFormValues, { setErrors }: FormikHelpers<MedicalReviewFormValues>) => {
        try {
            if (props.medicalReview) {
                // editing tms course
                const response = await sherpahPut<MedicalReview>(`/patients/${patient!.user_id}/medical-reviews/${props.medicalReview.id}`, values);
                props.onEdit!(response.data);
            } else {
                // posting new tms course
                const response = await sherpahPost<MedicalReview>(`/patients/${patient!.user_id}/medical-reviews`, values);
                props.onAdd!(response.data);
            }
            props.onClose();
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    };

    const initialValues: MedicalReviewFormValues = {
        date: props.medicalReview?.date || DateTime.now().toFormat("yyyy-MM-dd"),
        medicare_item_id:props.medicalReview?.medicare_item.code || medicareItems[0].code,
        description: props.medicalReview?.description || "",
    };


    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Add medical review</div>
            <Formik 
                initialValues={initialValues}
                validationSchema={Yup.object<MedicalReviewFormValues>({
                    date: Yup.date().label("Date").required(),
                    description: Yup.string().label("Description").required()
                })}
                onSubmit={onSubmit}>
                    {({isSubmitting}) => (
                    <Form>
                        <div className="form-fields">
                            <div>
                                <label htmlFor="date">Date</label>
                                <DatePickerField name="date" />
                                <ErrorMessage component={ErrorText} name="date" />
                            </div>
                            <div>
                                <label htmlFor="medicare_item_id">Medicare item</label>
                                <Field as="select" name="medicare_item_id">
                                    { medicareItems.map(item => 
                                        <option value={item.code} key={item.code}>{item.code} - {item.description}</option>
                                    )}
                                </Field>
                            </div>
                            <div>
                                <label htmlFor="description">Description</label>
                                <Field as="textarea" name="description" />
                                <ErrorMessage component={ErrorText} name="description" />
                            </div>
                        </div>
                        
                        {error ? <ErrorText>{error}</ErrorText> : null}
                        
                        {isSubmitting ? 
                            <div className="modal-actions">
                                <button type="button" className="w-button" disabled>Saving...</button>
                            </div>
                            :
                            <div className="modal-actions">
                                <button type="submit" className="w-button">Save</button>
                                <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                            </div>
                        }
                    </Form> )}
            </Formik>
        </div>
    </div>;
}


type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: () => void,
    review: MedicalReview
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        try {
            await sherpahDelete(`/patients/${patient!.user_id}/medical-reviews/${props.review.id}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this medical review?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                "Deleting..."
            :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}
