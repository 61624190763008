import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';


export default function FitbitRedirect() {
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");
    const clinic = searchParams.get("clinic");
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/redirect?mobile=sync-fitbit?authCode=${code}&clinic=${clinic}`);
    }, []);

    return <main>
        Redirecting...
    </main>;
}