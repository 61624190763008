import { Link } from 'react-router-dom';
import { PatientOverview, Playback, QuestionnaireSummary } from '../../../types';
import "./activity.scss";
import { formatDate, formatDatetime } from '../../../utils';
import { useContext, useEffect, useState } from 'react';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { ErrorText } from '../../../components/Errors';
import Loading from '../../../components/Loading';
import { Paginated, PaginationControls } from '../../../components/Pagination';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


function QuestionnaireList() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [questionnaires, setQuestionnaires] = useState<QuestionnaireSummary[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [deletingQuestionnaire, setDeletingQuestionnaire] = useState<QuestionnaireSummary | null>(null);

    // const sortedQuestionnaires = questionnaires.sort((a, b) => {
    //     const aValue = DateTime.fromISO(a.completed_at).valueOf();
    //     const bValue = DateTime.fromISO(b.completed_at).valueOf();
    //     return bValue - aValue;
    // });

    const getQuestionnaires = async () => {
        setLoading(true);
        try {
            const response = await sherpahGet<Paginated<QuestionnaireSummary>>(`/patients/${patient!.user_id}/questionnaires?page=${page}`);
            setQuestionnaires(response.data.results);
            setTotalPages(response.data.total_pages);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getQuestionnaires();
    }, [page]);

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else if (questionnaires.length == 0) {
        content = <i className="empty-message">None</i>;
    } else {
        content = <>
            <div className="list-container">
                <div className="list-columns questionnaire-list-grid">
                    <div className="list-column">COMPLETED AT</div>
                    <div className="list-column">TYPE</div>
                    <div className="list-column">SCORES</div>
                    <div className="list-column">ACTIONS</div>
                </div>
                { questionnaires.map(q => 
                    <div className="list-item questionnaire-list-grid" key={`${q.type}${q.id}`}>
                        <Link to={`questionnaires/${q.type.toLowerCase()}/${q.id}`}>
                            { q.backdated_to ? 
                                formatDate(q.backdated_to) + " (backdated)" :
                                formatDatetime(q.completed_at)
                            }
                        </Link>
                        <Link to={`questionnaires/${q.type.toLowerCase()}/${q.id}`}>{ q.type }</Link>
                        <Link to={`questionnaires/${q.type.toLowerCase()}/${q.id}`}>{ q.scores }</Link>
                        <div className="list-actions">
                            <div onClick={() => setDeletingQuestionnaire(q)} className="list-action" data-tooltip-id="delete" data-tooltip-content="Delete">
                                <Tooltip id="delete" className="tooltip-content" />
                                <FontAwesomeIcon icon={faTrashAlt} className="fa-button" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <PaginationControls page={page} setPage={setPage} totalPages={totalPages}/>
        </>;
    }

    return <div className="flex-col-gap-16">
        <h3>Questionnaires</h3>
        { content }
        { deletingQuestionnaire ? <ConfirmDeleteModal
            questionnaire={deletingQuestionnaire}
            onClose={() => setDeletingQuestionnaire(null)}
            onDelete={() => {
                setQuestionnaires(qs => qs.filter(q => q.id != deletingQuestionnaire.id));
                setDeletingQuestionnaire(null);
            }} /> : null }
    </div>;
}

function PlaybackList() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [playbacks, setPlaybacks] = useState<Playback[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    // const sortedPlaybacks = playbacks.sort((a, b) => {
    //     const aValue = DateTime.fromISO(a.started_at).valueOf();
    //     const bValue = DateTime.fromISO(b.started_at).valueOf();
    //     return bValue - aValue;
    // });

    const getPlaybacks = async () => {
        setLoading(true);
        try {
            const response = await sherpahGet<Paginated<Playback>>(`/patients/${patient!.user_id}/playbacks?page=${page}`);
            setPlaybacks(response.data.results);
            setTotalPages(response.data.total_pages);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPlaybacks();
    }, [page]);

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else if (playbacks.length == 0) {
        content = <i className="empty-message">None</i>;
    } else {
        content = <>
            <div className="list-container">
                <div className="list-columns content-list-grid">
                    <div className="list-column">STARTED AT</div>
                    <div className="list-column">CONTENT NAME</div>
                </div>
                { playbacks.map(p => 
                    <div className="list-item content-list-grid" key={p.id}>
                        <div>{ formatDatetime(p.started_at) }</div>
                        <div>{ p.content.name }</div>
                    </div>  
                )}
            </div>
            <PaginationControls page={page} setPage={setPage} totalPages={totalPages}/>
        </>;
    }

    return <div className="flex-col-gap-16">
        <h3>Content</h3>
        { content }
    </div>;
}


export default function ActivityTab(props: {overview: PatientOverview}) {
    return <div className="flex-col-gap-32">
        <QuestionnaireList />
        <PlaybackList />
	</div>;
}



type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: () => void,
    questionnaire: QuestionnaireSummary
}

function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        const courseResource = `${props.questionnaire.type.toLowerCase().replace("-","")}/${props.questionnaire.id}`;
        try {
            await sherpahDelete(`/patients/${patient!.user_id}/${courseResource}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this {props.questionnaire.type}?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                "Deleting..."
            :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}
