import Lottie from "lottie-react";
import loadingAnimation from "./loading.json";

type LoadingProps = {
	text?: string | false,
	height?: number
};

export default function Loading(props: LoadingProps) {
	return <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
        <Lottie
            animationData={loadingAnimation}
            style={{ height: (props.height || 60) + "px" }}
            loop autoplay />
        { props.text === false ? 
            null : 
            <div style={{ marginTop: 8 }}>{ props.text || "Loading..." }</div> }
    </div>;
}
