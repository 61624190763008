import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import Gauge from '../../../components/Gauge';
import { Report } from "./reportTypes";
import { DateTime, Duration } from 'luxon';
import { useState } from 'react';
import { formatDatetime, scoreDesc } from '../../../utils';

export default function ReportStats(props: {report: Report}) {
    const [showMore, setShowMore] = useState<boolean>(false);

    const assessment = props.report.report_assessment!;
    const stats = props.report.stats!;

    return <div style={{ display: "flex", gap: "48px", flexDirection: "column" }}>

        { assessment ? 
            <section>
                <h2>Patient assessment
                    <span
                        data-tooltip-id="patient-assessment-tooltip"
                        data-tooltip-content={`This is the patient's own assessment of this 24-hour reporting period.`}>
                            <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                            <Tooltip id="patient-assessment-tooltip" className="tooltip-content" />
                    </span>
                </h2>
                <div className="stats-grid overview-grid">
                    <div>
                        <b>Sleep Onset: </b>
                        <span>{DateTime.fromISO(props.report!.date).plus({minutes: 720 + assessment.sleep_onset}).toFormat("HH:mm")}</span>
                    </div>
                    <div>
                        <b>Sleep Finish: </b>
                        <span>{DateTime.fromISO(props.report!.date).plus({minutes: 720 + assessment.sleep_finish}).toFormat("HH:mm")}</span>
                    </div>
                    {/* TODO:
                     * differentiate between null and false
                     * ensure exercise and interruptions are filled out or supply empty message */}
                    <div className="stats-grid-span-2">
                        <b>Representative day: </b>
                        <span>{assessment.is_representative === null ?
                            <i className="empty-message">N/A</i> :
                            (assessment.is_representative === true ? "Yes" : "No")}
                        </span>
                    </div>
                    <div className="stats-grid-span-2">
                        <b>Exercise: </b>
                        <span>
                            {assessment.exercise?.slice(1,-1).split('","').join(", ") || 
                            <i className="empty-message">N/A</i> }
                        </span>
                    </div>
                    <div className="stats-grid-span-2">
                        <b>Sleep interruptions: </b>
                        <span>
                            {assessment.sleep_interruptions?.slice(1,-1).split('","').join(", ") ||
                            <i className="empty-message">N/A</i>}
                        </span>
                    </div>
                </div>
            </section> : null }

		<section>
			<div className="patient-stats-table">
				<h2>
					Overview
                    <span
                        data-tooltip-id="overview-tooltip"
                        data-tooltip-content={`Please remember that these scores are not diagnostic, and they serve as a general
                            indicator only. If you are concerned about your mental health, please see your GP or
                            other mental health professional for an official referral for psychiatric assessment,
                            diagnosis, and treatment plan. These scores can be affected when the system cannot
                            accurately identify your sleep period due to artefact in the data recordings, an abnormal
                            period in your life (i.e not sleeping to a normal schedule due to night shift), drugs or
                            alcohol, certain medications, pregnancy and many more.`}>
                            <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                            <Tooltip id="overview-tooltip" className="tooltip-content" />
                    </span>
				</h2>

				<div className="stats-grid overview-grid">
					<div>
						<b>Sleep Onset: </b>
						<span>{DateTime.fromISO(stats.sleep_onset).toFormat("HH:mm")}</span>
                        <span
                            data-tooltip-id="sleep-onset-tooltip"
                            data-tooltip-content={`This score identifies physiological indicators correlated with the onset of sleep, changes in heart rate
                                and activity signify to our system that you have entered sleep. This score is not always 100%
                                accurate and can be disrupted if you spend long periods of inactivity before going to sleep i.e. watching TV, meditating before bed. 
                                In rare cases our system cannot identify a sleep period at all, this happens in some cases where 
                                an individual wakes frequently throughout the night, or has severe insomnia.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="sleep-onset-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Average 24hr HR: </b>
						<span>{stats.avg_heart_rate.toFixed(1)} BPM</span>
                        <span
                            data-tooltip-id="24hr-tooltip"
                            data-tooltip-content={`Your average heart rate in BPM over the past 24-hour period.
                            As a rule of thumb, the lower your average 24-hour heart rate, the better your mental wellbeing.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="24hr-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Sleep Finish: </b>
						<span>{DateTime.fromISO(stats.sleep_finish).toFormat("HH:mm")}</span>
                        <span
                            data-tooltip-id="sleep-finish-tooltip"
                            data-tooltip-content={`This score identifies physiological indicators correlated with the end of the sleep period, changes in
                                heart rate and activity signify to our system that sleep has ended. This score is not always 100%
                                accurate and can be disrupted due to external influences. In rare cases our system cannot identify a
                                sleep period at all, this happens in some cases where an individual wakes frequently throughout the
                                night or has severe insomnia.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="sleep-finish-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Average Sleep HR: </b>
						<span>{stats.avg_sleep_heart_rate.toFixed(1)} BPM</span>
                        <span
                            data-tooltip-id="sleep-hr-tooltip"
                            data-tooltip-content={`Your average heart rate during your sleep period in BPM (between
                                sleep onset and sleep finish). As a rule of thumb, the lower your average sleeping HR the better your
                                mental wellbeing.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="sleep-hr-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Total Sleep (hours): </b>
						<span>{Duration.fromObject({"minutes": stats.sleep_duration}).toFormat("h:mm") }</span>
                        <span
                            data-tooltip-id="total-sleep-tooltip"
                            data-tooltip-content={`How many hours you slept (the time between sleep onset and sleep finish).`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="total-sleep-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Total Steps: </b>
						<span>{stats.total_steps}</span>
                        <span
                            data-tooltip-id="total-steps-tooltip"
                            data-tooltip-content={`The number of steps you took over a 24-hour period. In general, increased
                                activity correlates with improving mental wellbeing.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="total-steps-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Total Sleep Interruptions: </b>
						<span>{stats.total_interruptions}</span>
                        <span
                            data-tooltip-id="interruptions-tooltip"
                            data-tooltip-content={`How many times you awoke during the sleep period (between
                                sleep onset and sleep end). Less sleep interruptions correlate with improved mental wellbeing.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="interruptions-tooltip" className="tooltip-content" />
                        </span>
					</div>
					<div>
						<b>Data Quality: </b>
						<span>{(props.report!.quality * 100).toFixed()}%</span>
                        <span
                            data-tooltip-id="quality-tooltip"
                            data-tooltip-content={`A higher data quality score means that your scores have a higher likelihood of being accurate. This
                                score represents the amount of useable data collected from your device over a 24-hour period.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="quality-tooltip" className="tooltip-content" />
                        </span>
					</div>
				</div>
			</div>
    	</section>

		<div style={{ display: "flex" }}>
            <section style={{ flex: 1 }}>
                <h2>Physiological scores</h2>
                <div id="comment"></div>
                <div id="graphics">
                    <h3>
                        Low mood
                        <span
                            data-tooltip-id="low-mood-tooltip"
                            data-tooltip-content={`This score compares your physiological patterns over a 24-hour period (also known as your circadian pattern)
                                with a large database containing thousands of 24-hour physiological recordings from people ranging from very
                                healthy and stress-free to the most severe cases of clinical depression. The score places you somewhere on a
                                range of 0 - 10 with 0 being very healthy and 10 meaning your circadian patterns match with the most severe
                                examples of clinical depression in our database.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="low-mood-tooltip" className="tooltip-content" />
                        </span>
                    </h3>
                    <h3>
                        Mental stress
                        <span
                            data-tooltip-id="mental-stress-tooltip"
                            data-tooltip-content={`This score compares your physiological patterns over a 24-hour period (also known as your circadian pattern)
                                with a large database containing thousands of 24-hour physiological recordings from people ranging from very
                                healthy and stress-free to the most severe cases of generalised anxiety disorder. The score places you somewhere on a
                                range of 0 - 10 with 0 being very healthy and 10 meaning your circadian patterns match with the most severe
                                examples of generalised anxiety in our database.`}>
                                <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                                <Tooltip id="mental-stress-tooltip" className="tooltip-content" />
                        </span>
                    </h3>
                    <Gauge
                        value={stats.score_depression}
                        precision={1}
                        maxValue={10}
                        valueDesc={scoreDesc(stats.score_depression)} />
                    <Gauge
                        value={stats.score_anxiety}
                        precision={1}
                        maxValue={10}
                        valueDesc={scoreDesc(stats.score_anxiety)} />
                </div>
            </section>

			<section style={{ flex: 1 }}>
                <h2>Latest DASS-21</h2>
                { props.report!.latest_dass21 ?
                    <div>
                        <span>{ formatDatetime(props.report!.latest_dass21.completed_at) }</span>
                        <div id="graphicsDass">
                            <h4>Depression</h4>
                            <h4>Anxiety</h4>
                            <h4>Stress</h4>
                            <Gauge
                                value={props.report!.latest_dass21.depression_score}
                                precision={0}
                                maxValue={21}
                                valueDesc={props.report!.latest_dass21.depression_label} />
                            <Gauge
                                value={props.report!.latest_dass21.anxiety_score}
                                precision={0}
                                maxValue={21}
                                valueDesc={props.report!.latest_dass21.anxiety_label} />
                            <Gauge
                                value={props.report!.latest_dass21.stress_score}
                                precision={0}
                                maxValue={21}
                                valueDesc={props.report!.latest_dass21.stress_label} />
                        </div>
                    </div> :
                    <i className="empty-message">No DASS-21 completed prior to this report date.</i>
                }
			</section>
		</div>

		<section style={{ textAlign: "center" }}>
            { showMore ? 
                <button className="secondary-button fa-icon-button" onClick={() => setShowMore(false)}>
                    <FontAwesomeIcon icon={faCaretUp} />
                    Show Less
                </button> :
                <button className="secondary-button fa-icon-button" onClick={() => setShowMore(true)}>
                    <FontAwesomeIcon icon={faCaretDown} />
                    Show More
                </button> }
		</section>

    { showMore ? <>
		<section>
			<h2>Sleep Data</h2>
			<div className="stats-grid sleep-grid">
				<div></div>
				<b>Mean Pulse</b>
				<b>Variation</b>
				<b>24 Hour</b>
				<div>{stats.avg_heart_rate.toFixed(1)}</div>
				<div>{stats.hr_variation.toFixed(1)}</div>
				<b>Awake</b>
				<div>{stats.avg_awake_heart_rate.toFixed(1)}</div>
				<div>{stats.awake_hr_variation.toFixed(1)}</div>
				<b>Sleep</b>
				<div>{stats.avg_sleep_heart_rate.toFixed(1)}</div>
				<div>{stats.sleep_hr_variation.toFixed(1)}</div>
			</div>
		</section>

		<section>
			<h2>Interruptions Data</h2>
			<div className="stats-grid interruptions-grid">
				<div></div>
				<b className="sleep-period-text">Length of Interruption</b>
				<b className="sleep-interruptions-text">Heart Rate</b>
				<div></div>
				<b>5 - 14 min</b>
				<b>15 - 30 min</b>
				<b>&gt; 30 min</b>
				<b>
					Mean Pulse
                    <span
                        data-tooltip-id="mean-pulse-tooltip"
                        data-tooltip-content={`Average HR should stay relatively uniform throughout the sleep periods, big changes in average HR
                        between early, middle and late sleep are indicative of poor mental wellbeing.`}>
                            <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                            <Tooltip id="mean-pulse-tooltip" className="tooltip-content" />
                    </span>
				</b>
				<b>
					Mean Variation
                    <span
                        data-tooltip-id="mean-variation-tooltip"
                        data-tooltip-content={`Sleep variability shouldn’t change very much throughout the sleep periods. The less your sleep
                        variability scores change the better your mental wellbeing (in general).`}>
                            <FontAwesomeIcon icon={faCircleQuestion} className="tooltip-icon" />
                            <Tooltip id="mean-variation-tooltip" className="tooltip-content" />
                    </span>
				</b>

				<b>Early</b>
				<div>{stats.interruptions['5-14min']['early']}</div>
				<div>{stats.interruptions['14-30min']['early']}</div>
				<div>{stats.interruptions['>30min']['early']}</div>
				<div>{stats.avg_early_sleep_heart_rate.toFixed(1)}</div>
				<div>{stats.early_sleep_hr_variation.toFixed(1)}</div>

				<b>Middle</b>
				<div>{stats.interruptions['5-14min']['middle']}</div>
				<div>{stats.interruptions['14-30min']['middle']}</div>
				<div>{stats.interruptions['>30min']['middle']}</div>
				<div>{stats.avg_middle_sleep_heart_rate.toFixed(1)}</div>
				<div>{stats.middle_sleep_hr_variation.toFixed(1)}</div>

				<b>Late</b>
				<div>{stats.interruptions['5-14min']['late']}</div>
				<div>{stats.interruptions['14-30min']['late']}</div>
				<div>{stats.interruptions['>30min']['late']}</div>
				<div>{stats.avg_late_sleep_heart_rate.toFixed(1)}</div>
				<div>{stats.late_sleep_hr_variation.toFixed(1)}</div>
			</div>
		</section>

		<section style={{display: "none"}}>
			<h2>Spiking Data</h2>
			<div className="stats-grid spiking-grid">
				<div></div>
				<b>Mild</b>
				<b>Moderate</b>
				<b>Severe</b>
				<b>Total for 5 min</b>
				<div>{stats.total_spikes['mild']['5min']}</div>
				<div>{stats.total_spikes['mild']['10min']}</div>
				<div>{stats.total_spikes['mild']['15min']}</div>
				<b>10 min</b>
				<div>{stats.total_spikes['moderate']['5min']}</div>
				<div>{stats.total_spikes['moderate']['10min']}</div>
				<div>{stats.total_spikes['moderate']['15min']}</div>
				<b>15 min</b>
				<div>{stats.total_spikes['severe']['5min']}</div>
				<div>{stats.total_spikes['severe']['10min']}</div>
				<div>{stats.total_spikes['severe']['15min']}</div>
				<b>Sleep for 5 min</b>
				<div>{stats.dormant_spikes['mild']['5min']}</div>
				<div>{stats.dormant_spikes['mild']['10min']}</div>
				<div>{stats.dormant_spikes['mild']['15min']}</div>
				<b>10 min</b>
				<div>{stats.dormant_spikes['moderate']['5min']}</div>
				<div>{stats.dormant_spikes['moderate']['10min']}</div>
				<div>{stats.dormant_spikes['moderate']['15min']}</div>
				<b>15 min</b>
				<div>{stats.dormant_spikes['severe']['5min']}</div>
				<div>{stats.dormant_spikes['severe']['10min']}</div>
				<div>{stats.dormant_spikes['severe']['15min']}</div>
			</div>
		</section>

    </> : null }

    </div>;
}