import { createContext, useContext, useEffect, useState } from "react";
import { Patient } from "../types";
import { AuthContext } from "./AuthContext";
import { AxiosContext } from "./AxiosContext";
import { ErrorBox } from "../components/Errors";
import Loading from "../components/Loading";

type PatientContextType = {
	patient: Patient | null,
	updatePatient: (newPatient: any) => void,
	isShared: boolean
	// getPatientFromServer: () => Promise<Patient>
};

const PatientContext = createContext<PatientContextType | null>(null);


type PatientProviderProps = React.PropsWithChildren & {patientId: string};

const PatientProvider = (props: PatientProviderProps) => {
	const { sherpahGet, sherpahPut, catchAxios } = useContext(AxiosContext)!;
	const [patient, setPatient] = useState<Patient | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [isShared, setIsShared] = useState<boolean>(false);
	const authContext = useContext(AuthContext)!;

	const init = async () => {
		try {
			const response = await sherpahGet<Patient & {is_shared: boolean}>(`patients/${props.patientId}`);
			setPatient(response.data);
			setIsShared(response.data.is_shared);
		} catch (e: any) {
			catchAxios(e, setError);
		}
	};

	const updatePatient = async (newPatient: any) => {
		const response = await sherpahPut<Patient>(`patients/${props.patientId}`, newPatient);
		setPatient(response.data);
	};

	useEffect(() => {
		if (!patient && authContext.authState.authenticated) {
			init();
		}
	}, [authContext.authState.authenticated]);

	return (
		<PatientContext.Provider value={{
			patient,
			updatePatient,
			isShared
		}}>
			{ patient ? props.children : <main>
				{error ? 
					<ErrorBox>{error}</ErrorBox> : 
					<Loading text="Loading patient..." />
				}
			</main>}
		</PatientContext.Provider>
	);
};

export { PatientContext, PatientProvider };