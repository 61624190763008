
export const Routes = {

    accounts: {
        preferences: "/accounts/preferences"
    },
    
    anon: {
        accounts: {
            login2: "/accounts/login2"
        }
    },

    patients: {
        index: "/patients",
        id: (id: string) => `patients/${id}`
    }
}
