import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';

import {
    SharedPatient as SharedPatientType,
    Doctor as DoctorType,
    PatientListItem
} from '../../types';

import "./doctor.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faTable, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ErrorText } from '../../components/Errors';
import Loading from '../../components/Loading';
import { relativeDatetime } from '../../utils';
import { AuthContext } from '../../contexts/AuthContext';
import { AllPatientsList, ArchivedPatientsList, SharedPatientsList, TMSPatientsList } from './PatientsList';




type PatientsResponse = {
    patients: PatientListItem[],
    shared_patients: SharedPatientType[],
    doctor: DoctorType
};

type OperatorPatientsResponse = Omit<PatientsResponse, "shared_patients">;



function Doctor() {
    const [patients, setPatients] = useState<PatientListItem[] | null>(null);
    const [sharedPatients, setSharedPatients] = useState<SharedPatientType[] | null>(null);
    const [doctor, setDoctor] = useState<DoctorType | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<"TMS" | "ALL" | "SHARED" | "ARCHIVED">("TMS");
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { user } = useContext(AuthContext)!.authState;

    const getDoctor = async () => {
        try {
            let response;
            if (user?.is_operator) {
                response = await sherpahGet<OperatorPatientsResponse>(`/operators/${user.id}/patients`);
            } else {
                response = await sherpahGet<PatientsResponse>(`/doctors/${user!.id}/patients`);
                setSharedPatients(response.data.shared_patients);
            }
            setPatients(response.data.patients);
            setDoctor(response.data.doctor);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDoctor();
    }, []);

    const updatePatient = (patient: PatientListItem) => {
        setPatients(ps => ps!.map(p => patient.user.id == p.user.id ? patient : p));
    }

    let content;
    if (error != null) {
        content = <main><ErrorText>{error}</ErrorText></main>;
    } else if (loading) {
        content = <main><Loading /></main>;
    } else {
        content = <main>
            <div className="heading-wrapper">
                <h1>{doctor!.user.full_name}</h1>
                <div className="heading-wrapper-actions">
                    {doctor!.user.id == 37 || doctor!.user.id == 95 ? 
                        <Link to="/algorithm-data" className="w-button secondary-button fa-icon-button">
                            <FontAwesomeIcon icon={faTable} />
                            Algorithm data
                        </Link> : null }
                    <Link to="/managed-patients/add" className="w-button secondary-button fa-icon-button">
                        <FontAwesomeIcon icon={faUserPlus} />
                        Add managed patient
                    </Link>
                </div>
            </div>
            <div className="rounded-container">
                <div className="tabs">
                    <span className={`tab fa-icon-button ${activeTab == "TMS" ? " active-tab" : ""}`} onClick={() => setActiveTab("TMS")}>
                        {/* <FontAwesomeIcon icon={faHospitalUser} /> */}
                        TMS patients
                    </span>
                    <span className={`tab fa-icon-button ${activeTab == "ALL" ? " active-tab" : ""}`} onClick={() => setActiveTab("ALL")}>
                        {/* <FontAwesomeIcon icon={faUsers} /> */}
                        All patients
                    </span>
                    { user?.is_doctor ? 
                        <span className={`tab fa-icon-button ${activeTab == "SHARED" ? " active-tab" : ""}`} onClick={() => setActiveTab("SHARED")}>
                            {/* <FontAwesomeIcon icon={faPeopleArrows} /> */}
                            Shared patients
                        </span> : null }
                    <span className={`tab fa-icon-button ${activeTab == "ARCHIVED" ? " active-tab" : ""}`} onClick={() => setActiveTab("ARCHIVED")}>
                        {/* <FontAwesomeIcon icon={faFolderMinus} /> */}
                        Archived patients
                    </span>
                </div>
                { activeTab == "TMS" ? <TMSPatientsList patients={patients!} onUpdate={updatePatient} /> : null }
                { activeTab == "ALL" ? <AllPatientsList patients={patients!} onUpdate={updatePatient} /> : null }
                { activeTab == "SHARED" ? <SharedPatientsList sharedPatients={sharedPatients!} /> : null }
                { activeTab == "ARCHIVED" ? <ArchivedPatientsList patients={patients!} onUpdate={updatePatient} /> : null }
            </div>
        </main>;
    }

    return content;
}

export default Doctor;
