import React, { useContext, useReducer, useState } from 'react';

import {
	Diagnosis, PatientDiagnosis
} from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { SharedContext } from '../../../contexts/SharedContext';
import { ErrorText } from '../../../components/Errors';


type Action = {
    type: "added" | "deleted",
    diagnosis: PatientDiagnosis
};

function diagnosesReducer(diagnoses: PatientDiagnosis[], action: Action) {
    switch (action.type) {
        case "added": {
            return [...diagnoses, action.diagnosis];
        }
        case "deleted": {
            return diagnoses.filter(d => d.id !== action.diagnosis.id);
        }
        default: {
            throw Error("Unknown action: " + action.type);
        }
    }
}

type DiagnosesTabProps = {
	patientDiagnoses: PatientDiagnosis[]
};

export default function DiagnosesTab(props: DiagnosesTabProps) {
    const [patientDiagnoses, diagnosesDispatch] = useReducer(
        diagnosesReducer,
        props.patientDiagnoses
    );
    const { diagnoses } = useContext(SharedContext)!;

	const patientDiagnosisIds = patientDiagnoses.map(pd => pd.id);
	const diagnosisOptions = diagnoses.filter(diagnosis => !patientDiagnosisIds.includes(diagnosis.id));

	const [selectedDiagnosisId, setSelectedDiagnosisId] = useState<string>(diagnosisOptions[0].id);
    const { sherpahPost, sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!;
	const [error, setError] = useState<string | null>(null);


	const addDiagnosis = async () => {
		setError(null);
		try {
			const response = await sherpahPost<PatientDiagnosis>(`/patients/${patient!.user_id}/diagnoses`, {
				diagnosisId: selectedDiagnosisId
			});
			diagnosesDispatch({
				type: "added",
				diagnosis: response.data
			});
			const updatedDiagnosisOptions = diagnosisOptions.filter(d => d.id != selectedDiagnosisId);
			setSelectedDiagnosisId(updatedDiagnosisOptions[0].id);
		} catch (e: any) {
			catchAxios(e, setError);
		}
	};

	const removeDiagnosis = async (diagnosis: PatientDiagnosis) => {
		setError(null);
		try {
			await sherpahDelete(`/patients/${patient!.user_id}/diagnoses/${diagnosis.id}`);
			diagnosesDispatch({
				type: "deleted",
				diagnosis: diagnosis
			});
		 } catch (e: any) {
			catchAxios(e, setError);
		}
	};

    return <div className="flex-col-gap-8">
			{patientDiagnoses.length > 0 ? 
				<div className="inline-selections">
					{patientDiagnoses.map(diagnosis => 
						<span className="inline-selection" key={diagnosis.id}>
							{ diagnosis.diagnosis_name }
							{ !isShared ? <FontAwesomeIcon icon={faTimes} className="remove-selection" onClick={() => removeDiagnosis(diagnosis)} /> : null }
							<br/>
							{ diagnosis.assigned_by_patient ? 
								<small style={{ backgroundColor: "#ffca64", display: "inline-block", padding: "0 4px", borderRadius: 2, borderWidth: 1, borderColor: "#777" }}>Assigned by patient</small>
								:
								<small style={{ backgroundColor: "#72cfff", display: "inline-block", padding: "0 4px", borderRadius: 2, borderWidth: 1, borderColor: "#777" }}>Assigned by doctor</small> }
						</span>
					)}
				</div>
				: <i className="empty-message">No diagnoses</i> }
			{error ? <ErrorText>{error}</ErrorText> : null}
			{ !isShared ? <>
					<hr/>
					<div>
						<select style={{ width: "auto" }} onChange={e => setSelectedDiagnosisId(e.target.value)} value={selectedDiagnosisId}>
							{ diagnosisOptions.map(diagnosis => 
								<option value={diagnosis.id} key={diagnosis.id}>{ diagnosis.name }</option>
							)}
						</select>
						<button onClick={addDiagnosis} className="secondary-button" style={{marginLeft: 8}}>Add diagnosis</button>
					</div>
				</> : null }
		</div>;
}