import { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';
import OverviewTab from "./OverviewTab";
import MedicationsTab from "./MedicationsTab";
import DiagnosesTab from './DiagnosesTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faChartLine, faEdit, faShareFromSquare, faSquarePollVertical, faTimes, faTrashAlt, faUserLock } from '@fortawesome/free-solid-svg-icons';
import {
    SharedPatient,
    PatientOverview,
    PatientOverviewForDoctors,
} from '../../../types';
import ActivityTab from './ActivityTab';
import { PatientContext } from '../../../contexts/PatientContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { SharedContext } from '../../../contexts/SharedContext';
import { Slide, toast } from 'react-toastify';
import { DEFAULT_TOAST_SETTINGS } from '../../../utils';
import { ErrorText } from '../../../components/Errors';
import "./overview.scss";
import TMSTab from './TMSTab';
import Loading from '../../../components/Loading';
import ReviewsTab from './ReviewsTab';
import CommentsTab from './CommentsTab';
import FilesTab from './FilesTab';


type Action = 
    { type: "initialise", sharedPatients: SharedPatient[] } | 
    { type: "add", sharedPatient: SharedPatient } | 
    { type: "delete", doctorId: string }; 

function sharedPatientReducer(sharedPatients: SharedPatient[], action: Action) {    
    if (action.type == "initialise") return action.sharedPatients!;    
    if (action.type == "add") return [...sharedPatients, action.sharedPatient!];    
    if (action.type == "delete") return sharedPatients.filter(shared => shared.shared_doctor_id !== action.doctorId);
    throw Error("Unknown action");
}

const TABS = ["overview", "tms", "medications", "diagnoses", "activity", "reviews", "comments", "files"];

export default function Overview() {
    const params = useParams<{patientId: string}>();
    const hash = useLocation().hash.replace("#", "");
    const initialTab = TABS.includes(hash) ? hash : "overview";

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [patientOverview, setPatientOverview] = useState<PatientOverview | null>(null);
    const [activeTab, setActiveTab] = useState<typeof TABS[number]>(initialTab);
    const [moreActionsVisible, setMoreActionsVisible] = useState<boolean>(false);
    const [shareModalVisible, setShareModalVisible] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [unacknowledgedComments, setUnacknowledgedComments] = useState<number>(0);
    const { sherpahGet, sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!;
    const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();
    const isPatient = authContext.authState.user?.is_patient;
    const isDoctor = authContext.authState.user?.is_doctor;
    const isOperator = authContext.authState.user?.is_operator;

    const [sharedWith, sharedPatientDispatch] = useReducer(sharedPatientReducer, []);

    
    const getOverview = async () => {
        try {
            const response = await sherpahGet<PatientOverviewForDoctors>(`/patients/${params.patientId}/overview`);
            setPatientOverview(response.data);
            setUnacknowledgedComments(response.data.unacknowledged_comments);
            if ((isDoctor || isOperator)) sharedPatientDispatch({ type: "initialise", sharedPatients: response.data.shared_with });
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", () => setMoreActionsVisible(false));
        getOverview();
    }, []);

    const unsharePatient = async (doctorId: string) => {
        try {
            const response = await sherpahDelete(`/patients/${params.patientId}/share?doctorId=${doctorId}`);
            sharedPatientDispatch({ type: "delete", doctorId });
        } catch {
            // TODO error unsharing patient
        }
    }

    let content;
    if (error != null) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else if (patientOverview != null) {
        content = <>
            <div className="tabs">
                <span className={`tab ${activeTab == "overview" ? " active-tab" : ""}`} onClick={() => setActiveTab("overview")}>Overview</span>
                { (isDoctor || isOperator) ? <>
                    <span className={`tab ${activeTab == "tms" ? " active-tab" : ""}`} onClick={() => setActiveTab("tms")}>TMS</span>
                    <span className={`tab ${activeTab == "medications" ? " active-tab" : ""}`} onClick={() => setActiveTab("medications")}>Medications</span>
                    <span className={`tab ${activeTab == "diagnoses" ? " active-tab" : ""}`} onClick={() => setActiveTab("diagnoses")}>Diagnoses</span>
                    <span className={`tab ${activeTab == "activity" ? " active-tab" : ""}`} onClick={() => setActiveTab("activity")}>Activity</span>
                    <span className={`tab ${activeTab == "reviews" ? " active-tab" : ""}`} onClick={() => setActiveTab("reviews")}>Reviews</span>
                    <span className={`tab ${activeTab == "comments" ? " active-tab" : ""}`} onClick={() => setActiveTab("comments")}>
                        Comments
                        { unacknowledgedComments > 0 ? <span className="tab-badge">{unacknowledgedComments}</span> : null }
                    </span>
                    <span className={`tab ${activeTab == "files" ? " active-tab" : ""}`} onClick={() => setActiveTab("files")}>Files</span>
                </> : null }
            </div>
            { activeTab == "overview" ? <OverviewTab overview={patientOverview!} /> : null }
            { (isDoctor || isOperator) ? <>
                { activeTab == "tms" ? <TMSTab /> : null }
                { activeTab == "medications" ? <MedicationsTab overview={patientOverview!} /> : null }
                { activeTab == "diagnoses" ? <DiagnosesTab patientDiagnoses={patientOverview.diagnoses} /> : null }
                { activeTab == "activity" ? <ActivityTab overview={patientOverview!} /> : null }
                { activeTab == "reviews" ? <ReviewsTab /> : null }
                { activeTab == "comments" ? <CommentsTab onAcknowledge={() => setUnacknowledgedComments(0)} /> : null }
                { activeTab == "files" ? <FilesTab /> : null }
            </> : null }
        </>;
    }


    return <main>
            <div className="heading-wrapper">
                <h1>{patient!.user.full_name}</h1>
                <div className="heading-wrapper-actions">
                    <Link to="report" className="w-button fa-icon-button">
                        <FontAwesomeIcon icon={faChartLine} />
                        View report
                    </Link>
                    { !isShared ? 
                        (isDoctor ?
                            <div className="dropdown" onClick={e => e.stopPropagation()}>
                                <button onClick={() => setMoreActionsVisible(val => !val)} className="w-button secondary-button fa-icon-button">
                                    <FontAwesomeIcon icon={faCaretDown} />
                                    More
                                </button>
                                { moreActionsVisible ? 
                                    <div className="dropdown-content">
                                        <Link to="edit" className="dropdown-item fa-icon-button">
                                            <FontAwesomeIcon icon={faEdit} />
                                            Edit
                                        </Link>
                                        <span onClick={() => setShareModalVisible(true)} className="dropdown-item fa-icon-button">
                                            <FontAwesomeIcon icon={faShareFromSquare} />
                                            Share with doctor
                                        </span>
                                        <Link to="questionnaires" className="dropdown-item fa-icon-button">
                                            <FontAwesomeIcon icon={faSquarePollVertical} />
                                            Questionnaires
                                        </Link>
                                        <span onClick={() => setDeleteModalVisible(true)} className="dropdown-item fa-icon-button danger-button">
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                            Delete
                                        </span>
                                    </div> : null }
                            </div>
                            :
                            <Link to="questionnaires" className="w-button secondary-button fa-icon-button">
                                <FontAwesomeIcon icon={faSquarePollVertical} />
                                Questionnaires
                            </Link>)
                    : null }
                </div>
            </div>

            { isDoctor && !isShared && sharedWith.length > 0 ? 
                <div>
                    <b style={{ marginRight: 8 }}>Shared with:</b>
                    <span className="inline-selections">
                        {sharedWith.map(share => 
                            <span className="inline-selection" key={share.shared_doctor_id}>
                                { share.shared_doctor_name }
                                { share.deidentified ? <FontAwesomeIcon icon={faUserLock} style={{ marginLeft: 6 }} /> : null }
                                <FontAwesomeIcon icon={faTimes} onClick={() => unsharePatient(share.shared_doctor_id)} className="remove-selection" />
                            </span>
                        )}
                    </span>
                </div> : null }

            <div className="rounded-container">
                {content}
            </div>

            { shareModalVisible ? <SharePatientModal
                onClose={() => setShareModalVisible(false)}
                onShare={(sharedPatient: SharedPatient) => {
                    sharedPatientDispatch({ type: "add", sharedPatient });
                    setShareModalVisible(false);
                }} />
            : null }

            { deleteModalVisible ? <DeletePatientModal
                onClose={() => setDeleteModalVisible(false)}
                onDelete={() => {
                    navigate("/patients");
                    toast('Patient deleted.', DEFAULT_TOAST_SETTINGS);
                }} />
            : null }
    </main>;
}


type SharePatientModalProps = {
    onClose: () => void,
    onShare: (sharedPatient: SharedPatient) => void
};

function SharePatientModal(props: SharePatientModalProps) {
    const { sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;

    const [emailOrCode, setEmailOrCode] = useState<string>("");
    const [deidentified, setDeidentified] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isSharing, setIsSharing] = useState<boolean>(false);

    const sharePatient = async () => {
        if (emailOrCode.length != 6 && !emailOrCode.includes("@")) {
            setError("Please either input 6-character linking code or a valid email address.");
            return;
        }
        setIsSharing(true);
        try {
            const response = await sherpahPost<SharedPatient>(`/patients/${patient?.user_id}/share`, {
                    email_or_code: emailOrCode,
                    deidentified: deidentified
                });
            props.onShare(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setIsSharing(false);
        }
    };

    return <div className="modal-wrapper" onClick={props.onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-heading">Share patient with another doctor</div>
                <div>Enter the doctor's email address or linking code to share patient:</div>
                <input type="text" onChange={e => setEmailOrCode(e.target.value)} placeholder="Email address or linking code" />
                <div>
                    <input type="checkbox" name="deidentified" id="deidentified" onChange={e => setDeidentified(e.target.checked)} />
                    <label htmlFor="deidentified">Deidentify patient</label>
                </div>
                { error ? <ErrorText>{error}</ErrorText> : null }
                { isSharing ? 
                    <div className="modal-actions">
                        <button type="button" className="w-button" disabled>Sharing...</button>
                    </div>
                    :
                    <div className="modal-actions">
                        <button type="submit" onClick={sharePatient} className="w-button">Share</button>
                        <button type="button" onClick={props.onClose} className="secondary-button w-button">Cancel</button>
                    </div>
                }
        </div>
    </div>;
}


type DeletePatientModalProps = {
    onClose: () => void,
    onDelete: () => void
};

function DeletePatientModal(props: DeletePatientModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const { patient } = useContext(PatientContext)!;

    const deletePatient = async () => {
        setIsDeleting(true);
        try {
            await sherpahDelete(`/patients/${patient!.user_id}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setIsDeleting(false);
        }
    };

    return <div className="modal-wrapper" onClick={props.onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-heading">Delete patient</div>
            { isDeleting ? 
                <div>Deleting...</div>
                :
                <>
                    <div>
                        Are you sure you want to delete this patient?<br/>
                        This will delete all the patient's data including smartwatch metrics.<br/>
                        <b>This action is irreversible.</b>
                    </div>
                    { error ? <ErrorText>{error}</ErrorText> : null }
                    <div className="modal-actions">
                        <button type="submit" onClick={deletePatient} className="danger-button w-button">Yes, delete</button>
                        <button type="button" onClick={props.onClose} className="secondary-button w-button">No, don't delete</button>
                    </div>
                </>
            }
        </div>
    </div>;
}
