import { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';

import { ErrorMessage, Field, Form, Formik, FormikHandlers, FormikHelpers } from 'formik';
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorText } from '../../components/Errors';
import { Operator } from '../../types';
import OperatorForm from './OperatorForm';
import Loading from '../../components/Loading';



export default function EditOperator() {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [operator, setOperator] = useState<Operator | null>(null);
    const params = useParams<{operatorId: string}>();

    const getOperator = async () => {
        setLoading(true);
        try {
            const response = await sherpahGet<Operator>(`/operators/${params.operatorId}`);
            setOperator(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOperator();
    }, []);

    if (error) {
        return <main><ErrorText>{error}</ErrorText></main>;
    } else if (loading) {
        return <main><Loading /></main>;
    } else if (operator) {
        return <OperatorForm operator={operator} />;
    } else {
        return <main><ErrorText>Error loading page.</ErrorText></main>;
    }
}