import { useContext, useState } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";

import './login.scss';
import { ErrorText } from "../../components/Errors";
import Loading from "../../components/Loading";
import { UnauthContainer } from "./UnauthContainer";


export default function ResetPassword() {
	const axiosContext = useContext(AxiosContext)!;
	const [email, setEmail] = useState<string>("");
	const [sentEmail, setSentEmail] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

    const handleReset = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosContext.publicAxios.post("accounts/password_reset/", {
                email
            });
            setSentEmail(true);
        } catch (e: any) {
            axiosContext.catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    return <UnauthContainer>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { loading ? <Loading text="Sending password reset link..." /> :
                ( sentEmail ? 
                    <p>We have emailed you instructions for resetting your password.</p>
                        :
                    <div className="flex-col-gap-8">
                        <div>Enter your email address below, and you will receive email instructions for setting a new password.</div>
                        <input type="email" onChange={e => setEmail(e.currentTarget.value)} placeholder="Email address" />
                        <button className="w-button" onClick={handleReset}>Reset Password</button>
                    </div> )
            }
    </UnauthContainer>;
}