import Badge from "./google-play-badge.png";

type Props = {
    href: string;
    alt: string;
}

export function PlayStoreBadge(props: Props) {
    return <a href={props.href} target="_blank">
        <img height={70} alt="Download link for" src={Badge} />
    </a>
}
