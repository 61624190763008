import { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';

import "./dass.scss";

import { User, BDI as BDIType } from '../../../types';
import { PatientContext } from '../../../contexts/PatientContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { DEFAULT_TOAST_SETTINGS, formatDate, formatDatetime } from '../../../utils';
import { ErrorText } from '../../../components/Errors';
import { toast } from 'react-toastify';
import { BDI_QUESTIONS } from './questions';
import { DateTime } from 'luxon';
import Loading from '../../../components/Loading';
import DatePicker from 'react-datepicker';


type BDIAnswers = {
	[key in `q${1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21}`]: number | null
};

type QuestionProps = {
    statements: string[],
    index: number,
    answer?: number,
    onAnswer?: (questionIndex: keyof BDIAnswers, value: number) => void
};

function Question(props: QuestionProps) {
    const { statements, index } = props;
    const [answer, setAnswer] = useState<number | null>(props.answer === undefined ? null : props.answer);

    const updateAnswer = (value: number) => {
        if (props.onAnswer) {
            setAnswer(value);
            props.onAnswer(`q${index}` as keyof BDIAnswers, value);
        }
    };

    return <>
        <div className="dass-question">
            <h4>{index}.</h4>
            <div className="dass-options">
                {statements.map((statement, i) => 
                    <button onClick={() => updateAnswer(i)} className={answer == i ? "selected" : ""} key={i} disabled={props.answer !== undefined}>
                        {statement}
                    </button>
                )}
            </div>
        </div>
        <hr/>
    </>;
}

type BDIResults = BDIAnswers & {
    completed_at: string,
    backdated_to: string | null
};

export default function BDI() {
    const [error, setError] = useState<string | null>(null);
    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [backdate, setBackdate] = useState<Date | null>(null);
    const { sherpahGet, sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const navigate = useNavigate();

    const params = useParams<{id: string}>();
    const showingResults = params.id !== undefined;

    const initialAnswers: BDIAnswers = BDI_QUESTIONS.reduce((obj: any, q, index) => {obj[`q${index+1}`] = null; return obj;}, {});
	const [answers, setAnswers] = useState<BDIAnswers>(initialAnswers);
    const [completedAt, setCompletedAt] = useState<string | null>(null);

    const getResults = async () => {
        try {
            const response = await sherpahGet<{"bdi": BDIResults}>(`patients/${patient!.user_id}/bdi/${params.id}`);
            setAnswers(response.data.bdi);
            if (response.data.bdi.backdated_to) {
                setCompletedAt(formatDate(response.data.bdi.backdated_to) + " (backdated)");
            } else {
                setCompletedAt(formatDatetime(response.data.bdi.completed_at));
            }
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showingResults) {
            setLoading(true);
            getResults();
        }
    }, []);

	const postResults = async () => {
        setSubmitting(true);
        setSubmissionError(null);

        // ensure entire questionnaire was filled out
        let isCompleted = true;
        for (const answer in answers) {
            if (answers[answer as keyof BDIAnswers] === null) {
                isCompleted = false;
                break;
            }
        }

        if (!isCompleted) {
            setSubmissionError("Please answer all questions.");
            setSubmitting(false);
            return;
        }

        let data: any = {...answers};
        if (backdate) {
            data["backdated_to"] = DateTime.fromJSDate(backdate).toISODate();
        }

		try {
			await sherpahPost<BDIType>(
				`patients/${patient!.user_id}/bdi`,
				data
			);
            toast("BDI completed!", DEFAULT_TOAST_SETTINGS);
            navigate(-1);
		} catch (e: any) {
			catchAxios(e, setSubmissionError);
		} finally {
			setSubmitting(false);
		}
	};

	const updateAnswers = (questionIndex: keyof BDIAnswers, value: number) => {
        setAnswers(a => {
            a[questionIndex] = value;
            return a;
        });
	};

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <div>
            { showingResults ? 
                <p><b>{patient?.user.full_name}</b> completed this questionnaire on <b>{ completedAt }</b></p> :
                <>
                    <p>A multiple-item questionnaire used to provide an indication of depression, and as a guide to evaluate recovery.</p>
                    <b>If this questionnaire was completed at a past date you may enter that date here:</b> <DatePicker
                        selected={backdate}
                        onChange={(d: Date) => setBackdate(d)}
                        dateFormat="d MMM yyyy" />
                </>
            }
            { submissionError ? <ErrorText>{submissionError}</ErrorText> : null }
            { showingResults ? 
                BDI_QUESTIONS.map((statements, i) => <Question statements={statements} index={i+1} key={i} answer={answers[`q${i+1}` as keyof BDIAnswers]!} />) :
                BDI_QUESTIONS.map((statements, i) => <Question statements={statements} index={i+1} key={i} onAnswer={updateAnswers} /> )
            }
            { submissionError ? <ErrorText>{submissionError}</ErrorText> : null }
            { !showingResults ? (
                submitting ? 
                    <button disabled>Submitting...</button> : 
                    <button onClick={postResults}>Submit</button>
            ) : null }
        </div>;
    }

    return <main>
        <div className="heading-wrapper">
            <h1>Beck Depression Inventory</h1>
        </div>
        <div className="rounded-container">
            { content }
        </div>
    </main>
}