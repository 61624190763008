import { useContext, useReducer, useState } from 'react';
import {
    MedicationCourse,
    PatientOverview
} from '../../../types';
import { PatientContext } from '../../../contexts/PatientContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileLines, faTrashAlt, faPrescriptionBottleMedical } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDeleteModal, MedicationCourseModal } from './MedicationsModals';
import { Tooltip } from 'react-tooltip';
import { formatDate } from '../../../utils';



type MedicationAction = {
    type: "added" | "edited" | "deleted",
    course: MedicationCourse
};

function medicationCoursesReducer(courses: MedicationCourse[], action: MedicationAction) {
    switch (action.type) {
        case "added": {
            return [...courses, action.course];
        }
        case "edited": {
            return courses.map(c => c.id === action.course.id ? action.course : c);
        }
        case "deleted": {
            return courses.filter(c => c.id !== action.course.id);
        }
        default: {
            throw Error("Unknown action: " + action.type);
        }
    }
}


type MedicationsTabProps = {
    overview: PatientOverview
};

export default function MedicationsTab(props: MedicationsTabProps) {
    const { overview } = props;
    const [showMedicationModal, setShowMedicationModal] = useState<boolean>(false);
    const [editingMedicationCourse, setEditingMedicationCourse] = useState<MedicationCourse | null>(null);
    const [deletingCourse, setDeletingCourse] = useState<MedicationCourse | null>(null);
    const { isShared } = useContext(PatientContext)!;

    const [medicationCourses, medicationDispatch] = useReducer(
        medicationCoursesReducer,
        overview.medication_courses
    );

    return <div className="flex-col-gap-8">
        { medicationCourses.length ?
        <div className="list-container">
            <div className="list-columns medications-list-grid">
                <div className="list-column">NAME</div>
                <div className="list-column">DOSAGE</div>
                <div className="list-column">FREQUENCY</div>
                <div className="list-column">START</div>
                <div className="list-column">END</div>
                { !isShared ? <div className="list-column">ACTIONS</div> : null }
            </div>
            { medicationCourses.map(medicationCourse => 
                <div className="list-item medications-list-grid" key={medicationCourse.id}>
                    <div>{ medicationCourse.medication.name }</div>
                    <div>{ medicationCourse.dosage }mg</div>
                    <div>{ medicationCourse.frequency } times per day</div>
                    <div>{ formatDate(medicationCourse.start) }</div>
                    <div>{ medicationCourse.end ? formatDate(medicationCourse.end) : "Ongoing" }</div>
                    { !isShared ?
                        <div className="course-actions">
                            {/* <a target="_blank" href={`/patients/${patient!.user_id}/medication-course/${medicationCourse.id}/report`} data-tooltip-id="report" data-tooltip-content="Report">
                                <Tooltip id="report" className="tooltip-content" />
                                <FontAwesomeIcon icon={faFileLines} className="fa-button" />
                            </a> */}
                            <div onClick={() => setEditingMedicationCourse(medicationCourse)} data-tooltip-id="edit" data-tooltip-content="Edit">
                                <Tooltip id="edit" className="tooltip-content" />
                                <FontAwesomeIcon icon={faEdit} className="fa-button" />
                            </div>
                            <div onClick={() => setDeletingCourse(medicationCourse)} data-tooltip-id="delete" data-tooltip-content="Delete">
                                <Tooltip id="delete" className="tooltip-content" />
                                <FontAwesomeIcon icon={faTrashAlt} className="fa-button" />
                            </div>
                        </div> : null }
                </div>
            )}
        </div> : <i className="empty-message">No medication</i> }

        { !isShared ?
            <div>
                <button className="w-button secondary-button fa-icon-button" onClick={() => setShowMedicationModal(true)}>
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faPrescriptionBottleMedical} /></span>
                    Add medication course
                </button>
            </div> : null }

        { (showMedicationModal || editingMedicationCourse) ? <MedicationCourseModal
            medicationCourse={editingMedicationCourse}
            onClose={() => {
                setShowMedicationModal(false);
                setEditingMedicationCourse(null);
            }}
            onAdd={(course: MedicationCourse) => medicationDispatch({type: "added", course})}
            onEdit={(course: MedicationCourse) => medicationDispatch({type: "edited", course})} />
        : null }

        { deletingCourse ? <ConfirmDeleteModal
            course={deletingCourse}
            onClose={() => setDeletingCourse(null)}
            onDelete={() => {
                medicationDispatch({type: "deleted", course: deletingCourse});
                setDeletingCourse(null);
            }} /> : null }

    </div>
}
