import { useContext, useEffect, useState } from 'react';
import { PopulatedDates, TrendMetricType, Trends } from './reportTypes';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import { TrendsGraph } from './TrendsGraph';
import { PatientContext } from '../../../contexts/PatientContext';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { useSearchParams } from 'react-router-dom';
import { ErrorBox, WarningBox } from '../../../components/Errors';
import Loading from '../../../components/Loading';
import SlicesGraph from './SliceGraph';



type MetricCheckboxProps = {
    id: TrendMetricType,
    label: string,
    colour: string,
    handleMetricChecked: (id: TrendMetricType, checked: boolean) => void,
    defaultChecked?: boolean
};

function MetricCheckbox(props: MetricCheckboxProps) {
    return <div>
        <input
            type="checkbox"
            id={`${props.id}Checkbox`}
            style={{accentColor: props.colour}}
            onChange={e => props.handleMetricChecked(props.id, e.target.checked)}
            defaultChecked={props.defaultChecked || false} />
        <label htmlFor={`${props.id}Checkbox`}>
            {props.label}
        </label>
    </div>;
}



type TrendsSectionProps = {
    trends?: Trends,
    populatedDates?: PopulatedDates | null
    // onSelectDays: (days: number) => void,
    // date: Date
};

export default function TrendsSection(props: TrendsSectionProps) {
    const [metricSelection, setMetricSelection] = useState<TrendMetricType[]>(["avgSleepHr", "totalSteps"]);
    const [showTrendlines, setShowTrendlines] = useState<boolean>(true);
    const [showSlices, setShowSlices] = useState<boolean>(false);
    const [trends, setTrends] = useState<Trends | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const paramDate = searchParams.get("date");
    const initialToDate = paramDate ? DateTime.fromISO(paramDate).toJSDate() : DateTime.now().startOf("day").toJSDate();
    const [toDate, setToDate] = useState<Date | null>(initialToDate);
    const [fromDate, setFromDate] = useState<Date | null>(new Date(initialToDate.getTime() - 14 * 86400000)); // 14 days prior
    const [sliceDay, setSliceDay] = useState<string | null>(null);

    const [error, setError] = useState<string | null>(null);
    const [warnings, setWarnings] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;

    const populatedDayClassName = (date: Date) => {
        const dateString = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
        if (!props.populatedDates) return "";
        if (props.populatedDates.full.includes(dateString)) {
            return "datepicker-populated-full";
        } else {
            return "";
        }
    }

    const getTrends = async () => {
        if (toDate == null || fromDate == null) return;
        setError(null);
        setWarnings([]);
        setLoading(true);
        setTrends(null);
        const from = DateTime.fromJSDate(fromDate).toFormat('yyyy-MM-dd');
        const to = DateTime.fromJSDate(toDate).toFormat('yyyy-MM-dd');
        try {
            const response = await sherpahGet<Trends>(`patients/${patient?.user_id}/trends?from=${from}&to=${to}&slices=${showSlices}`);
            setTrends(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (showSlices) getTrends();
    }, [showSlices]);

    useEffect(() => {
        getTrends();
    }, [toDate, fromDate]);

    const handleMetricChecked = (metricId: TrendMetricType, checked: boolean) => {
        if (checked) {
            setMetricSelection(selection => [...selection, metricId]);
        } else {
            setMetricSelection(selection => selection.filter(m => m != metricId));
        }
    };

    const previousDays = (n: number) => {
        setToDate(DateTime.now().toJSDate());
        setFromDate(DateTime.now().minus({days: n}).toJSDate());
    };

    let content;
    if (trends) {
        content = <>
            <TrendsGraph trends={trends!} metrics={metricSelection} dateRange={[fromDate!, toDate!]} showTrendlines={showTrendlines} onHover={dateStr => setSliceDay(dateStr)} />
            { trends.slices_metrics && showSlices ? <div style={{ width: "50%", margin: "0 auto" }}>
                <SlicesGraph metrics={trends.slices_metrics} showDate={sliceDay} totalDays={30} />
            </div> : null }

            <section>
                <div className="trend-metrics">
                    <div className="show-last">
                        <input type="checkbox" id="showSlices" defaultChecked={showSlices} onChange={e => setShowSlices(e.target.checked)}/>
                        <label htmlFor="showSlices">Show slices</label>
                    </div>
                    <div className="show-last">
                        <input type="checkbox" id="showTrendlines" defaultChecked={showTrendlines} onChange={e => setShowTrendlines(e.target.checked)}/>
                        <label htmlFor="showTrendlines">Show trendlines</label>
                    </div>
                    <hr className="show-last" />
                    <MetricCheckbox id="avgSleepHr" label="Average Sleeping Heart Rate" colour="#7ace4c" defaultChecked={true} handleMetricChecked={handleMetricChecked} />
                    <MetricCheckbox id="totalSteps" label="Total Daily Steps" colour="#00D1C5" defaultChecked={true} handleMetricChecked={handleMetricChecked} />
                    <MetricCheckbox id="sleepDuration" label="Sleep Duration" colour="#4C80CE" handleMetricChecked={handleMetricChecked} />
                    <MetricCheckbox id="treatments" label="Treatments" colour="#333" handleMetricChecked={handleMetricChecked} />
                    <MetricCheckbox id="sleepInstability" label="Sleep instability" colour="#C22A9F" handleMetricChecked={handleMetricChecked} />
                    <div></div>
                    <div>
                        <b>Physiological scores</b>
                        <div style={{paddingLeft: "16px"}}>
                            <MetricCheckbox id="scoresD" label="Low mood" colour="#E9922C" handleMetricChecked={handleMetricChecked} />
                            <MetricCheckbox id="scoresA" label="Mental stress" colour="#F5DD03" handleMetricChecked={handleMetricChecked} />
                        </div>
                    </div>
                    <div>
                        <b>DASS-21 results</b>
                        { trends.dass21s && trends.dass21s.timestamp.length > 1 ?
                            <div style={{paddingLeft: "16px"}}>
                                <MetricCheckbox id="dassD" label="Depression" colour="#A12CE9" handleMetricChecked={handleMetricChecked} />
                                <MetricCheckbox id="dassA" label="Anxiety" colour="#F423BA" handleMetricChecked={handleMetricChecked} />
                                <MetricCheckbox id="dassS" label="Stress" colour="#E43A3A" handleMetricChecked={handleMetricChecked} />
                            </div>
                            :
                            <div><i className="empty-message">No DASS-21 trends available.</i></div>
                        }
                    </div>
                </div>
            </section>
        </>;
    } else {
        // content = <i>No trends</i>;
    }

    return <div className="flex-col-gap-16">
    
        <div className="graph-controls-left">
            <b>Select date range: </b>
            <DatePicker
                className="trend-date-picker"
                selected={fromDate}
                startDate={fromDate}
                endDate={toDate}
                onChange={(dates: [Date|null, Date|null]) => {
                    setFromDate(dates[0]);
                    setToDate(dates[1]);
                }}
                dateFormat="d MMM yyyy"
                selectsRange
                monthsShown={2}
                dayClassName={populatedDayClassName} />
            <div style={{flex: 1}}></div>
        {/* </div>
        <div className="graph-controls-left"> */}
            <b>Go to: </b>
            <button className="w-button pagination" onClick={() => previousDays(14)}>Last 14 days</button>
            <button className="w-button pagination" onClick={() => previousDays(30)}>Last 30 days</button>
            <button className="w-button pagination" onClick={() => previousDays(90)}>Last 90 days</button>
            {/* { patient?.is_undergoing_tms ? <button className="w-button pagination" onClick={() => selectStartingTms()}>Since starting TMS course</button> : null }
            { patient?.has_completed_tms ? <button className="w-button pagination">Since finishing TMS course</button> : null } */}
        </div>
        
        { (error || warnings.length > 0) ? <div style={{display: "flex", gap: "8px", flexDirection: "column"}}>
                { error ? <ErrorBox>{error}</ErrorBox> : null }
                { warnings.map((warning, i) => <WarningBox key={i}>{warning}</WarningBox>) }
            </div> : null }

        { loading ? <Loading /> : null }

        { content }

    </div>;

}

