import { useContext, useState } from 'react';
import { Formik, Form, Field, FormikHelpers, ErrorMessage  } from 'formik';
import {
    MedicationCourse,
    TMSCourse
} from '../../../types';
import { DatePickerField } from '../../../components/DatePickerField';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { SharedContext } from '../../../contexts/SharedContext';
import { ErrorText } from '../../../components/Errors';
import * as Yup from 'yup';



type MedicationCourseModalProps = {
    medicationCourse: MedicationCourse | null,
    onClose: () => void,
    onAdd: (course: MedicationCourse) => void,
    onEdit: (course: MedicationCourse) => void
};

type MedicationFormValues = {
    medication_id: string,
    dosage: number,
    frequency: number,
    start: string,
    end: string | null
};

export function MedicationCourseModal(props: MedicationCourseModalProps) {
    const { medications } = useContext(SharedContext)!;
    const { sherpahPost, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [error, setError] = useState<string | null>(null);

    const onSubmit = async (values: MedicationFormValues, { setErrors }: FormikHelpers<MedicationFormValues>) => {
        setError(null);
        try {
            if (props.medicationCourse === null) {
                // posting new medication course
                const response = await sherpahPost<MedicationCourse>(`/patients/${patient!.user_id}/medication-courses`, values);
                props.onAdd(response.data);
            } else {
                // editing medication course
                const response = await sherpahPut<MedicationCourse>(`/patients/${patient!.user_id}/medication-courses/${props.medicationCourse.id}`, values);
                props.onEdit(response.data);
            }
            props.onClose();
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    };


    const initialValues: MedicationFormValues = {
        medication_id: props.medicationCourse?.medication.id || medications[0].id,
        start: props.medicationCourse?.start || "",
        end: props.medicationCourse?.end || null,
        dosage: props.medicationCourse?.dosage || 1,
        frequency: props.medicationCourse?.frequency || 1,
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Add medication course</div>
            <Formik 
                initialValues={initialValues}
                validationSchema={Yup.object<MedicationFormValues>({
                    start: Yup.date().label("Course start").required(),
                    end: Yup.date().label("Course end").nullable()
                            .min(Yup.ref("start"), "Course end must be after course start."),
                    dosage: Yup.number().label("Dosage").required().min(0),
                    frequency: Yup.number().label("Times per day").required().min(1)
                })}
                onSubmit={onSubmit}>
                {({isSubmitting}) => (
                    <Form>
                        <div className="form-fields">
                            <div>
                                <label htmlFor="medication_id">Medication</label>
                                <Field as="select" name="medication_id">
                                    {medications.map(m => <option value={m.id} key={m.id}>{m.name}</option>)}
                                </Field>
                            </div>
                            <div className="form-field-half">
                                <label htmlFor="start">Course Start</label>
                                <DatePickerField name="start" />
                                <ErrorMessage name="start" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label htmlFor="end">Course End</label>
                                <DatePickerField name="end" placeholderText="(leave blank for ongoing)" />
                                <ErrorMessage name="end" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label htmlFor="dosage">Dosage (mg)</label>
                                <Field type="text" id="dosage" name="dosage" min="0" />
                                <ErrorMessage name="dosage" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label htmlFor="frequency">Times per day</label>
                                <Field type="text" id="frequency" name="frequency" min="1" />
                                <ErrorMessage name="frequency" component={ErrorText} />
                            </div>
                        </div>

                        {error ? <ErrorText>{error}</ErrorText> : null}
                        
                        {isSubmitting ? 
                            <div className="modal-actions">
                                <button type="button" className="w-button" disabled>Saving...</button>
                            </div>
                            :
                            <div className="modal-actions">
                                <button type="submit" className="w-button">Save</button>
                                <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    </div>
}


type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: () => void,
    course: MedicationCourse
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        const courseResource = `medication-courses/${props.course.id}`;
        try {
            await sherpahDelete(`/patients/${patient!.user_id}/${courseResource}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this course of treatment?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                "Deleting..."
            :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}
