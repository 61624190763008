import Badge from "./app-store-badge.svg";

type Props = {
    href: string;
    alt: string;
}

export function AppStoreBadge(props: Props) {
    return <a href={props.href} target="_blank">
        <img height={50} alt={props.alt} src={Badge} />
    </a>
}

