import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { toast } from 'react-toastify';
import { ErrorText } from '../../components/Errors';


export default function FreeAccess() {
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const navigate = useNavigate();

    const onSubmit = async () => {
        setError(null);
        setLoading(true);
        try {
            const response = await sherpahPost(`/patients/free-access`, {email});
            toast('Patient has been given free access.', DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    return <main className="narrow">
        <div className="heading-wrapper">
            <h1>Give patient free access</h1>
        </div>
        <div className="rounded-container">
            <p>Input patient's email address to allow them free access.</p>

            { error ? <ErrorText>{error}</ErrorText> : null }

            <div>
                <label>Email</label>
                <input type="email" onChange={e => setEmail(e.target.value)} />
            </div>

            <br/>

                <button type="submit" className="w-button" disabled={loading} onClick={onSubmit}>Give free access</button>

        </div>
    </main>

}