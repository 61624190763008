import React from "react"
import './Text.scss'

export type TextProps = {
    size?: 'title' | 'body'
    variant?: 'info' | 'warning' | 'error'

} & React.PropsWithChildren

export function Text(props: TextProps) {
    return <p className={`text text-${props.size ?? 'body'} text-${props.variant ?? 'info'}`} >{props.children}</p>
}
