import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';
import { DEFAULT_TOAST_SETTINGS, formatDatetime } from '../../utils';
import { toast } from 'react-toastify';
import { ErrorText } from '../../components/Errors';


type Verification = {
    link: string,
    sent_at: string,
    accepted_at: string,
    is_expired: boolean
};

export default function VerificationLink() {
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>("");
    const [verification, setVerification] = useState<Verification | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const navigate = useNavigate();

    const onSubmit = async () => {
        setError(null);
        setLoading(true);
        try {
            const response = await sherpahPost<Verification>(`/patients/verification-link`, {email});
            setVerification(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    let verificationMessage;
    if (verification?.accepted_at) {
        verificationMessage = <b className="success-text">Patient has already verified on {formatDatetime(verification.accepted_at)}.</b>;
    } else if (verification?.is_expired) {
        verificationMessage = <b className="danger-text">Verification expired.</b>;
    }

    return <main className="narrow">
        <div className="heading-wrapper">
            <h1>Retrieve patient verification</h1>
        </div>
        <div className="rounded-container">
            <p>Input patient's email address to view their verification link.</p>

            { error ? <ErrorText>{error}</ErrorText> : null }

            <div>
                <label>Email</label>
                <input type="email" onChange={e => setEmail(e.target.value)} />
            </div>

            <br/>

            <button type="submit" className="w-button" disabled={loading} onClick={onSubmit}>Retrieve verification</button>
            
            <br/>
            <br/>
            { verification ? 
                <div>
                    { verificationMessage }<br/>
                    <b>Link:</b> <a className="link" href={verification.link}>{verification.link}</a><br/>
                    <b>Sent at:</b> {formatDatetime(verification.sent_at)}<br/>
                </div>
            : null }

        </div>
    </main>

}