import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import "./css/app.scss";
import { AxiosProvider } from "./contexts/AxiosContext";
import { CognitoProvider } from "./contexts/CognitoContext";
import { AppProvider } from "./contexts/AppContext";
import { Routes } from "./pages/Routes";


export default function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const authContext = useContext(AuthContext)!;

    useEffect(() => {
        if (location.pathname == "/") {
            const user = authContext.authState.user;
            if (user) {
                if (user.is_doctor || user.is_operator) {
                    navigate("/patients");
                } else if (user.is_patient) {
                    navigate("/patients/" + user.id);
                } else if (user.is_superuser) {
                    navigate("/admin");
                }
            } else {
                navigate(Routes.anon.accounts.login2);
            }
        }
    }, [location, authContext.authState.user]);

    return <AxiosProvider>
        <Outlet />
    </AxiosProvider>;
}
