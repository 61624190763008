import React from "react";
import ReactDOM from "react-dom/client";
import { Link, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useRouteError } from "react-router-dom";

import App from "./App";
import Login from "./pages/unauthenticated/Login";
import CognitoLogin from "./pages/unauthenticated/CognitoLogin";
import Doctor from "./pages/doctors/Doctor";
import Overview from "./pages/patients/overview/Overview";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import PatientEdit from "./pages/patients/Edit";
import Report from "./pages/patients/report/Report";
import Questionnaires from "./pages/patients/questionnaires/Questionnaires";
import DASS21 from "./pages/patients/questionnaires/DASS21";
import BDI from "./pages/patients/questionnaires/BDI";
import HDRS from "./pages/patients/questionnaires/HDRS";
import { ErrorBox } from "./components/Errors";
import ResetPassword from "./pages/unauthenticated/ResetPassword";
import ResetPasswordForm from "./pages/unauthenticated/ResetPasswordForm";
import AlgorithmData from "./pages/admin/AlgorithmData";
import AddDoctor from "./pages/admin/AddDoctor";
import { AuthProvider } from "./contexts/AuthContext";
import Invitation from "./pages/doctors/Invitation";
import Operators from "./pages/doctors/Operators";
import FreeAccess from "./pages/admin/FreeAccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import VerificationLink from "./pages/admin/VerificationLink";
import OperatorForm from "./pages/doctors/OperatorForm";
import EditOperator from "./pages/doctors/EditOperator";
import Redirect from "./pages/unauthenticated/Redirect";
import DeletingData from "./pages/DeletingData";
import AdminHome from "./pages/admin/Admin";
import Access from "./access";
import SignupVerification from "./pages/unauthenticated/SignupVerification";
import Payment from "./pages/unauthenticated/Payment";
import CancelPayment from "./pages/unauthenticated/CancelPayment";
import FitbitRedirect from "./pages/patients/FitbitRedirect";
import { AppProvider } from "./contexts/AppContext";
import NewMetrics from "./pages/admin/NewMetrics";
import DeletedItems from "./pages/doctors/DeletedItems";
import Algorithm from "./pages/admin/Algorithm";
import TCsSherpah from "./pages/TCsSherpah";
import TCsHeartschool from "./pages/TCsHeartschool";
import AddManagedPatient from "./pages/doctors/AddManagedPatient";
import DassReport from "./pages/doctors/DassReport";
import Settings from "./pages/doctors/Settings";
import SleepDetection from "./pages/admin/SleepDetection";
import { CognitoProvider } from "./contexts/CognitoContext";
import { FeatureProvider } from "./contexts/FeatureContext";


function ErrorBoundary() {
    let error: any = useRouteError();
    console.error(error);

    if (error.status == 404) {
        return <div style={{ padding: 16 }}>
            <ErrorBox>
                This page does not exist! <Link to="/" className="link">Go to homepage.</Link>
            </ErrorBox>
        </div>;
    }

    return <div style={{ padding: 16 }}>
        <ErrorBox>
            An error occured while rendering the page. <Link to="/" className="link">Go to homepage.</Link>
        </ErrorBox>
    </div>;
}


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />} errorElement={<ErrorBoundary />}>
            {/* PUBLIC */}
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<TCsSherpah />} />
            <Route path="terms-and-conditions/heartschool" element={<TCsHeartschool />} />
            <Route path="deleting-data" element={<DeletingData />} />
            <Route path="redirect" element={<Redirect />} />
            <Route path="patients/fitbit-redirect" element={<FitbitRedirect />} />
            <Route path="signup/verify-payment" element={<Payment />} />
            <Route path="signup/verify-payment/:patientId" element={<Payment />} />
            <Route path="signup/cancel-payment" element={<CancelPayment />} />

            {/* UNAUTHENTICATED */}
            <Route element={<Access.Unauthenticated />}>
                <Route path="invitations/:code" element={<Invitation />} />
                <Route path="signup/verification/:code" element={<SignupVerification />} />
                <Route path="accounts/login" element={<Login />} />
                <Route path="accounts/login2" element={<CognitoLogin />} />
                <Route path="accounts/reset-password" element={<ResetPassword />} />
                <Route path="accounts/reset-password/:token" element={<ResetPasswordForm />} />
            </Route>

            {/* AUTHENTICATED */}
            <Route element={<Access.Authenticated />}>

                {/* DOCTOR ONLY */}
                <Route element={<Access.DoctorOnly />}>
                    <Route path="operators" element={<Operators />} />
                    <Route path="operators/add" element={<OperatorForm />} />
                    <Route path="managed-patients/add" element={<AddManagedPatient />} />
                    <Route path="algorithm-data" element={<AlgorithmData />} />
                    <Route path="sleep-detection" element={<SleepDetection />} />
                    <Route path="operators/:operatorId/edit" element={<EditOperator />} />
                    <Route path="deleted-items" element={<DeletedItems />} />
                    <Route path="dass-report" element={<DassReport />} />
                </Route>

                {/* DOCTOR & OPERATOR */}
                <Route element={<Access.DoctorAndOperators />}>
                    <Route path="patients" element={<Doctor />} />
                    <Route path="settings" element={<Settings />} />
                </Route>

                {/* PATIENT, PATIENT DOCTOR, PATIENT OPERATOR */}
                <Route path="patients/:patientId" element={<Access.Patient />}>
                    <Route path="" element={<Overview />} />
                    <Route path="edit" element={<PatientEdit />} />
                    <Route path="report" element={<Report />} />
                    <Route path="questionnaires" element={<Questionnaires />} />
                    <Route path="questionnaires/dass-21" element={<DASS21 />} />
                    <Route path="questionnaires/dass-21/:id" element={<DASS21 />} />
                    <Route path="questionnaires/bdi" element={<BDI />} />
                    <Route path="questionnaires/bdi/:id" element={<BDI />} />
                    <Route path="questionnaires/hdrs" element={<HDRS />} />
                    <Route path="questionnaires/hdrs/:id" element={<HDRS />} />
                </Route>

                {/* ADMIN */}
                <Route path="admin" element={<Access.Admin />} >
                    <Route path="" element={<AdminHome />} />
                    <Route path="algorithm" element={<Algorithm />} />
                    <Route path="add-doctor" element={<AddDoctor />} />
                    <Route path="free-access" element={<FreeAccess />} />
                    <Route path="verification-link" element={<VerificationLink />} />
                    <Route path="new-metrics" element={<NewMetrics />} />
                </Route>
            </Route>
        </Route>
    )
);


ReactDOM.createRoot(document.getElementById("root")!).render(
    // TODO: reenable strict mode to deal with jwt token refreshes
    // https://react.dev/learn/synchronizing-with-effects#fetching-data
    // <React.StrictMode>
    <AppProvider>
        <CognitoProvider>
            <AuthProvider>
                <FeatureProvider>
                    <RouterProvider router={router} />
                    <ToastContainer />
                </FeatureProvider>
            </AuthProvider>
        </CognitoProvider>
    </AppProvider>
    // </React.StrictMode>
);