export const DASS_QUESTIONS = [
	"I found it hard to wind down.",
	"I was aware of dryness of my mouth.",
	"I couldn’t seem to experience any positive feeling at all.",
	"I experienced breathing difficulty (eg. excessively rapid breathing, breathlessness in the absence of physical exertion).",
	"I found it difficult to work up the initiative to do things.",
	"I tended to over-react to situations.",
	"I experienced trembling (eg. in the hands).",
	"I felt that I was using a lot of nervous energy.",
	"I was worried about situations in which I might panic and make a fool of myself.",
	"I felt that I had nothing to look forward to.",
	"I found myself getting agitated.",
	"I found it difficult to relax.",
	"I felt down-hearted and blue.",
	"I was intolerant of anything that kept me from getting on with what I was doing.",
	"I felt I was close to panic.",
	"I was unable to become enthusiastic about anything.",
	"I felt I wasn’t worth much as a person.",
	"I felt that I was rather touchy.",
	"I was aware of the action of my heart in the absence of physical exertion (eg, sense of heart rate increase, heart missing a beat).",
	"I felt scared without any good reason.",
	"I felt that life was meaningless.",
];

export const BDI_QUESTIONS = [
    [
        "I do not feel sad",
        "I feel sad",
        "I am sad all the time and I can't snap out of it",
        "I am so sad and unhappy that I can't stand it",
    ], [
        "I am not particularly discouraged about the future",
        "I feel discouraged about the future",
        "I feel I have nothing to look forward to",
        "I feel the future is hopeless and that things cannot improve",
    ], [
        "I do not feel like a failure",
        "I feel I have failed more than the average person",
        "As I look back on my life, all I can see is a lot of failures",
        "I feel I am a complete failure as a person", 
    ], [
        "I get as much satisfaction out of things as I used to",
        "I don't enjoy things the way I used to",
        "I don't get real satisfaction out of anything anymore",
        "I am dissatisfied or bored with everything", 
    ], [
        "I don't feel particularly guilty",
        "I feel guilty a good part of the time",
        "I feel quite guilty most of the time",
        "I feel guilty all of the time",
    ], [
        "I don't feel I am being punished",
        "I feel I may be punished",
        "I expect to be punished",
        "I feel I am being punished", 
    ], [
        "I don't feel disappointed in myself",
        "I am disappointed in myself",
        "I am disgusted with myself",
        "I hate myself",  
    ], [
        "I don't feel I am any worse than anybody else",
        "I am critical of myself for my weaknesses or mistakes",
        "I blame myself all the time for my faults",
        "I blame myself for everything bad that happens", 
    ], [
        "I don't have any thoughts of killing myself",
        "I have thoughts of killing myself, but I would not carry them out",
        "I would like to kill myself",
        "I would kill myself if I had the chance",    
    ], [
        "I don't cry any more than usual",
        "I cry more now than I used to",
        "I cry all the time now",
        "I used to be able to cry, but now I can't cry even though I want to",  
    ], [
        "I am no more irritated by things than I ever was",
        "I am slightly more irritated now than usual",
        "I am quite annoyed or irritated a good deal of the time",
        "I feel irritated all the time",
    ], [
        "I have not lost interest in other people",
        "I am less interested in other people than I used to be",
        "I have lost most of my interest in other people",
        "I have lost all of my interest in other people",
    ], [
        "I make decisions about as well as I ever could",
        "I put off making decisions more than I used to",
        "I have greater difficulty in making decisions more than I used to",
        "I can't make decisions at all anymore",
    ], [
        "I don't feel that I look any worse than I used to",
        "I am worried that I am looking old or unattractive",
        "I feel there are permanent changes in my appearance that make me look unattractive.",
        "I believe that I look ugly",
    ], [
        "I can work about as well as before",
        "It takes an extra effort to get started at doing something",
        "I have to push myself very hard to do anything",
        "I can't do any work at all",
    ], [
        "I can sleep as well as usual",
        "I don't sleep as well as I used to",
        "I wake up 1-2 hours earlier than usual and find it hard to get back to sleep",
        "I wake up several hours earlier than I used to and cannot get back to sleep",
    ], [
        "I don't get more tired than usual",
        "I get tired more easily than I used to",
        "I get tired from doing almost anything",
        "I am too tired to do anything",
    ], [
        "My appetite is no worse than usual",
        "My appetite is not as good as it used to be",
        "My appetite is much worse now",
        "I have no appetite at all anymore",
    ], [
        "I haven't lost much weight, if any, lately",
        "I have lost more than five pounds",
        "I have lost more than ten pounds",
        "I have lost more than fifteen pounds",
    ], [
        "I am no more worried about my health than usual",
        "I am worried about physical problems like aches, pains, upset stomach, or constipation",
        "I am very worried about physical problems and it's hard to think of much else",
        "I am so worried about my physical problems that I cannot think of anything else",
    ], [

        "I have not noticed any recent change in my interest in sex",
        "I am less interested in sex than I used to be",
        "I have almost no interest in sex",
        "I have lost interest in sex completely",
    ]
];

export type HDRSQuestion = {
    number: string,
    title: string,
    description?: string,
    options: string[]
}

export const HDRS_QUESTIONS: HDRSQuestion[] = [
    {
        number: "1",
        title: "Depressed mood",
        description: "sadness, hopeless, helpless, worthless",
        options: [
            "Absent",
            "These feeling states indicated only on questioning",
            "These feeling states spontaneously reported verbally",
            "Communicates feeling states non-verbally, i.e. through facial expression, posture, voice and tendency to weep.",
            "Patient reports virtually only these feeling states in his/her spontaneous verbal and non-verbal communication.",
        ],
    },
    {
        number: "2",
        title: "Feelings of guilt",
        options: [
            "Absent",
            "Self reproach, feels he/she has let people down.",
            "Ideas of guilt or rumination over past errors or sinful deeds.",
            "Present illness is a punishment. Delusions of guilt.",
            "Hears accusatory or denunciatory voices and/or experiences threatening visual hallucinations.",
        ],
    },
    {
        number: "3",
        title: "Suicide",
        options: [
            "Absent",
            "Feels life is not worth living.",
            "Wishes he/she were dead or any thoughts of possible death to self.",
            "Ideas or gestures of suicide.",
            "Attempts at suicide (any serious attempt select this).",
        ],
    },
    {
        number: "4",
        title: "Insomnia: Early in the night",
        options: [
            "No difficulty falling asleep.",
            "Complains of occasional difficulty falling asleep, i.e. more than 1/2 hour.",
            "Complains of nightly difficulty falling asleep.",
        ],
    },
    {
        number: "5",
        title: "Insomnia: Middle of the night",
        options: [
            "No difficulty.",
            "Patient complains of being restless and disturbed during the night.",
            "Waking during the night – any getting out of bed select this.",
        ],
    },
    {
        number: "6",
        title: "Insomnia: Early hours of the morning",
        options: [
            "No difficulty.",
            "Waking in early hours of the morning but goes back to sleep.",
            "Unable to fall asleep again if he/she gets out of bed.",
        ],
    },
    {
        number: "7",
        title: "Work and activities",
        options: [
            "No difficulty.",
            "Thoughts and feelings of incapacity, fatigue or weakness related to activities, work or hobbies.",
            "Loss of interest in activity, hobbies or work – either directly reported by the patient or indirect in listlessness, indecision and vacillation (feels he/she has to push self to work or activities).",
            "Decrease in actual time spent in activities or decrease in productivity. Select this if the patient does not spend at least three hours a day in activities (job or hobbies) excluding routine chores.",
            "Stopped working because of present illness. Select this if patient engages in no activities except routine chores, or if patient fails to perform routine chores unassisted.",
        ],
    },
    {
        number: "8",
        title: "Retardation",
        description: "slowness of thought and speech, impaired ability to concentrate, decreased motor activity",
        options: [
            "Normal speech and thought.",
            "Slight retardation during the interview.",
            "Obvious retardation during the interview.",
            "Interview difficult.",
            "Complete stupor.",
        ],
    },
    {
        number: "9",
        title: "Agitation",
        options: [
            "None.",
            "Fidgetiness.",
            "Playing with hands, hair, etc.",
            "Moving about, can’t sit still.",
            "Hand wringing, nail biting, hair-pulling, biting of lips.",
        ],
    },
    {
        number: "10",
        title: "Anxiety psychic",
        options: [
            "No difficulty.",
            "Subjective tension and irritability.",
            "Worrying about minor matters.",
            "Apprehensive attitude apparent in face or speech.",
            "Fears expressed without questioning.",
        ],
    },
    {
        number: "11",
        title: "Anxiety somatic (physiological concomitants of anxiety)",
        options: [
            "Absent.",
            "Mild.",
            "Moderate.",
            "Severe.",
            "Incapacitating.",
        ],
    },
    {
        number: "12",
        title: "Somatic symptoms gastro-intestinal",
        options: [
            "None.",
            "Loss of appetite but eating without staff encouragement. Heavy feelings in abdomen.",
            "Difficulty eating without staff urging. Requests or requires laxatives or medication for bowels or medication for gastro-intestinal symptoms.",
        ],
    },
    {
        number: "13",
        title: "General somatic symptoms",
        options: [
            "None.",
            "Heaviness in limbs, back or head. Backaches, headaches, muscle aches. Loss of energy and fatigability.",
            "Any clear-cut symptoms.",
        ],
    },
    {
        number: "14",
        title: "Genital symptoms (symptoms such as loss of libido, menstrual disturbances)",
        options: [
            "Absent.",
            "Mild.",
            "Severe.",
        ],
    },
    {
        number: "15",
        title: "Hypochondriasis",
        options: [
            "Not present.",
            "Self-absorption (bodily).",
            "Preoccupation with health.",
            "Frequent complaints, requests for help, etc.",
            "Hypochondriacal delusions.",
        ],
    },
    {
        number: "16a",
        title: "Loss of weight",
        description: "according to the patient",
        options: [
            "No weight loss",
            "Probable weight loss associated with present illness",
            "Definite (according to patient) weight loss",
            "Not assessed",
        ],
    },
    {
        number: "16b",
        title: "Loss of weight",
        description: "according to weekly measurements",
        options: [
            "Less than 1 lb weight loss in week",
            "Greater than 1 lb weight loss in a week",
            "Greater than 2 lb weight loss in week",
            "Not assessed",
        ],
    },
    {
        number: "17",
        title: "Insight",
        options: [
            "Acknowledges being depressed and ill.",
            "Acknowledges illness but attributes cause to bad food, climate, overwork, virus, need for rest, etc.",
            "Denies being ill at all.",
        ],
    },
]
