import { useField, useFormikContext } from "formik";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { DateTime } from 'luxon';

import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../utils";

type DatePickerFieldProps = {name: string} & Omit<ReactDatePickerProps, "onChange">;

export const DatePickerField = (props: DatePickerFieldProps) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField<string>(props.name);
    const { value, ...fieldWithoutValue } = field;
    return (
        <DatePicker
            {...fieldWithoutValue}
            dateFormat="d MMM yyyy"
            {...props}
            selected={field.value ? new Date(field.value) : null}
            onChange={(date: Date | null) => {
                setFieldValue(field.name, date?.toISOString().substring(0,10) || null);
            }}
            className="input"
        />
    );
};