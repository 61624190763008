import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { FormEvent, useContext, useEffect } from "react";
import { useState } from "react"
import { CognitoContext, appRedirectUri, AccountStatusResponse } from "../../contexts/CognitoContext";
import { Form, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../ui/base/Button";
import { AxiosContext } from "../../contexts/AxiosContext";
import { logError } from "../../utils";
import { AuthContext, AuthSource } from "../../contexts/AuthContext";
import { AppContext, AppState } from "../../contexts/AppContext";
import { Card } from "../../ui/base/Card";
import './CognitoLogin.scss';
import { Text } from "../../ui/base/Text";
import { CentreLayout } from "../../ui/layout/Centre";
import { useScreenDetector } from "../../hooks/Screen";
import { SherpahToken } from "./Login";
import { AxiosError } from "axios";
import { UnauthContainer } from "./UnauthContainer";
import { ErrorText } from "../../components/Errors";

enum SignUpProcess {
    Email,
    Verify,
    LoggedIn,
    Forgot,
    AppSignedIn,
    CognitoTransferPassword,
    Password,
    CreateAgree,
    CreateFirstLastName,
    CreatePassword,
    NewPasswordRequired,
    TotpChallenge
}

const fromAppGlobal = window.location.search.includes("fromApp");

type Props = {
    fromApp?: boolean;
}

export default function CognitoLogin({ fromApp = fromAppGlobal }: Props) {
    const [state, setState] = useState<SignUpProcess>(SignUpProcess.Email);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [temporaryPassword, setTemporaryPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [agreesToPp, setAgreesToPp] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verifyCode, setVerifyCode] = useState('');
    const [message, setMessage] = useState('');
    const [totpCode, setTotpCode] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [sessionUserAttributes, setSessionUserAttributes] = useState({});
    const navigate = useNavigate();
    const cognitoContext = useContext(CognitoContext);
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);
    const appContext = useContext(AppContext);
    const screen = useScreenDetector();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const { setUser: setCognitoUser, setSession } = cognitoContext;
    const { userPool, initState } = appContext;

    if (!userPool || !setCognitoUser || !setSession) {
        let inner = <Text>Loading...</Text>

        switch (initState) {
            case AppState.none:
            case AppState.fetching:
                break;

            default:
            case AppState.initialised:
                inner = <ErrorText>Unable to initialise the application</ErrorText>;
                logError("erroneous cognito state & pool", initState);
                break;
        }
        return <UnauthContainer>
            {inner}
        </UnauthContainer>
    }

    const onSignIn = (session: CognitoUserSession) => {
        setLoading(true);
        setSession(session);

        axiosContext?.publicAxios.post("/accounts/cognito-handshake", {
            AccessToken: session.getAccessToken().getJwtToken(),
            Source: fromApp ? "app" : "web",
            Clinic: params.get('clinic'),
            FirstName: firstName,
            LastName: lastName
        }).then(res => {
            const user = res.data.user;

            if (fromApp) {
                // const refreshToken = cognitoContext.session?.getRefreshToken().getToken();
                // const accessToken = cognitoContext.session?.getAccessToken().getJwtToken();
                // window.location.replace(`${window.location.origin}${window.location.pathname}?refresh=${refreshToken}&access=${accessToken}&clinic=${params.get('clinic')}&fromApp`);
                setState(SignUpProcess.AppSignedIn);
                setMessage('Successfully signed in!')
            } else {
                authContext?.setAuthState({
                    user,
                    authenticated: true,
                    authSource: AuthSource.cognito,
                    cognitoSession: session
                });
                setMessage('Successfully signed in!')
                navigate("/patients")
            }
        }).catch(err => {
            logError(err);
            let message = 'Failed to sign in, please try again later';
            if (err instanceof AxiosError && err.response?.data.error) {
                message = err.response.data.error;
            }
            setError(message)
            setState(SignUpProcess.Email);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onBackToApp = (e: FormEvent) => {
        const refreshToken = cognitoContext.session?.getRefreshToken().getToken();
        const accessToken = cognitoContext.session?.getAccessToken().getJwtToken();

        const message = JSON.stringify({
            "schema": "auth",
            "payload": {
                "refresh": refreshToken,
                "access": accessToken
            }
        })

        // window.postMessage(message)
        window.location.search = `?refresh=${refreshToken}&access=${accessToken}`
        console.log({ message })
    }

    const onAgreeToPp = (e: FormEvent) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (!agreesToPp) {
            setError("Please read and agree to the Privacy Policy");
            return;
        }

        setState(SignUpProcess.CreateFirstLastName);
    }

    const onFirstLastName = (e: FormEvent) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (!firstName || !lastName) {
            setError('Please enter your name')
            return;
        }

        setState(SignUpProcess.CreatePassword);
    }

    const onCognitoFailure = (err: any) => {
        logError(err);

        if (err instanceof Error && err.name === "UserNotConfirmedException") {

            cognitoContext.user?.resendConfirmationCode((err, result) => {
                if (result) {
                    setState(SignUpProcess.Verify);
                    setLoading(false);
                    setMessage('A verification code has been sent to your ' + result.CodeDeliveryDetails.AttributeName);
                } else {
                    setError("Failed to verification code")
                }
            })
        }
        else if (err instanceof Error && err.name === "UserNotFoundException") {
            setError("Email address not found. Please check your e-mail or sign up instead");
            setLoading(false);
        }
        else if (err instanceof Error && err.name === "NotAuthorizedException") {
            setError(err.message);
            setLoading(false);
        }
        else {
            setError(err instanceof Error ? err.message : "An unknown error occured. Please try again later");
            setLoading(false);
        }
    }

    function onTotpRequired(challengeName: string, challengeParams: { FRIENDLY_DEVICE_NAME?: string }) {
        console.log({ challengeName, challengeParams });
        setTotpCode('');
        setLoading(false);
        setError('');
        setMessage('');
        setState(SignUpProcess.TotpChallenge);
    }

    function onTotpChallengeSubmit(e: FormEvent) {
        e.preventDefault();

        setLoading(true);
        setError('');
        setMessage('');

        cognitoContext.user?.sendMFACode(totpCode, {
            onSuccess: onSignIn,
            onFailure: onCognitoFailure
        }, 'SOFTWARE_TOKEN_MFA')
    }

    function authenticateUser() {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool!
        })

        setCognitoUser!(user);

        user.authenticateUser(
            new AuthenticationDetails({
                Username: email,
                Password: password,
            }),
            {
                onSuccess: onSignIn,
                onFailure: onCognitoFailure,
                totpRequired: onTotpRequired,
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    console.log({ userAttributes, requiredAttributes })
                    delete userAttributes.email;
                    delete userAttributes.email_verified;
                    setSessionUserAttributes(userAttributes);
                    setState(SignUpProcess.NewPasswordRequired);
                    setTemporaryPassword(password);
                    setPassword('');
                    setConfirmPassword('');
                    setLoading(false);
                },
            }
        )
    }

    const onSubmitPassword = async (e: FormEvent) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (state == SignUpProcess.CreatePassword) {
            if (password !== confirmPassword) {
                setError(`Passwords do not match`)
                return;
            }
        }

        if (state == SignUpProcess.NewPasswordRequired) {
            if (password !== confirmPassword) {
                setError(`Passwords do not match`)
                return;
            }
            if (!temporaryPassword) {
                setError(`Please enter your temporary password`)
                return;
            }
        }

        setLoading(true);

        switch (state) {
            case SignUpProcess.CognitoTransferPassword:

                try {
                    await axiosContext!.publicAxios.post<{}>('/accounts/cognito-transfer', {
                        email, password
                    })

                    authenticateUser();

                } catch (err) {
                    setLoading(false);
                    if (err instanceof AxiosError) {
                        if (err.response?.status === 400) {
                            setError('Password is incorrect, please try again.')
                        } else {
                            setError('An unknown error occurred during login, please try again later.')
                        }
                    } else {
                        setError('An unknown error occurred during login, please try again later.')
                    }
                }

                break;

            case SignUpProcess.Password:
                authenticateUser();
                break;

            case SignUpProcess.NewPasswordRequired:
                cognitoContext.user?.completeNewPasswordChallenge(password, sessionUserAttributes, {
                    onSuccess: onSignIn,
                    onFailure: onCognitoFailure
                });
                break;

            default:
                const attrs = [
                    new CognitoUserAttribute({
                        Name: 'email',
                        Value: email
                    }),
                    new CognitoUserAttribute({
                        Name: 'given_name',
                        Value: firstName
                    }),
                    new CognitoUserAttribute({
                        Name: 'family_name',
                        Value: lastName
                    })
                ]

                userPool.signUp(email.toLowerCase(), password, attrs, [], (err, result) => {
                    setLoading(false);

                    if (err?.name === "UsernameExistsException") {
                        // setError("User account already exists");
                        authenticateUser();
                        return;
                    }

                    if (err?.name === "InvalidParameterException") {
                        setError(`Password does not meet complexity requirements`)
                        return;
                    }

                    if (result) {
                        if (!result.userConfirmed) {
                            setState(SignUpProcess.Verify);
                            setMessage('A verification code has been sent to your ' + result.codeDeliveryDetails.AttributeName);
                        }
                    }
                });
        }
    }

    const onVerify = (e: FormEvent) => {
        e.preventDefault();

        setMessage('');
        setError('');
        setLoading(true);

        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        })

        user.confirmRegistration(verifyCode, false, (err, result) => {
            if (err) {
                logError(err);
                setMessage((err && err.message) || `Error confirming registration, please try again`)
                setLoading(false);
            }
            if (result) {
                user.authenticateUser(
                    new AuthenticationDetails({
                        Username: email,
                        Password: password,
                    }),
                    {
                        onFailure: (err) => {
                            setError("Error signing in, please try again")
                            logError(err);
                            setLoading(false);
                        },
                        onSuccess: onSignIn
                    }
                )
            }
        })
    }

    const onForgotVerify = (e: FormEvent) => {
        e.preventDefault();

        setMessage('');
        setError('');

        if (password !== confirmPassword) {
            setError(`Passwords do not match`)
            return;
        }

        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        })

        setLoading(true);
        user.confirmPassword(verifyCode, password,
            {
                onFailure: (err) => {
                    setError("Error confirming verification code. Please try again")
                    logError(err);
                    setLoading(false);
                },
                onSuccess: (success) => {
                    setMessage('New password successfully set. Please sign in')
                    setPassword('');
                    setConfirmPassword('');
                    setState(SignUpProcess.Email)
                    setLoading(false);
                }
            }
        )
    }

    const onEmailContinue = (e: FormEvent) => {
        e.preventDefault();

        setError('');
        setMessage('');

        if (axiosContext) {
            setLoading(true);
            axiosContext.publicAxios.post<AccountStatusResponse>('/accounts/status', {
                email
            })
                .then(async (res) => {
                    if (res.data.cognito_exists) {
                        if (res.data.exists) {
                            setState(SignUpProcess.Password);
                        } else {
                            setMessage('Welcome! To continue with signup please enter your name.')
                            setState(SignUpProcess.CreateFirstLastName);
                        }

                    } else if (res.data.exists) {

                        setState(SignUpProcess.CognitoTransferPassword);

                    } else {
                        setMessage('Welcome! To continue with signup please enter your name.')
                        setState(SignUpProcess.CreateFirstLastName);
                    }
                })
                .catch(err => {
                    logError(err);
                    setError('Server error trying to login, please try again later');
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            setError('Error trying to login, please try refreshing your browser');
        }
    }

    async function onForgotPassword(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();

        setMessage('');
        setError('');

        if (!email) {
            setError('Please enter your email to continue');
            return;
        }

        setPassword('');
        setConfirmPassword('');
        setTemporaryPassword('');

        try {
            const res = await axiosContext!.publicAxios.post<AccountStatusResponse>('/accounts/status', {
                email
            })

            if (res.status === 200) {
                if (!(res.data.exists || res.data.cognito_exists)) {
                    setError('No user with that email exists')
                    return;
                }

                setLoading(true);

                if (res.data.exists && !res.data.cognito_exists) {
                    // Creates the cognito account with temporary password, but we just re-initiate
                    // a reset for simplicities' sake
                    await axiosContext!.publicAxios.post<{}>('/accounts/cognito-forgot', {
                        email
                    })
                }

                const user = new CognitoUser({
                    Username: email,
                    Pool: userPool!
                })

                user.forgotPassword({
                    onSuccess: (data) => {
                        setMessage("Verification code sent to your email");
                        setLoading(false);
                        setState(SignUpProcess.Forgot);
                    },
                    onFailure: (err) => {
                        setError(`Failed to reset password. Please check your email`)
                        setLoading(false);
                    },
                })
            }
        }
        catch (err) {
            setError(`Failed to initiate password reset: ${err}`)
        }
    }

    function renderInner() {

        const forgotPassword = <Link to="#" className="link" style={{ textAlign: "center" }} onClick={onForgotPassword}>Forgot password?</Link>;

        switch (state) {
            default:

            case SignUpProcess.Email:
                return <form className="form">
                    <div className="form-question">
                        <label htmlFor="email">Email address</label>
                        <input type="email"
                            value={email}
                            id="email"
                            onChange={e => setEmail(e.target.value)} />
                    </div>

                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}

                    <div className="login2-actions">
                        <Button onClick={onEmailContinue} disabled={loading} text="Continue" />
                        {forgotPassword}
                    </div>
                </form>

            case SignUpProcess.CognitoTransferPassword:
            // fall through
            case SignUpProcess.Password:
                return <form className="form">
                    { email ? 
                    <div className="form-question">
                        <label htmlFor="email">Email</label>
                        <input type="email"
                            value={email}
                            disabled={true} />
                    </div> : null }
                    <div className="form-question">
                        <label htmlFor="password">Password</label>
                        <input type="password"
                            id="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                    </div>
                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}

                    <div className="login2-actions">
                        <Button onClick={onSubmitPassword} disabled={loading} text="Continue" />
                        {forgotPassword}
                    </div>
                </form>

            case SignUpProcess.CreateAgree:
                return <form className="form">
                    <div>
                        <input type="checkbox"
                            id="agreesToPp"
                            required={true}
                            checked={agreesToPp}
                            onClick={e => setAgreesToPp(!agreesToPp)} />
                        <label htmlFor="agreesToPp">I have read and agreed to the <Link to="/privacy-policy">Privacy Policy</Link></label>
                    </div>

                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}

                    <div className="login2-actions">
                        <Button onClick={onAgreeToPp} disabled={loading} text="Continue" />
                        {forgotPassword}
                    </div>
                </form>

            case SignUpProcess.CreateFirstLastName:
                return <form className="form">
                    { email ? 
                    <div className="form-question">
                        <label htmlFor="email">Email</label>
                        <input type="email"
                            value={email}
                            disabled={true} />
                    </div> : null }
                    <div className="form-question">
                        <label htmlFor="firstName">First name</label>
                        <input type="text"
                            required={true}
                            id="firstName"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div className="form-question">
                        <label htmlFor="lastName">Last name</label>
                        <input type="text"
                            id="lastName"
                            required={true}
                            value={lastName}
                            onChange={e => setLastName(e.target.value)} />
                    </div>

                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}

                    <div className="login2-actions">
                        <Button onClick={onFirstLastName} disabled={loading} text="Continue" />
                        {forgotPassword}
                    </div>
                </form>

            case SignUpProcess.NewPasswordRequired:
                return <form className="form">
                    <div className="form-question">
                        <label htmlFor="tempPassword">Temporary password</label>
                        <input type="password"
                            id="tempPassword"
                            required={true}
                            value={temporaryPassword}
                            onChange={e => setTemporaryPassword(e.target.value)} />
                    </div>
                    <div className="form-question">
                        <label htmlFor="password">New password</label>
                        <input type="password"
                            id="password"
                            required={true}
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="form-question">
                        <label htmlFor="confirmPassword">Confirm password</label>
                        <input type="password"
                            value={confirmPassword}
                            required={true}
                            id="confirmPassword"
                            onChange={e => setConfirmPassword(e.target.value)} />
                    </div>

                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}

                    <div className="login2-actions">
                        <Button onClick={onSubmitPassword} disabled={loading} text="Continue" />
                        {forgotPassword}
                    </div>
                </form>

            case SignUpProcess.CreatePassword:
                return <form className="form">
                    <div className="form-question">
                        <label htmlFor="password">Password</label>
                        <input type="password"
                            id="password"
                            required={true}
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="form-question">
                        <label htmlFor="confirmPassword">Confirm password</label>
                        <input type="password"
                            value={confirmPassword}
                            required={true}
                            id="confirmPassword"
                            onChange={e => setConfirmPassword(e.target.value)} />
                    </div>

                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}

                    <div className="login2-actions">
                        <Button onClick={onSubmitPassword} disabled={loading} text="Continue" />
                        {forgotPassword}
                    </div>
                </form>

            case SignUpProcess.Verify:
                return <form onSubmit={onVerify}>
                    <div className="form-question">
                        <label htmlFor="verify">Verification code</label>
                        <input type="text"
                            value={verifyCode}
                            id="verify"
                            onChange={e => setVerifyCode(e.target.value)} />
                    </div>
                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}
                    <div className="login2-actions">
                        <Button disabled={loading} text="Verify" />
                    </div>
                </form>

            case SignUpProcess.TotpChallenge:
                return <form onSubmit={onTotpChallengeSubmit}>
                    <label htmlFor="mfa">MFA code</label>
                    <input inputMode="numeric"
                        value={totpCode}
                        id="mfa"
                        placeholder="MFA code"
                        onChange={e => setTotpCode(e.target.value)} />
                    {message && <Text>{message}</Text>}
                    {error && <Text variant="error">{error}</Text>}
                    <div className="login2-actions">
                        <Button disabled={loading} text="Submit" />
                    </div>
                </form>

            case SignUpProcess.Forgot:
                return <form onSubmit={onForgotVerify}>
                    <div className="form-question">
                        <label htmlFor="verify">Verification code</label>
                        <input type="text"
                            value={verifyCode}
                            id="verify"
                            onChange={e => setVerifyCode(e.target.value)} />
                    </div>
                    <div className="form-question">
                        <label htmlFor="password">Password</label>
                        <input type="password"
                            id="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="form-question">
                        <label htmlFor="confirmPassword">Confirm password</label>
                        <input type="password"
                            value={confirmPassword}
                            id="confirmPassword"
                            onChange={e => setConfirmPassword(e.target.value)} />
                    </div>
                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}
                    <div className="login2-actions">
                        <Button disabled={loading} text="Set new password" />
                    </div>
                </form>

            case SignUpProcess.AppSignedIn:
                const refreshToken = cognitoContext.session?.getRefreshToken().getToken();
                const accessToken = cognitoContext.session?.getAccessToken().getJwtToken();
                return <div className="login2-app-handoff">
                    {message && <Text>{message}</Text>}
                    {error && <ErrorText>{error}</ErrorText>}
                    {/* <input style={{ display: "none" }} id="refresh" value={refreshToken} />
                    <input style={{ display: "none" }} id="access" value={accessToken} />
                    <input style={{ display: "none" }} id="fromApp" value={fromApp.toString()} />
                    <input style={{ display: "none" }} id="clinic" value={params.get('clinic')?.toString()} /> */}
                    <div className="login2-actions">
                        {/* <Button text="Continue to app" /> */}
                        <a href={`${window.location.origin}${window.location.pathname}?refresh=${refreshToken}&access=${accessToken}&clinic=${params.get('clinic')}&fromApp`}>
                            <Button text="Continue" />
                        </a>
                    </div>
                </div>
        }
    }

    return <UnauthContainer>
        {renderInner()}
    </UnauthContainer>;

    // <CentreLayout>
    //     <Card variant="info" cardSize="large">
    //         <div className="login2-title">
    //             <img src="/logo-rounded.png" className="logo" />
    //             <Text size='title'>Sherpah</Text>
    //         </div>
    //         {renderInner()}
    //     </Card>
    // </CentreLayout>
}
