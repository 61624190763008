import { UploadedFile } from '../../../types';
import "./comments.scss";
import { formatDatetime } from '../../../utils';
import { ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { PatientContext } from '../../../contexts/PatientContext';
import { ErrorText } from '../../../components/Errors';
import Loading from '../../../components/Loading';
import { AuthContext } from '../../../contexts/AuthContext';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPaperPlane, faPaperclip, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';


type PresignedPost = {
    url: string,
    fields: {
        key: string,
        AWSAccessKeyId: string,
        "x-amz-security-token": string,
        policy: string,
        signature: string
    }
};


function catchAWSError(error: AxiosError<string>, setErrorState: (value: React.SetStateAction<string | null>) => void) {
	const domParser = new DOMParser();
	try {
		const parsedError = domParser.parseFromString(error.response!.data, "text/xml");
		const code = parsedError.getElementsByTagName("Code")[0].textContent;
        if (code == "EntityTooLarge") {
            setErrorState("File size too large, must be less than 5MB.");
		} else if (code == "AccessDenied") {
			setErrorState("File upload not permitted.");
        } else {
            setErrorState("Error occured while uploading file.");
        }
	} catch {
		setErrorState("File upload error.");
	}
}


export default function FilesTab() {
    const { sherpahGet, sherpahPost, publicAxios, catchAxios } = useContext(AxiosContext)!;
    const { patient, isShared } = useContext(PatientContext)!
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [preparing, setPreparing] = useState<boolean>(false);
    const [presignedPost, setPresignedPost] = useState<PresignedPost | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [downloadingFile, setDownloadingFile] = useState<UploadedFile | null>(null);
    const [deletingFile, setDeletingFile] = useState<UploadedFile | null>(null);
    const user = useContext(AuthContext)!.authState.user!;
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    const getFiles = async () => {
        try {
            const response = await sherpahGet<{files: UploadedFile[]}>(`/patients/${patient!.user_id}/files`);
            setFiles(response.data.files);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    const onSelectFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
        setPreparing(true);
        setFile(e.target.files![0]);
        try {
            const response = await sherpahGet<PresignedPost>(`/patients/${patient!.user_id}/file-upload-presigned`);
            setPresignedPost(response.data);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setPreparing(false);
        }
    }

    const uploadFile = async () => {
        setUploading(true);
        const fileName = presignedPost!.fields["key"];

        // upload file to S3
        try {
            const data = {
                ...presignedPost!.fields,
                file
            };

            await publicAxios.post(
                `${presignedPost!.url}`,
                data,
                {headers: {"Content-Type": "multipart/form-data"}}
            );
        } catch (e: any) {
            catchAWSError(e, setError);
            setUploading(false);
            setFile(null);
            return;
        }

        try {
            // send uploaded file details to backend
            const response = await sherpahPost<UploadedFile>(`/patients/${patient!.user_id}/file-upload-presigned`, {
                uuid: fileName,
                patient_id: patient!.user_id,
                uploaded_by_id: user.id,
                original_file_name: file!.name,
                size: file!.size
            });
            setFiles(files => [...files, response.data]);
        } catch (e: any) {
            catchAxios(e, setError);
        }

        setFile(null);
        setUploading(false);
    };

    useEffect(() => {
        setLoading(true);
        getFiles();
    }, []);

    let button;
    if (preparing) {
        button = <button type="button" disabled>Preparing file...</button>;
    } else if (uploading) {
        button = <button type="button" disabled>Uploading...</button>;
    } else if (file && !error) {
        button = <button type="button" onClick={uploadFile} className="fa-icon-button">
            <span className="fa-wrapper"><FontAwesomeIcon icon={faPaperPlane} /></span>
            Upload
        </button>;
    } else {
        button = <button type="button" onClick={() => hiddenFileInput.current?.click()} className="fa-icon-button secondary-button">
            <span className="fa-wrapper"><FontAwesomeIcon icon={faPaperclip} /></span>
            Select PDF file
        </button>;
    }

    let content;
    if (loading) {
        content = <Loading />;
    } else {
        content = <>
            { files.length > 0 ? <>
                    <div className="list-container">
                        <div className="list-columns files-list-grid">
                            <div className="list-column">FILE NAME</div>
                            <div className="list-column">UPLOADED BY</div>
                            <div className="list-column">UPLOADED AT</div>
                            <div className="list-column">ACTIONS</div>
                        </div>
                        { files.map(f => 
                            <div className="list-item files-list-grid" key={f.uuid}>
                                <div>{ f.original_file_name }</div>
                                <div>{ f.uploaded_by.full_name }</div>
                                <div>{ formatDatetime(f.uploaded_at) }</div>
                                <div className="list-actions">
                                    <div onClick={() => setDownloadingFile(f)} data-tooltip-id="download" data-tooltip-content="Download">
                                        <Tooltip id="download" className="tooltip-content" />
                                        <FontAwesomeIcon icon={faDownload} className="fa-button" />
                                    </div>
                                    { f.uploaded_by.id == user.id ? 
                                    <div onClick={() => setDeletingFile(f)} data-tooltip-id="delete" data-tooltip-content="Delete">
                                        <Tooltip id="delete" className="tooltip-content" />
                                        <FontAwesomeIcon icon={faTrashAlt} className="fa-button" />
                                    </div> : null }
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <PaginationControls page={page} setPage={setPage} totalPages={totalPages}/> */}
                </> : <i className="empty-message">No files uploaded.</i>
            }
            <hr/>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { file ? <b>{file ? "File: " + file.name : "No file selected"}</b> : null }
            <div>{ button }</div>
            <input type="file" accept=".pdf" onChange={onSelectFile} style={{display: "none"}} ref={hiddenFileInput} />
        </>;
    }
    
    return <div className="flex-col-gap-8">
		{content}
        { downloadingFile ? <DownloadingFileModal onClose={() => setDownloadingFile(null)} file={downloadingFile} /> : null }
        { deletingFile ? <ConfirmDeleteModal
            file={deletingFile}
            onClose={() => setDeletingFile(null)} 
            onDelete={() => {
                setDeletingFile(null);
                setFiles(files => files.filter(f => f.uuid != deletingFile.uuid))
            }}
        /> : null }
	</div>;
}


type DownloadingFileModalProps = {
    onClose: () => void,
    file: UploadedFile
};

function DownloadingFileModal(props: DownloadingFileModalProps) {
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const retrieveFile = async () => {
        setError(null);
        try {
            const response = await sherpahGet<{url: string}>(`/patients/${patient!.user_id}/files/${props.file.uuid}`);
            const anchor = document.createElement("a");
            anchor.setAttribute("href", response.data.url);
            anchor.setAttribute("download", props.file.original_file_name);
            anchor.setAttribute("filename", props.file.original_file_name);
            anchor.setAttribute("target", "_blank");
            anchor.click();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        retrieveFile();
    }, []);

    let content;
    if (loading) {
        content = <Loading text="Downloading file..." />;
    } else if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else {
        content = <div>File downloaded.</div>
    }

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Patient file</div>
            { content }
            <div className="modal-actions">
                <button type="button" className="secondary-button w-button" onClick={props.onClose}>Close</button>
            </div>
        </div>
    </div>;
}


type ConfirmDeleteModalProps = {
    onClose: () => void,
    onDelete: () => void,
    file: UploadedFile
};

function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    const { sherpahDelete, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const deleteModal = async () => {
        setError(null);
        setDeleting(true);
        try {
            const response = await sherpahDelete(`/patients/${patient!.user_id}/files/${props.file.uuid}`);
            props.onDelete();
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setDeleting(false);
        }
    };

    return <div className="modal-wrapper">
        <div className="modal">
            <div className="modal-heading">Confirm delete</div>
            <p>Are you sure you want to delete this file?</p>
            { error ? <ErrorText>{error}</ErrorText> : null }
            { deleting ? 
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" disabled>Deleting...</button>
                </div>
            :
                <div className="modal-actions">
                    <button type="button" className="w-button danger-button" onClick={deleteModal}>Yes, delete</button>
                    <button type="button" className="secondary-button w-button" onClick={props.onClose}>Cancel</button>
                </div>
            }
        </div>
    </div>;
}
