import React, { useContext, useEffect, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import "./doctor.scss";
import { ErrorText } from '../../components/Errors';
import Loading from '../../components/Loading';




export default function DassReport() {
    const [patientList, setPatientList] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;

    const getPatients = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await sherpahGet<{patients: string[]}>(`/doctors/%USERID%/dass-report`);
            setPatientList(response.data.patients);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPatients();
    }, []);

    let content;
    if (error != null) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <>
            <div className="heading-wrapper">
                <h1>DASS report</h1>
            </div>
            <div className="rounded-container">
                <p>Patients without a recorded DASS-21 in week prior to and first week of treatment:</p>
                <ul>
                { patientList.length == 0 ? 
                    <i className="empty-message">None</i>
                    : patientList.map(p => <li>{p}</li>)
                }
                </ul>
            </div>
        </>;
    }

    return <main>
        {content}
    </main>;
}
