import { Link } from 'react-router-dom';
import "./dass.scss";
import { useContext } from 'react';
import { PatientContext } from '../../../contexts/PatientContext';
import { AuthContext } from '../../../contexts/AuthContext';

export default function Questionnaires() {
    const { patient } = useContext(PatientContext)!;
    const authContext = useContext(AuthContext)!;
    
    return <main>
        <div className="heading-wrapper">
            <h1>{patient?.user.full_name}</h1>
        </div>
        <div className="rounded-container">
            <h3>Questionnaires</h3>
            <Link to="dass-21">
                <b>Depression, Anxiety and Stress Scale (DASS-21)</b>
                <p>A set of 21 questions designed to measure the negative emotional states of depression, anxiety and stress.
                </p>
            </Link>
            <hr/>
            { !authContext.authState.user?.is_patient ? <>
            <Link to="hdrs">
                <b>Hamilton Depression Rating Scale (HDRS)</b>
                <p>A multiple-item questionnaire used to provide an indication of depression, and as a guide to evaluate recovery.</p>
            </Link>
            <hr/> 
            </>: null }
            <Link to="bdi">
                <b>Beck Depression Inventory (BDI)</b>
                <p>A 21-item, multiple-choice inventory to assess levels of depression.</p>
            </Link>
        </div>
    </main>
}