import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';

import "./dass.scss";

import { User, HDRS as HDRSType } from '../../../types';
import { PatientContext } from '../../../contexts/PatientContext';
import { DEFAULT_TOAST_SETTINGS, formatDate, formatDatetime } from '../../../utils';
import { ErrorText } from '../../../components/Errors';
import { toast } from 'react-toastify';
import { HDRSQuestion, HDRS_QUESTIONS } from './questions';
import { DateTime } from 'luxon';
import Loading from '../../../components/Loading';
import DatePicker from 'react-datepicker';


type HDRSAnswers = {
	[key: string]: number | null
};

type QuestionProps = {
    question: HDRSQuestion,
    answer?: number | null,
    onAnswer?: (questionIndex: string, value: number | null) => void,
    enabled?: boolean // used for Q16
};

function Question(props: QuestionProps) {
    const { question } = props;
    const [answer, setAnswer] = useState<number | null>(props.answer === undefined ? null : props.answer);

    const updateAnswer = (value: number) => {
        if (props.onAnswer) {
            setAnswer(value);
            props.onAnswer(question.number, value);
        }
    };

    useEffect(() => {
        if (props.enabled === false) {
            setAnswer(null);
        }
    }, [props.enabled]);

    return <>
        <div className="dass-question">
            <h4>{question.number}. {question.title}</h4>
            {question.description ? <p>({question.description})</p> : null}
            <div className="dass-options">
                {question.options.map((option, i) => 
                    <button onClick={() => updateAnswer(i)} className={answer == i ? "selected" : ""} key={i} disabled={props.answer !== undefined}>
                        {option}
                    </button>
                )}
            </div>
        </div>
        <hr/>
    </>;
}

type HDRSResults = HDRSAnswers & {
    completed_at: string,
    backdated_to: string | null
};

export default function HDRS() {
    const [error, setError] = useState<string | null>(null);
    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [backdate, setBackdate] = useState<Date | null>(null);
    const { sherpahGet, sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const { patient } = useContext(PatientContext)!;
    const navigate = useNavigate();

    const params = useParams<{id: string}>();
    const showingResults = params.id !== undefined;

    const initialAnswers: HDRSAnswers = HDRS_QUESTIONS.reduce((obj: any, q, index) => {obj[`q${q.number}`] = null; return obj;}, {});
	const answers = useRef<HDRSAnswers>(initialAnswers);
    const [completedAt, setCompletedAt] = useState<string | null>(null);
    const [q16, setq16] = useState<"a"|"b">("a");

    const getResults = async () => {
        try {
            const response = await sherpahGet<{"hdrs": HDRSResults}>(`patients/${patient!.user_id}/hdrs/${params.id}`);
            answers.current = response.data.hdrs;
            if (response.data.hdrs.backdated_to) {
                setCompletedAt(formatDate(response.data.hdrs.backdated_to) + " (backdated)");
            } else {
                setCompletedAt(formatDatetime(response.data.hdrs.completed_at));
            }
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showingResults) {
            setLoading(true);
            getResults();
        }
    }, []);

	const postResults = async () => {
        setSubmitting(true);
        setSubmissionError(null);

        // ensure entire questionnaire was filled out
        let numberOfAnswers = 0;
        for (const answer in answers.current) {
            if (answers.current[answer] != null) {
                numberOfAnswers++;
            }
        }

        if (numberOfAnswers < 17) {
            setSubmissionError("Please answer all questions.");
            setSubmitting(false);
            return;
        }

        let data: any = {...answers.current};

        if (q16 == "a") delete data["q16b"];
        else delete data["q16a"];

        if (backdate) {
            data["backdated_to"] = DateTime.fromJSDate(backdate).toISODate();
        }

		try {
			await sherpahPost<HDRSType>(
				`patients/${patient!.user_id}/hdrs`,
				data
			);
            toast("HDRS completed!", DEFAULT_TOAST_SETTINGS);
            navigate(-1);
		} catch (e: any) {
			catchAxios(e, setSubmissionError);
		} finally {
			setSubmitting(false);
		}
	};

	const updateAnswers = (questionIndex: string, value: number | null) => {
        answers.current[`q${questionIndex}`] = value;
	};

    let content;
    if (error) {
        content = <ErrorText>{error}</ErrorText>;
    } else if (loading) {
        content = <Loading />;
    } else {
        content = <div>
            { showingResults ? 
                <p><b>{patient?.user.full_name}</b> completed this questionnaire on <b>{ completedAt }</b></p> :
                <>
                    <p>A multiple-item questionnaire used to provide an indication of depression, and as a guide to evaluate recovery.</p>
                    <b>If this questionnaire was completed at a past date you may enter that date here:</b> <DatePicker
                        selected={backdate}
                        onChange={(d: Date) => setBackdate(d)}
                        dateFormat="d MMM yyyy" />
                </>
            }
            { submissionError ? <ErrorText>{submissionError}</ErrorText> : null }
            { showingResults ? 
                HDRS_QUESTIONS.slice(0,-3).map((question, i) => <Question question={question} key={i} answer={answers.current[`q${i+1}`]!} />) :
                HDRS_QUESTIONS.slice(0,-3).map((question, i) => <Question question={question} key={i} onAnswer={updateAnswers} />)
            }
            <br/>
            <p><b>Select either 16a or 16b</b></p>
            { showingResults ? <>
                <Question question={HDRS_QUESTIONS[15]} answer={answers.current["q16a"]!} />
                <Question question={HDRS_QUESTIONS[16]} answer={answers.current["q16b"]!} />
                <Question question={HDRS_QUESTIONS[17]} answer={answers.current["q17"]!} />
            </> : <>
                <Question question={HDRS_QUESTIONS[15]} onAnswer={(i, val) => {setq16("a"); updateAnswers(i, val);}} enabled={q16 == "a"} />
                <Question question={HDRS_QUESTIONS[16]} onAnswer={(i, val) => {setq16("b"); updateAnswers(i, val);}} enabled={q16 == "b"} />
                <Question question={HDRS_QUESTIONS[17]} onAnswer={updateAnswers} />
            </> }
            { submissionError ? <ErrorText>{submissionError}</ErrorText> : null }
            { !showingResults ? (
                submitting ? 
                    <button disabled>Submitting...</button> : 
                    <button onClick={postResults}>Submit</button>
            ) : null }
        </div>;
    }

    return <main>
        <div className="heading-wrapper">
            <h1>Hamilton Depression Rating Scale</h1>
        </div>
        <div className="rounded-container">
            { content }
        </div>
    </main>;

}