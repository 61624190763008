
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading';


export default function CancelPayment() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const clinicName = searchParams.get("clinic");

    useEffect(() => {
        navigate(`/redirect?mobile=payment?status=cancelled&clinic=${clinicName}`);
    }, []);

    let content = <Loading text="Cancelling..." />;

    return <main>
        {content}
    </main>;
}
