import { useState } from "react";
import "./Dialog.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export type DialogProps = {
    title: string;
    child: React.ElementType;
    onClose?: () => void;
}

export function Dialog(props: DialogProps) {
    return <div className="modal-wrapper">
        <div className="modal">
            <div className="dialog-title">
                <p>{props.title}</p>
                <div className="dialog-close" onClick={props.onClose}>
                    <FontAwesomeIcon icon={faClose} />
                </div>
            </div>
            <props.child onClose={props.onClose} />
        </div>
    </div>
}

export function useDialog(props: DialogProps) {
    const [isOpen, setOpen] = useState(false);

    return {
        setOpen,
        isOpen,
        component: isOpen ? <Dialog onClose={() => {
            setOpen(false);
            props.onClose && props.onClose();
        }} title={props.title} child={props.child}></Dialog> : undefined
    }
}
