import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip as TooltipChartJS,
    ChartData,
    BarElement,
    LineController,
    BarController,
    TimeScale,
    ChartOptions,
    ChartDataset
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Report, SliceMetrics } from './reportTypes';
import { DateTime, Duration } from 'luxon';


ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Legend,
    BarElement,
    LineController,
    BarController,
    TooltipChartJS,
    zoomPlugin
);

type SlicesGraphProps = {
    metrics: SliceMetrics
    showDate: string | null,
    totalDays: number
};

export default function SlicesGraph(props: SlicesGraphProps) {
    if (props.showDate === null) {
        return <div>No slice date selected.</div>;
    }

    const date = DateTime.fromISO(props.showDate);
    const timestamps = Array.from({length: 1440}, (_, i) => date.plus({minutes: i+720}));

    const slicedHr = props.metrics[props.showDate].heart_rate;
    const slicedSteps = props.metrics[props.showDate].steps;

    // const slicedHr = props.metrics.heart_rate.slice(props.showDay*1440, props.showDay*1440+1440);
    // const slicedSteps = props.metrics.steps.slice(props.showDay*1440, props.showDay*1440+1440);

    // console.log("HEART RATE", props.metrics.heart_rate);
    // console.log("SLICE HR", slicedHr);


    const options: ChartOptions = {
        responsive: true,
        animation: false as const,
        plugins: {
            legend: false as const,
            tooltip: {
                enabled: true,
                intersect: false
            },
            datalabels: {
                display: false
            }
        },
        scales: {
            x: {
                type: "time" as const,
                time: {
                    displayFormats: {
                        hour: "ha"
                    }
                }
            },
            y: {
                min: 40,
                max: 180
            }
        }
    };

    let datasets: ChartDataset<"line" | "bar", number[] | {x: DateTime, y: number}[]>[] = [
        {
            type: "line" as const,
            label: "Heart rate",
            data: slicedHr,
            pointRadius: 0,
            pointBackgroundColor: "#7ace4c",
            fill: "#7ace4c",
            borderColor: "#7ace4c",
            borderJoinStyle: "round",
            borderWidth: 1.5
        }, {
            type: "bar" as const,
            label: "Steps",
            data: slicedSteps.map(v => 40+v/5),
            backgroundColor: "#2A3238",
            barPercentage: 1.5
        }
    ];

    // if (props.report.stats) {
    //     const bucketAwake = props.report.stats.avg_awake_heart_rate;
    //     const bucketSleep = 0.7 * bucketAwake;
    //     const sleepOnset = DateTime.fromISO(props.report.stats.sleep_onset);
    //     const sleepFinish = DateTime.fromISO(props.report.stats.sleep_finish);
    //     datasets.push({
    //         type: "line" as const,
    //         data: [
    //             {x: timestamps[0], y: bucketAwake},
    //             {x: sleepOnset, y: bucketAwake},
    //             {x: sleepOnset, y: bucketSleep},
    //             {x: sleepFinish, y: bucketSleep},
    //             {x: sleepFinish, y: bucketAwake},
    //             {x: timestamps[timestamps.length-1], y: bucketAwake},
    //         ],
    //         pointRadius: 0,
    //         borderColor: "rgba(120, 120, 120, 0.2)",
    //         borderWidth: 30
    //     });

    //     if (props.testingNewScores) {
    //         props.report.stats.interruption_detail.forEach(interruption => {
    //             const height = 40;
    //             const start = DateTime.fromISO(interruption.start);
    //             const end = DateTime.fromISO(interruption.end);
    //             datasets.push({
    //                 type: "line" as const,
    //                 data: [
    //                     {x: start, y: height},
    //                     {x: end, y: height},
    //                 ],
    //                 pointRadius: 0,
    //                 borderColor: "rgba(220, 0, 0, 0.4)",
    //                 borderWidth: 30
    //             });
    //         });
    //     }
    // }


    const data: ChartData<"line" | "bar", number[] | {x: DateTime, y: number}[], DateTime> = {
        labels: timestamps,
        datasets: datasets,
    };

    return <Chart type="bar" data={data} options={options} />;
}
