import React from 'react';
import { DateTime } from 'luxon';
import { PatientOverview } from '../../../types';
import Gauge from '../../../components/Gauge';
import { formatDate, formatDatetime, scoreDesc } from '../../../utils';


export default function OverviewTab(props: {overview: PatientOverview}) {
    const { overview } = props;
    return <div className="overview-container">
			<div className="overview-physiological">
                { overview.latest_report ? 
                    <div>
                        <div className="inner-rect-heading">
                            <h3>Latest physiological scores</h3>
                            <span>{ formatDate(overview.latest_report?.date) }</span>
                        </div>
                        <div id="graphics">
                            <h4>Low mood</h4>
                            <h4>Mental stress</h4>
                            <Gauge
                                value={overview.latest_report.score_depression}
                                precision={1}
                                maxValue={10}
                                valueDesc={scoreDesc(overview.latest_report.score_depression)} />
                            <Gauge
                                value={overview.latest_report.score_anxiety}
                                precision={1}
                                maxValue={10}
                                valueDesc={scoreDesc(overview.latest_report.score_anxiety)} />
                        </div>
                    </div>
                    :
                    <div>
                        <div className="inner-rect-heading">
                            <h3>Latest physiological scores</h3>
                        </div>
                        <i className="empty-message">None</i>
                    </div> }
				<div>
					<h3>Comment</h3>
                    { overview.comment || <i className="empty-message">Not available</i>}
				</div>
			</div>
			<div className="overview-dass">
				{ overview.latest_dass21 ? 
				    <div>
						<div className="inner-rect-heading">
							<h3>Latest DASS-21</h3>
							<span>
                            { overview.latest_dass21.backdated_to ? 
                                formatDate(overview.latest_dass21.backdated_to) + " (backdated)" :
                                formatDatetime(overview.latest_dass21.completed_at)
                            }</span>
						</div>
						<div id="graphicsDass">
							<h4>Depression</h4>
							<h4>Anxiety</h4>
							<h4>Stress</h4>
                            <Gauge
                                value={overview.latest_dass21!.depression_score}
                                precision={0}
                                maxValue={21}
                                valueDesc={overview.latest_dass21!.depression_label} />
                            <Gauge
                                value={overview.latest_dass21!.anxiety_score}
                                precision={0}
                                maxValue={21}
                                valueDesc={overview.latest_dass21!.anxiety_label} />
                            <Gauge
                                value={overview.latest_dass21!.stress_score}
                                precision={0}
                                maxValue={21}
                                valueDesc={overview.latest_dass21!.stress_label} />
						</div>
					</div>
					:
                    <div>
						<div className="inner-rect-heading">
							<h3>Latest DASS-21</h3>
						</div>
						<i className="empty-message">None</i>
                    </div>
                }
				{/* <div>
					<h3>DASS-21 history</h3>
					<div>
						{ overview.dass21s.length ? 
                            <div className="list-container">
                                <div className="list-columns dass21-list-grid">
                                    <div className="list-column">COMPLETED AT</div>
                                    <div className="list-column">DEPRESSION</div>
                                    <div className="list-column">ANXIETY</div>
                                    <div className="list-column">STRESS</div>
                                </div>
                                { overview.dass21s.map(dass21 => 
                                    <div className="list-item dass21-list-grid" key={dass21.completed_at}>
                                        <div>{ formatDatetime(dass21.completed_at) }</div>
                                        <div>{ dass21.depression_score } ({ dass21.depression_label })</div>
                                        <div>{ dass21.anxiety_score } ({ dass21.anxiety_label })</div>
                                        <div>{ dass21.stress_score } ({ dass21.stress_label })</div>
                                    </div>  
                                )}
                            </div>
                        :
						    <i className="empty-message">None</i>
                        }
					</div>
				</div> */}
			</div>
		</div>
}
