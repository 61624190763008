import { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';

import { Patient, User } from '../../types';
import { PatientContext } from '../../contexts/PatientContext';
import { AuthContext } from '../../contexts/AuthContext';
import { ErrorMessage, Field, Form, Formik, FormikHandlers, FormikHelpers } from 'formik';
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { DatePickerField } from '../../components/DatePickerField';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorText } from '../../components/Errors';


type PatientEditFormValues = {
    user__first_name: string,
    user__last_name: string,
    date_of_birth: string | null,
    sex: "M" | "F" | "O" | null,
    mobile_number: string | null,
    referring_gp__gp_name: string,
    referring_gp__practise_name: string,
    referring_gp__practise_address: string,
    referring_gp__practise_phone: string
};

export default function PatientEdit() {
    const { sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { patient, updatePatient, isShared } = useContext(PatientContext)!;
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (isShared) {
            navigate(`/patients/${patient!.user.id}`);
        }
    }, [isShared]);

    const onSubmit = async (values: PatientEditFormValues, { setErrors }: FormikHelpers<PatientEditFormValues>) => {
        setError(null);
        try {
            updatePatient(values);
            navigate(-1);
            toast('Patient updated.', DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    }

    const initialValues: PatientEditFormValues = {
        user__first_name: patient!.user.first_name,
        user__last_name: patient!.user.last_name,
        date_of_birth: patient!.date_of_birth,
        sex: patient!.sex,
        mobile_number: patient!.mobile_number,
        referring_gp__gp_name: patient!.referring_gp?.gp_name || "",
        referring_gp__practise_name: patient!.referring_gp?.practise_name || "",
        referring_gp__practise_address: patient!.referring_gp?.practise_address || "",
        referring_gp__practise_phone: patient!.referring_gp?.practise_phone || ""
    };

    return <main className="narrow">
        <div className="heading-wrapper">
            <h1>Edit patient</h1>
        </div>
        <div className="rounded-container">
            <Formik 
                validationSchema={Yup.object<PatientEditFormValues>({
                    user__first_name: Yup.string().label("First name").required(),
                    user__last_name: Yup.string().label("Last name").required(),
                    date_of_birth: Yup.date().notRequired().max(new Date(), "Date must occur before today.")
                })}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                    {({isSubmitting}) => (
                    <Form className="form-sections">
                        <div>
                            <h3>Patient details</h3>
                            <div className="form-fields">
                                <div>
                                    <label>Email</label>
                                    <input type="text" disabled value={patient?.user.email} />
                                </div>
                                <div className="form-field-half">
                                    <label>First name</label>
                                    <Field type="text" name="user__first_name" />
                                    <ErrorMessage name="user__first_name" component={ErrorText} />
                                </div>
                                <div className="form-field-half">
                                    <label>Last name</label>
                                    <Field type="text" name="user__last_name" />
                                    <ErrorMessage name="user__last_name" component={ErrorText} />
                                </div>
                                <div>
                                    <label>Date of birth</label>
                                    <DatePickerField name="date_of_birth" />
                                    <ErrorMessage name="date_of_birth" component={ErrorText} />
                                </div>
                                <div>
                                    <label>Sex</label>
                                    <Field as="select" name="sex">
                                        <option value=""></option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                        <option value="O">Other</option>
                                    </Field>
                                    <ErrorMessage name="sex" component={ErrorText} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3>Referring GP</h3>
                            <div className="form-fields">
                                <div>
                                    <label>GP name</label>
                                    <Field type="text" name="referring_gp__gp_name" />
                                    <ErrorMessage name="referring_gp__gp_name" component={ErrorText} />
                                </div>
                                <div>
                                    <label>Practise name</label>
                                    <Field type="text" name="referring_gp__practise_name" />
                                    <ErrorMessage name="referring_gp__practise_name" component={ErrorText} />
                                </div>
                                <div>
                                    <label>Practise address</label>
                                    <Field type="text" name="referring_gp__practise_address" />
                                    <ErrorMessage name="referring_gp__practise_address" component={ErrorText} />
                                </div>
                                <div>
                                    <label>Practise phone</label>
                                    <Field type="text" name="referring_gp__practise_phone" />
                                    <ErrorMessage name="referring_gp__practise_phone" component={ErrorText} />
                                </div>
                            </div>
                        </div>

                        {error ? <ErrorText>{error}</ErrorText> : null}

                        { isSubmitting ? 
                            <button type="button" className="w-button" disabled>Saving...</button>
                            :
                            <div className="actions">
                                <button type="submit" className="w-button">Save</button>
                                <button type="button" className="secondary-button w-button" onClick={() => navigate(-1)}>Cancel</button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    </main>;

}