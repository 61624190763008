import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosContext } from '../../contexts/AxiosContext';

import { ErrorMessage, Field, Form, Formik, FormikHandlers, FormikHelpers } from 'formik';
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorText } from '../../components/Errors';


type PatientFormValues = {
    email: string,
    first_name: string,
    last_name: string,
};


export default function AddManagedPatient() {
    const { sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const onSubmit = async (values: PatientFormValues, { setErrors }: FormikHelpers<PatientFormValues>) => {
        setError(null);
        try {
            const response = await sherpahPost(`/doctors/%USERID%/managed-patients`, values);
            navigate(-1);
            toast("Patient added.", DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            catchAxios(e, setError, setErrors);
        }
    }

    const initialValues: PatientFormValues = {
        email: "",
        first_name: "",
        last_name: "",
    };

    return <main className="narrow">
        <div className="heading-wrapper">
            <h1>Add managed patient</h1>
        </div>
        <div className="rounded-container">
            <p>
                A managed patient is a patient manually added by a doctor so that the patient doesn't need to sign up with the mobile app.
                Doctors can add managed patients for recording TMS sessions & medications.
            </p>
            <Formik 
                validationSchema={Yup.object<PatientFormValues>({
                    email: Yup.string().label("Email").required(),
                    first_name: Yup.string().label("First name").required(),
                    last_name: Yup.string().label("Last name").required(),
                })}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                    {({isSubmitting}) => (
                    <Form>
                        <div className="form-fields">
                            <div>
                                <label>Email</label>
                                <Field type="text" name="email" />
                                <ErrorMessage name="email" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label>First name</label>
                                <Field type="text" name="first_name" />
                                <ErrorMessage name="first_name" component={ErrorText} />
                            </div>
                            <div className="form-field-half">
                                <label>Last name</label>
                                <Field type="text" name="last_name" />
                                <ErrorMessage name="last_name" component={ErrorText} />
                            </div>
                        </div>

                        {error ? <ErrorText>{error}</ErrorText> : null}

                        <div className="actions">
                        { isSubmitting ? 
                            <button type="button" className="w-button" disabled>Adding...</button>
                            : <>
                                <button type="submit" className="w-button">Add</button>
                                <button type="button" className="secondary-button w-button" onClick={() => navigate(-1)}>Cancel</button>
                            </>
                        }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </main>;
}